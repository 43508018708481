import React, { useState, useEffect } from 'react';
import { Row, Col, Container, Card } from 'react-bootstrap';
import Banner from '../../components/banner.js';
import '../../styles/Homepage.scss'
import MetaTags from 'react-meta-tags';
import { createMuiTheme, ThemeProvider } from '@mui/material/styles';
import {  IconArrowNarrowRight } from '@tabler/icons-react';
import { useHistory } from 'react-router-dom';
import { jwtDecode } from "jwt-decode";
import { RefreshToken } from '../../Services/LoginService.js';
import SearchBox from '../../components/searchbox.js';
import { IconButton, Button, Badge, Divider} from '@mui/material';
import Background from '../../components/background.js';
import { logPageViews } from '../../Services/LoginService.js';
import { IconArrowRight} from '@tabler/icons-react';
import {UserSession } from '../../Services/LoginService';
function Homepage() {
    useEffect(() => {
        document.title = "Home - MIMS Drugs4dent"; // Change "Your App Name" to your desired title
        logPage()
        async function logPage() {
          await logPageViews("Home")
        //   if((await UserSession('Check',localStorage.getItem("sessionId"),'',localStorage.getItem("userId"),'','','','','','','')).data == false)
        //     {
        //       localStorage.clear()
        //       console.log('fals')
        //       window.location.reload();
        //     }else{
             
        //     }
        }

        

      }, []);


    if ("token" in localStorage) {
        if (localStorage.getItem("token") === 'undefined' || localStorage.getItem("token") === null) {
          localStorage.removeItem("token");
          window.location.reload();
        }
    
        const decoded = jwtDecode(localStorage.getItem("token"));
        const expiration = new Date(decoded.exp)
        const now = new Date();
        const fiveMinutes = 1000 * 60 * 5 / 1000;

        if ((expiration.getTime() - Math.floor(now.getTime() / 1000)) < fiveMinutes) {
            (async () => {
              try {
                // Refresh the token
                const refreshResult = await RefreshToken(
                  localStorage.getItem('token'),
                  localStorage.getItem('refreshToken'),
                  localStorage.getItem('sessionId')
                );
    
                // Update local storage with the new token
                localStorage.setItem('token', refreshResult.token);
                localStorage.setItem('refreshToken', refreshResult.refreshToken);
                localStorage.setItem('sessionId', refreshResult.sessionId);
    
                //window.location.reload();
              } catch (error) {
                console.error('An error occurred:', error);
                localStorage.clear()
                window.location.reload();
              }
            })();
    
        }
      }

    const history = useHistory();
    const handleNavigate = (path) => {
        history.push(path);
    };
    
    const theme = createMuiTheme({
        components: {
            MuiAutocomplete: {
                styleOverrides: {
                    inputRoot: {
                        borderRadius: 20,
                        backgroundColor: '#ffffff',
                        // Example: Change background color
                        '& .MuiInputBase-input': {
                            paddingLeft: '10px',
                        }
                        // Adjust border radius if needed
                    },
                },
            },
            MuiCheckbox: {
                styleOverrides: {
                    inputRoot: {
                        borderRadius: 1, // Adjust border radius as needed
                    },
                },
            },
        },
    });

    const makeAnimationStartHandler = (stateSetter) => (e) => {
        const autofilled = !!e.target?.matches("*:-webkit-autofill");
        if (e.animationName === "mui-auto-fill" || e.animationName === "mui-auto-fill-cancel") {
        stateSetter(autofilled || e.target.value !== '');
        }
    };
    // Check IMGateway
    const gateway = localStorage.getItem('gateway');
    const checkgateway = gateway !== 'false' && gateway !== undefined && gateway !== null && gateway !== '';
    console.log(checkgateway); // Logs `true` or `false` based on the condition

    return (<>
        <MetaTags>
            <meta name="viewport" content="width=device-width, initial-scale=1" />
        </MetaTags>
        <Container fluid id="dashboard-main">
            <Banner />
            <Row id="dash-content-main">
                <Col id="headline">
                    <Row id="headlineCaption" > 
                        <img src='/Search.svg' alt="Logo" className="d-none d-lg-flex" />
                        <div id="headline-text">
                            <h1>Towards Safer Medication Use:</h1>
                            <h2>A Tailored Medicines Resource For Dental Practitioners</h2>
                            <h4>powered by <span>MIMS</span></h4>
                        </div>
                    </Row>
                    <Row id="search">
                       
                            <SearchBox />
                      
                    </Row>
                </Col>
                
            
                    
              
                <Divider sx={{ my: {lg: 6, xs: 4}, borderBottomWidth: 1, borderColor: '#727272' }}/>
                <Col id="Menu">
                <Row className="d-flex justify-content-center" >
                    <div class="row row-cols-5 row-equal-height">
                    <Col xs={10} md={6} lg={checkgateway ? 4 : 6} xl className="mb-4" onClick={() => handleNavigate('/druginteractions')}>
                            <Card className="card-custom">
                                <div className="card-media">
                                    <Card.Img variant="top" src="/MenuIcons/Interactions.svg" alt="Drug & Allergy Interactions" />
                                </div>
                                <Card.Body>
                                    <Card.Title className="card-title">Drug & Allergy Interactions</Card.Title>
                                    <Card.Text className="card-text">Identify potentially harmful combinations of medications or medications that may be unsuitable due to an allergy</Card.Text>
                                </Card.Body>
                                {/* <IconButton color="primary" variant="outlined" aria-label="arrow-right">
                                    <IconArrowRight />
                                </IconButton>*/}
                            </Card> 
                        </Col>
                        <Col xs={10} md={6} lg={checkgateway ? 4 : 6} xl className="mb-4" onClick={() => handleNavigate('/dosingcalculations')}>
                            <Card className="card-custom">
                                <div className="card-media">
                                <Card.Img variant="top" src="/MenuIcons/Calculator.svg" alt="Dosing Calculations" />
                                </div>
                                <Card.Body>
                                <Card.Title className="card-title">Dosing Calculations</Card.Title>
                                <Card.Text className="card-text">Recommended dosages for prescribing of medicines in dentistry for adults and children</Card.Text>
                                </Card.Body>
                                {/* <IconButton color="primary" variant="outlined" aria-label="arrow-right">
                                    <IconArrowRight />
                                </IconButton> */}
                            </Card>
                        </Col>
                        <Col xs={10} md={6} lg={checkgateway ? 4 : 6} xl className="mb-4" onClick={() => handleNavigate('/pregnancyandlactationguide')}>
                            <Card className="card-custom">
                                <div className="card-media">
                                <Card.Img variant="top" src="/MenuIcons/Pregnancy.svg" alt="Pregnancy & Lactation Guide" />
                                </div>
                                <Card.Body>
                                <Card.Title className="card-title">Pregnancy & Lactation Guide</Card.Title>
                                <Card.Text className="card-text">Identify the safety of dental medicines in pregnancy and lactation</Card.Text>
                                </Card.Body>
                                {/* <IconButton color="primary" variant="outlined" aria-label="arrow-right">
                                    <IconArrowRight />
                                </IconButton> */}
                            </Card>
                        </Col>
                        <Col xs={10} md={6} lg={6} xl className="mb-4" onClick={() => handleNavigate('/dentalantibioticguide')}>
                            <Card className="card-custom">
                                <div className="card-media">
                                <Card.Img variant="top" src="/MenuIcons/DentalAntibiotic.svg" alt="Dental Antibiotic Use Guide" />
                                </div>
                                <Card.Body>
                                <Card.Title className="card-title">Dental Antibiotic Use Guide</Card.Title>
                                <Card.Text className="card-text">Comprehensive guide for appropriate dental antibiotic use and patient education</Card.Text>
                                </Card.Body>
                                {/* <IconButton color="primary" variant="outlined" aria-label="arrow-right">
                                    <IconArrowRight />
                                </IconButton> */}
                            </Card>
                        </Col>
                        {checkgateway ? (
                        <Col xs={10} md={6} lg={6} xl className="mb-4" onClick={() => history.push('/IMGatewayHerbalInteractions')}>
                            <Card className="card-custom">
                            <Badge badgeContent={'Add-on'} id="addon">
                                <div className="card-media">
                                <Card.Img variant="top" src="/MenuIcons/IMGateway.svg" alt="IMGateway Herbal Interactions" />
                                </div>
                            </Badge>
                            <Card.Body>
                                <Card.Title className="card-title">IMGateway Herbal Interactions</Card.Title>
                                <Card.Text className="card-text">Check interactions between complementary and conventional medicines</Card.Text>
                            </Card.Body>
                            {/* <IconButton color="primary" variant="outlined" aria-label="arrow-right">
                                <IconArrowRight />
                            </IconButton> */}
                            </Card>
                        </Col>
                        ) : null}
                    </div>
                </Row>
                </Col>
                
            </Row>   
        </Container>
    <Background />
    </>
    );
}

export default Homepage;