import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Divider, IconButton, Box,Tooltip, Dialog, DialogTitle, DialogContent, DialogActions, Button, Slide, Hidden } from '@mui/material';
import { useHistory } from 'react-router-dom';
import '../styles/sidebar.scss'
import { IconCategoryFilled, IconArrowRight, IconArrowLeft, IconSquareRoundedArrowRightFilled, IconSquareRoundedArrowLeftFilled } from '@tabler/icons-react';
import { jwtDecode } from "jwt-decode";
import { RefreshToken } from '../Services/LoginService';
import parse from 'html-react-parser'
import { SearchDetailById, getAbbrPiByID, getDrugInfo, getProductList } from '../Services/ProductSearchService';
import SearchBox from './searchbox';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import { HideImage, HideSource } from '@mui/icons-material';

const Sidebar = ({ activeButton }) => {

  if ("token" in localStorage) {
    if (localStorage.getItem("token") === 'undefined' || localStorage.getItem("token") === null) {
      localStorage.removeItem("token");
      window.location.reload();
    }

    const decoded = jwtDecode(localStorage.getItem("token"));
    const expiration = new Date(decoded.exp)
    const now = new Date();
    const fiveMinutes = 1000 * 60 * 5 / 1000;
    //console.log(fiveMinutes)
    //console.log((expiration.getTime() - Math.floor(now.getTime() / 1000)))
    if ((expiration.getTime() - Math.floor(now.getTime() / 1000)) < fiveMinutes) {
      
        (async () => {
          //console.log('ex')
          try {
            // Refresh the token
            const refreshResult = await RefreshToken(
              localStorage.getItem('token'),
              localStorage.getItem('refreshToken'),
              localStorage.getItem('sessionId')
            );

            // Update local storage with the new token
            localStorage.setItem('token', refreshResult.token);
            localStorage.setItem('refreshToken', refreshResult.refreshToken);
            localStorage.setItem('sessionId', refreshResult.sessionId);

            //window.location.reload();
          } catch (error) {
            console.error('An error occurred:', error);
            localStorage.clear()
            window.location.reload();
          }
        })();

    }
  }



  //Sidebar Toggle
  const [open, setOpen] = React.useState(false);
  const toggleDrawer = (forceClose = null) => {
    if (forceClose === true) {
      setOpen(false);
    } else if (forceClose === false) {
      setOpen(true);
    } else {
      setOpen(!open);
    }
  };

  //Sidebar Items
  const gateway = localStorage.getItem('gateway');
  const [hoveredButton, setHoveredButton] = useState(null);
  const buttons = [
    { id: 'search', openLabel: 'Search Medications', label: 'Search', activeIcon: '/MenuIcons/Active/SVG/Search.svg', icon: '/MenuIcons/SVG/Search.svg', link: '' },
    { id: 'interactions', openLabel: 'Drug & Allergy Interactions', label: 'Interactions', activeIcon: '/MenuIcons/Active/SVG/Interactions.svg', icon: '/MenuIcons/SVG/Interactions.svg', link: '/druginteractions' },
    { id: 'dosing', openLabel: 'Dosing Calculations', label: 'Dosing', activeIcon: '/MenuIcons/Active/SVG/Calculator.svg', icon: '/MenuIcons/SVG/Calculator.svg', link: '/dosingcalculations' },
    { id: 'pregnancy', openLabel: 'Pregnancy & Lactation Guide', label: 'Pregnancy', activeIcon: '/MenuIcons/Active/SVG/Pregnancy.svg', icon: '/MenuIcons/SVG/Pregnancy.svg', link: '/pregnancyandlactationguide' },
    { id: 'antibiotic', openLabel: 'Dental Antibiotic Use Guide', label: 'Antibiotic', activeIcon: '/MenuIcons/Active/SVG/DentalAntibiotic.svg', icon: '/MenuIcons/SVG/DentalAntibiotic.svg', link: '/dentalantibioticguide' },
  ];
  // Check IMGateway
  if (gateway !== 'false' && gateway !== 'undefined' && gateway !== undefined && gateway !== null && gateway !== '') {
    buttons.push({id: 'imgateway', openLabel: 'IMGateway Herbal Interactions', label: 'IMGateway', activeIcon: '/MenuIcons/Active/SVG/IMGateway.svg', icon: '/MenuIcons/SVG/IMGateway.svg', link: '/IMGatewayHerbalInteractions'});
  }
  
  //Routing
  const history = useHistory();
  const handleNavigate = (path) => {
    history.push(path);
  };

  //Search Modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleButtonClick = (button) => {
    if (button.id === 'search') {
      setIsModalOpen(true);
    } else {
      handleNavigate(button.link);
    }
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

 


  //Search function
  const [inputValue, setInputValue] = useState('');
  const options = ['Option 1', 'Option 2', 'Option 3'];
  const handleSearch = () => {
    console.log('Search clicked for:', inputValue);
  };
  const handleClearInput = () => {
    setInputValue('');
  };


  //Search-checkbox
  const [checked, setChecked] = useState(false);
  const handleCheckboxChange = (event) => {
    setChecked(event.target.checked);
  };


  const [op1, setOp1] = useState([]);
  const [query, setQuery] = useState("");
  const [suggests, setSuggest] = useState([]);
  const [option, setOption] = useState('')
  const [type, setType] = useState('')
  const [routeUrl, setrRouteUrl] = useState('')


  useEffect(() => {

    if (type == 'prescriptions') {
      setrRouteUrl('/dosingcalculations/')
    } else {
      setrRouteUrl('/ProductDetails/')
    }
  }, [type]);
  const [hovering, setHovering] = useState(false);

   //Custom Autocomplete Search Styles
  

  return (<>
    <Box id="sidebar"className="d-md-flex d-none">
      <Drawer
        variant="permanent"
        className={open ? "drawer open" : "drawer"}
         >  <Box onClick={toggleDrawer} id="toggleButton" sx={{cursor: 'pointer', my: 1, marginLeft: -1, borderLeft: '4px solid #0262AB', display: 'flex', justifyContent: 'center', alignItems: 'center',py: 1}}>
          
          {open ? (
            <div>
              <IconCategoryFilled size={16} style={{ marginRight: '4px' }} />
              <span>Menu</span>
            </div>
          ) : null}
            <IconButton
            color="primary"
            
          sx={{
            margin: '0',
            padding: 1,
          }}
        >
        
        {open ? <IconSquareRoundedArrowLeftFilled size={40} /> : <IconSquareRoundedArrowRightFilled size={40}/> }
      
      </IconButton>
      </Box>
        <Divider />
        
        {buttons.map(button => (
          <Tooltip arrow title={!open ? button.openLabel : ''} placement="right"
            componentsProps={{
              tooltip: {
                sx: {
                  backgroundColor: '#0262AB',  // Custom background color
                  color: '#fffff',  // Custom text color
                  fontSize: '14px',  // Custom font size
                  borderRadius: '8px',  // Custom border radius
                  padding: '8px 12px',  // Custom padding
                },
              },
              arrow:{
                sx:{
                  color: '#0262AB',
                }
              }
            }}
            key={button.id}
          >
           <div className="buttonContainer" onClick={() => {
            if (activeButton !== button.id || (activeButton === 'search' && button.id === 'search')) {
              handleButtonClick(button);
            } 
          }}>
          <ListItem
            key={button.id}
            button
            className={`${activeButton === button.id ? 'item active' : 'item'}`}
            onMouseEnter={() => setHovering(button.id)}
            onMouseLeave={() => setHovering(null)}
            
            style={{ cursor: activeButton !== button.id || activeButton === 'search' && button.id === 'search' ? 'pointer' : 'default' }}
          >
            <ListItemIcon >
              <img src={activeButton === button.id ? button.activeIcon : button.icon} alt="Logo" className="align-self-center"/>
            </ListItemIcon>
            {/* <ListItemText primary={button.openLabel} /> */}
            
             <ListItemText primary={open ? button.openLabel : button.label} />
            
          </ListItem>
          </div>
          </Tooltip>
          
        ))}

      </Drawer>
      {open && <div className="overlay" onClick={toggleDrawer}></div>}
    </Box>
    <Dialog TransitionComponent={Slide}
      transitionDuration={300} open={isModalOpen} onClose={handleCloseModal} classes={{ paper: 'searchModal', container: 'dialog-top-center' }} >
      <DialogTitle id="headline-container">
        <img src='/Search.svg' alt="Logo" />
        <Row id="headline-text">
          <h1>Search MIMS Drugs4dent&#174;</h1>
          <h5>Towards Safer Medication Use:</h5>
                    <span>A Tailored Medicines Resource For Dental Practitioners</span>
        </Row>
      </DialogTitle>
      <DialogContent>
        <div id="search">
            <SearchBox handleCloseModal={handleCloseModal} toggleDrawer={toggleDrawer} />
        </div>
      </DialogContent>
    </Dialog>
  </>);
};

export default Sidebar;
