import React, { useState, useEffect, useRef } from 'react';

import { useHistory } from 'react-router-dom';
import { logPageViews, VerifySubscriptionEmailAcc } from '../../Services/LoginService'
import { useParams,useLocation } from 'react-router-dom';
import { Row, Col, Container } from 'react-bootstrap';
import { TextField, Modal, Button, Typography, FormControl, InputAdornment, IconButton, Stepper, Step, StepLabel, Box } from '@mui/material';
import { IconUser, IconBuilding, IconBriefcase, IconMapPin, IconPhone, IconMail, IconLock, IconEye, IconEyeOff, IconLogin, IconCheck, IconArrowForward, IconArrowLeft, IconArrowRight } from '@tabler/icons-react';




const EstoreVerification = () => {
  const { id } = useParams();
  const [token, setToken] = useState('')
  const [isVerify, setisVerify] = useState(false)
  const [isVerify2, setisVerify2] = useState(false)
  const [isVerify3, setisVerify3] = useState(true)
  const search = useLocation().search;
  const params = new URLSearchParams(search);
  const [interId, SetinterId] = useState(params.get('id'))
  //setToken(VerifyEmailAcc(id))
  console.log(interId)
  useEffect(() => {
    logPage()
    async function logPage() {
      await logPageViews("Verify Subscription", '')
    }

    async function Vemail() {
      document.getElementById('loaderPage').style.display = 'flex'
      setToken(await VerifySubscriptionEmailAcc(interId))
      document.getElementById('loaderPage').style.display = 'none'
    }
    if (isVerify) {
      setisVerify2(true)
    } else {
      Vemail()
    }


  }, [isVerify]);
  const [seconds, setSeconds] = useState(7);
  const history = useHistory();
  useEffect(() => {

    if (token !== '') {
      if (token.success = 'true') {

        //console.log(token.data.isSuccess)
        setisVerify(token.data.isSuccess)
        setisVerify3(token.data.isSuccess)
        // Start the timer if verification is successful
        const interval = setInterval(() => {
          setSeconds((prev) => prev - 1);
        }, 1000);

        // Redirect after the timer reaches 0
        if (seconds === 0) {
          clearInterval(interval);
          history.push('/login'); // Use history.push for redirection
        }

        // Cleanup interval on component unmount
        return () => clearInterval(interval);
      }else{
       
      }
    }
  }, [token, seconds, history]);



  // Function to immediately redirect to the login page
  const handleRedirect = () => {
    history.push('/login'); // Redirect to the login page
  };
  return (<>
    {isVerify2 ?
            <div className="d-flex flex-column align-items-center justify-content-center vh-100 w-50 mx-auto text-center">
            <img src="/Verified.svg" alt="Illustration" 
                style={{ maxWidth: '240px', width: 'auto', height: 'auto', marginBottom: '20px' }} 
              />
              <h2 style={{fontWeight: 'bold', color: '#0262AB'}}>Subscription Success!</h2>
              <h5 style={{color: '#727272', marginBottom: '40px'}}>You can now access MIMS Drugs4dent® using your sign in credentials</h5>
              <span style={{color: '#727272', marginBottom: '20px'}}>You will be redirected to the Sign In page in <strong>{seconds}</strong>, if you are not redirected automatically you can click the button below:</span>
              <Button
                onClick={handleRedirect}
                variant="contained"
                color="primary"
                sx={{
                  borderRadius: '32px',
                  padding: '16px 32px',
                  fontSize: '16px',
                  backgroundColor: '#0262AB',
                  '&:hover': { boxShadow: '0px 8px 24px 0px rgba(0, 153, 255, 0.15)' },
                }}
              >
                Proceed to sign in
              </Button>
          </div> 
            : 

            isVerify3 ? 
            '':
            <div className="d-flex flex-column align-items-center justify-content-center vh-100 w-50 mx-auto text-center">
            {/* <img src="/Verified.svg" alt="Illustration" 
                style={{ maxWidth: '240px', width: 'auto', height: 'auto', marginBottom: '20px' }} 
              /> */}
              <h2 style={{fontWeight: 'bold', color: '#0262AB'}}>Invalid Request!</h2>
              <h5 style={{color: '#727272', marginBottom: '40px'}}>If you believe this message is a mistake, please contact MIMS Customer Support at 1800 800 629 or support@mims.com.au.</h5>
              {/* <span style={{color: '#727272', marginBottom: '20px'}}>You will be redirected to the Sign In page in <strong>{seconds}</strong>, if you are not redirected automatically you can click the button below:</span> */}
              <Button
                onClick={handleRedirect}
                variant="contained"
                color="primary"
                sx={{
                  borderRadius: '32px',
                  padding: '16px 32px',
                  fontSize: '16px',
                  backgroundColor: '#0262AB',
                  '&:hover': { boxShadow: '0px 8px 24px 0px rgba(0, 153, 255, 0.15)' },
                }}
              >
                Proceed to sign in
              </Button>
          </div> 
        }

  </>);
}

export default EstoreVerification;