import Banner from '../../components/banner.js';
import MetaTags from 'react-meta-tags';
import { isLoggedIn } from '../../Services/Auth.js';
import Sidebar from '../../components/sidebar.js';
import LandingPageBanner from '../LandingPageBanner.js';
import React, { useState, useEffect, useRef } from 'react';
import { logPageViews } from '../../Services/LoginService.js';
import { Container, Row, Col, Placeholder } from 'react-bootstrap';
import { SendFeedbackEmail } from "../../Services/EmailService.js"
import { FeedBack as FeedbackPost } from '../../Services/LoginService.js';
import '../../styles/Feedback.scss';
import { useHistory, BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { Rating, TextField, Divider, Button, Chip, Typography, FormControl, InputAdornment, IconButton, Checkbox, FormControlLabel, Box, Modal, CircularProgress, Avatar } from '@mui/material';
import { IconUser, IconMapPinFilled, IconBuilding, IconBriefcase, IconMapPin, IconPhone, IconMail, IconSend, IconArticle, IconMailFilled, IconUserFilled, IconPhoneFilled } from '@tabler/icons-react';


const Feedback = () => {

  // Authenticate logged in status
  const [loggedIn, setLoggedIn] = useState(false);
  useEffect(() => {
        document.title = "Feedback"
        setLoggedIn(isLoggedIn());
        logPage(); 
            async function logPage() {
                await logPageViews('Feedback',"")
            }
  }, []);
  const loggedInField = {
    fullName: localStorage.getItem('fullName'),
    email: localStorage.getItem('email')
  };
  // Form fields
  const formFields = [
    { id: 'fullName', label: 'Full Name', icon: <IconUserFilled sx={{ fontSize: 16, pl: '10px' }} />, type: 'text' },
    { id: 'email', label: 'Email', icon: <IconMailFilled sx={{ fontSize: 16, pl: '10px' }} />, type: 'email' },
    { id: 'title', label: 'Title',  shrinkLabel: 'Title', icon: <IconArticle sx={{ fontSize: 16, pl: '10px' }} />, type: 'text' },
    { id: 'body', label: 'Message', placeholder: 'What can we do to improve your experience?', type: 'text' },
  ];

  // Form fields states
  const initialFieldState = formFields.reduce((acc, field) => {
    acc[field.id] = '';
    return acc;
  }, {});
  const [formValues, setFormValues] = useState(initialFieldState);
  useEffect(() => {
    if (loggedIn) {
      setFormValues((prevValues) => ({
        ...prevValues,
        email: loggedInField.email,
        fullName: loggedInField.fullName,
      }));
      setShrinked((prevShrinked) => ({ ...prevShrinked, email: true, fullName: true }));
    }
  }, [loggedIn, loggedInField]);
  
  // Form fields error states
  const initialErrorstate = formFields.reduce((acc, field) => {
    acc[field.id] = false;
    return acc;
  }, {});
  const [error, setError] = useState(initialErrorstate);
  const [emailErrorMessage, setEmailErrorMessage] = useState('');

  // Form fields label srhink states
  const initialShrinkState = formFields.reduce((acc, field) => {
    acc[field.id] = false;
    return acc;
  }, {});
  
  // Shrink label when there's an autofill value 
  const [shrinked, setShrinked] = useState(initialShrinkState);
  const makeAnimationStartHandler = (fieldId) => (e) => {
  const autofilled = !!e.target?.matches("*:-webkit-autofill");
    if (e.animationName === "mui-auto-fill" || e.animationName === "mui-auto-fill-cancel") {
        setShrinked((prevShrinked) => ({ ...prevShrinked, [fieldId]: autofilled || e.target.value !== '', }));
     }
  };

  // Rating
  const [rating, setRating] = useState(0);

  const handleRatingChange = (event, newValue) => {
    setRating(newValue);
  };

  const generateStarString = (rating) => {
    // Create a string of stars based on the rating value
    const fullStars = '★'.repeat(rating);  // Full stars
    const emptyStars = '☆'.repeat(5 - rating);  // Empty stars
    return fullStars + emptyStars;
  };

  // Input change validation
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const handleInputChange = (fieldId) => (e) => {
    const {value } = e.target;
    setFormValues((prevValues) => ({ ...prevValues, [fieldId]: value, }));
    setShrinked((prevShrinked) => ({ ...prevShrinked, [fieldId]: value !== '', }));
    setError((prevError) => ({ ...prevError, [fieldId]: false }));

    if (fieldId === 'email'){
      if (value.includes('@')) {
          if (!emailRegex.test(value)) {
              setError((prevState) => ({ ...prevState, email: true }));
              setEmailErrorMessage('Invalid email');
          } 
      } else {
          // Handle the case where '@' is not present (optional)
          setError((prevState) => ({ ...prevState, email: false }));
      }
    }
  
  }
  
  // Send button disabler to prevent multiple clicks
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  // Form Validation
  const handleSend = async (event) => {
    event.preventDefault();
    let isValid = true;
    let feedbackMessage = '';
    formFields.forEach((field) => {
      const fieldValue = formValues[field.id]?.trim(); // Trim the value
    
      // Validate
      if (fieldValue === '') { // Check if trimmed value is empty
        if (field.id === 'title') {
          // Skip validation for 'title' if needed
          feedbackMessage = formValues.body;
        } else {
          setError((prevError) => ({ ...prevError, [field.id]: true }));
          isValid = false;
        }
      } else {
        feedbackMessage = formValues.title + ": " + formValues.body;
        setError((prevError) => ({ ...prevError, [field.id]: false }));
      }
    
      if (field.id === 'email') {
        if (fieldValue === '') { // Check if trimmed value is empty
          setError((prevState) => ({ ...prevState, [field.id]: true }));
          setEmailErrorMessage('Email is required');
          isValid = false;
        } else if (!emailRegex.test(fieldValue)) { // Validate email format
          setError((prevState) => ({ ...prevState, [field.id]: true }));
          setEmailErrorMessage('Invalid email');
          isValid = false;
        }
      }
    });

    let ratingValue = '';
    if (rating) {
      ratingValue = generateStarString(rating);
    }

    if (isValid) {
      const { email, fullName, title, body } = formValues;
      
        setIsButtonDisabled(true);

        try {
            const response = await FeedbackPost(fullName, email, feedbackMessage, rating);

            if (response.success) {
                // Optionally handle success
                console.log('Feedback submitted successfully!');
                // Send the feedback email after successful feedback submission
                setIsButtonDisabled(true);
                sendFeedbackEmail( fullName, title, ratingValue, body, email);
            } else {
                // Optionally handle failure
                console.log('Failed to submit feedback. Please try again.');
            }
        } catch (error) {
            console.error(error);
            console.log('An unexpected error occurred. Please try again.');
        } finally {
            setIsButtonDisabled(false);
        }
    }
    
  };

 // Email template
 const [template, setTemplate] = useState('');
 useEffect(() => {
   fetch('/FeedbackReceipt.html')
       .then(response => response.text())
       .then(data => setTemplate(data));
 }, []);
 const sendFeedbackEmail = ( fullName, title, rating, body, email) => {
   if (template) {
       const processedTemplate = template
           .replace(/{fullName}/g, fullName)
           .replace(/{title}/g, title)
           .replace('{rating}', rating)
           .replace('{body}', body);

       SendFeedbackEmail(
           email,
           processedTemplate,
           '',
           'Thank you for your feedback!'
       );
       setOpen(true)
       setIsLoading(true);
       setTimeout(() => {
           setIsLoading(false); // Stop loading
           setIsButtonDisabled(false);
         }, 1000);
  
   }
 };
  // Success modal
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false); 

  // Open Modal
  const handleOpen = () => {
    setOpen(true);
  };
  const history = useHistory();
  // Close modal then proceed to login
  const handleClose = () => {
    setOpen(false);
    if (loggedIn){
      history.push('/Home');
    }
    else{
      history.push('/');
    }
  };

    return ( <>
        <MetaTags>
            <meta name="viewport" content="width=device-width, initial-scale=1" />
        </MetaTags>
          <Modal id="modal" open={open} disableBackdropClick  aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box id="forgotPassword">
              {isLoading ? (
                <>
                  <CircularProgress sx={{ color: '#0262AB', margin: 'auto', display: 'block' }} />
                </>
              ) : (
                <>
                  <img 
                    src="/Feedback.svg" 
                    alt="Illustration" 
                    style={{ maxWidth: '240px', width: '100%', height: 'auto', marginBottom: '16px' }} 
                  />
                  <Typography id="modal-modal-title" variant="h4" component="h2" gutterBottom sx={{ fontWeight: 'bold', color: '#0262AB' }}>
                  We Got Your Feedback!
                  </Typography>
                  <Typography id="modal-modal-description" sx={{ mt: 2, mb: 4, color: '#727272', fontSize: { xs: '0.9rem', sm: '1rem', md: '1.2rem' } }}>
                  Thanks a lot for sharing your feedback with us! We really appreciate you taking the time to let us know what you think.
                  </Typography>
                  <Button
                    onClick={handleClose}
                    variant="contained"
                    color="primary"
                    sx={{
                      borderRadius: '32px',
                      padding: '16px 32px',
                      fontSize: '16px',
                      marginTop: '16px',
                      backgroundColor: '#0262AB',
                      '&:hover': { boxShadow: '0px 8px 24px 0px rgba(0, 153, 255, 0.15)' },
                    }}
                  >
                    Got it
                  </Button>
                </>
              )}
            </Box>
          </Modal>
          {loggedIn ? (
            <Banner />
          ) : (
            <LandingPageBanner/>
          )}
          {loggedIn ? <Sidebar /> : null}
          <Container fluid id="main">
            <Row id="content">
              <Col>
                  <div id="contentCaption">
                    <h1>Feedback</h1>
                    <span>Have a question or feedback? Reach out to us!</span>
                  </div>
                  <Row id="contentContainer">
                    <Col lg={6} className="containerItem">
                      <h4>Send us a message</h4>
                      <Row id="message">
                        {formFields.slice(0, 2).map((field) => (
                            <Col lg={12} xl={6} key={field.id}>
                              <FormControl fullWidth margin="normal" required error={!!error[field.id]}>
                                <TextField
                                  id={field.id}
                                  name={field.id}
                                  type={field.type}
                                  label={field.label}
                                  variant="outlined"
                                  value={formValues[field.id]}
                                  onChange={handleInputChange(field.id)}
                                  fullWidth
                                  error={error[field.id]}
                                  helperText={error[field.id] ? (field.id === 'email' ? emailErrorMessage : `${field.label} is required`) : ''}
                                  InputProps={{
                                    onAnimationStart: makeAnimationStartHandler(field.id),
                                    startAdornment: <InputAdornment position="start">{field.icon}</InputAdornment>,
                                    readOnly: loggedIn
                                  }}
                                  InputLabelProps={{
                                    shrink: shrinked[field.id],
                                    sx: { fontWeight: 'bold' },
                                  }}
                                />
                              </FormControl>
                            </Col>
                          ))}
                          <Col xs={12} className="d-flex flex-column justify-content-center align-items-center">
                            <Typography
                              variant="h6"
                              gutterBottom
                              sx={{ mt: 4, fontSize: '16px' }} // Set font size to 16px
                            >
                              How's your experience?
                            </Typography>
                            <Rating
                              name="experience-rating"
                              value={rating}
                              onChange={handleRatingChange}
                              precision={1}
                              sx={{ fontSize: {lg:40, xs: 60 }  }}
                            />
                          </Col>
                          {formFields.slice(2).map((field) => (
                            <Col xs={12} key={field.id}>
                              <FormControl fullWidth margin="normal" required error={!!error[field.id]}>
                                <TextField
                                  id={field.id}
                                  name={field.id}
                                  type={field.type}
                                  label={field.label}
                                  placeholder={field.placeholder}
                                  variant="outlined"
                                  className={field.id === 'body' ? 'lastTextField' : ''}
                                  value={formValues[field.id]}
                                  multiline={field.id === 'body'}  // Add multiline only for field with id "body"
                                  rows={field.id === 'body' ? 8 : undefined}  // Set rows to 6 only for field with id "body"
                                  onChange={handleInputChange(field.id)}
                                  fullWidth
                                  error={error[field.id]}
                                  helperText={error[field.id] ? `${field.label} is required` : ''}
                                  InputProps={{
                                    startAdornment: field.id === 'title' ? (
                                      <InputAdornment position="start">{field.icon}</InputAdornment>
                                    ) : null,
                                    onAnimationStart: field.id === 'title' ? makeAnimationStartHandler(field.id) : undefined,
                                  }}
                                  InputLabelProps={{
                                    sx: { fontWeight: 'bold' },
                                    shrink: field.id === 'title' ? shrinked[field.id] : undefined,
                                  }}
                                />
                              </FormControl>
                            </Col>
                          ))}
                        <Box sx={{marginTop: 2 }}>
                          <Button
                            className="proceedButton"
                            variant="contained"
                            type="submit"
                            color="primary"
                            fullWidth
                            onClick={handleSend}
                            endIcon={<IconSend />}
                          >
                            Send Message
                          </Button>
                        </Box>
                      </Row>

                    </Col>
                    <Col xs={1} className="containerItem d-none d-lg-flex justify-content-center">
                    <Divider id="dividerCol" orientation="vertical" sx={{ borderColor: '#727272' }}><span>OR</span></Divider>
                    </Col>
                    <Col lg={5} className="containerItem">
                      <h4>Contact us</h4>
                      <Row id="contactUs">
                       
                        <Col xs={12}className="contactItem">
                            <Avatar >
                              <IconPhoneFilled />
                            </Avatar>
                            <div className="contactInfo">
                              <span>Phone Number</span>
                              <a href="tel:1800800629">
                                <h6 style={{ cursor: 'pointer' }}>1800 800 629</h6>
                              </a>
                            </div>
                          </Col>
                          <Col xs={12} className="contactItem">
                            <Avatar>
                              <IconMailFilled />
                            </Avatar>
                            <div className="contactInfo">
                              <span>Email</span>
                              <a href="mailto:subscriptions@mims.com.au">
                                <h6 style={{ cursor: 'pointer' }}>subscriptions@mims.com.au</h6>
                              </a>
                            </div>
                          </Col>
                          <Col xs={12} className="contactItem">
                            <Avatar>
                              <IconMapPinFilled />
                            </Avatar>
                            <div className="contactInfo">
                              <span>Office Address</span>
                              <a href="https://www.google.com/maps/search/?api=1&query=44+Market+Street,+Sydney+NSW+2000,+Australia">
                                <h6 style={{ cursor: 'pointer' }}>
                                  Suite 1602, Level 16, 44 Market Street, SYDNEY NSW 2000 Australia
                                </h6>
                              </a>
                            </div>
                        </Col>
                        <Col xs={12} className="contactItem">
                          <div className="mapContainer">
                            <iframe
                              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3310.0457929535306!2d151.2076017154669!3d-33.86761178065043!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b12a3a291c74f83%3A0xd7b5f7eb1e9f67d1!2s44%20Market%20St%2C%20Sydney%20NSW%202000%2C%20Australia!5e0!3m2!1sen!2sus!4v1695755510123!5m2!1sen!2sus"
                              width="600"
                              height="300"
                              style={{ border: 0 }}
                              allowFullScreen=""
                              loading="lazy"
                              referrerPolicy="no-referrer-when-downgrade"
                            ></iframe>
                          </div>
                        </Col>
                      </Row>
                        
                    </Col>
                    
                  </Row>
              </Col>  
            </Row>
          </Container>
    </> );
}
 
export default Feedback;