import React, { useState, useEffect, useRef } from 'react';
import '../styles/LandingPage.scss';
import { Container, Col, Row, Card, Carousel} from "react-bootstrap";
import ReactPlayer from 'react-player';
import { useHistory, useParams } from "react-router-dom";
import { isLoggedIn } from '../Services/Auth';
import { history } from '../Services/History';
import { IconArrowForward, IconPhoneFilled, IconMailFilled, IconMapPinFilled, IconHome } from '@tabler/icons-react';
import { Button, IconButton, AppBar, Toolbar, Tooltip, Typography, Divider} from '@mui/material';
import { useSpring, animated, config } from '@react-spring/web';
import { useInView } from 'react-intersection-observer';
import LandingPageBanner from './LandingPageBanner';
import Lottie from 'lottie-react';
import animationData from '../assets/HeroBannerAnimation.json';
import { logPageViews } from '../Services/LoginService';
import MetaTags from 'react-meta-tags';

const LandingPage = () => {
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    document.title = "MIMS Drugs4dent";
    logPage()
    async function logPage() {
      await logPageViews("Landing", "")
    }
    setLoggedIn(isLoggedIn());
  }, []);

  const history = useHistory();
  const handleSignUpClick = () => {
    if (loggedIn) {
      history.push('/home'); // Redirect to homepage if logged in
    } else {
      history.push('/signup'); // Redirect to signup if not logged in
    }
  };

  // Animation for fade in and slide up
  const { ref: refCaption, inView: inViewCaption } = useInView({
    triggerOnce: true, // Trigger animation once
    threshold: 0.1, // Trigger when 10% of the element is visible
  });
  const captionProps = useSpring({
     opacity: inViewCaption ? 1 : 0,
    transform: inViewCaption ? 'translateY(0)' : 'translateY(50px)',
    config: config.slow,
  });

  // Animation for smooth grow
  const { ref: refGrow, inView: inViewGrow } = useInView({
    triggerOnce: true, // Trigger animation once
    threshold: 0.3, // Trigger when 10% of the element is visible
  });
  const growProps = useSpring({
    opacity: inViewGrow ? 1 : 0,
    transform: inViewGrow ? 'scale(1)' : 'scale(0.9)',
    config: config.slow,
  });

  // Animation for fade in slide up in features section
  const { ref: refFeature, inView: inViewFeature } = useInView({
    triggerOnce: true, // Trigger animation once
    threshold: 0.1, // Trigger when 10% of the element is visible
  });
  const featureProps = useSpring({
    opacity: inViewFeature ? 1 : 0,
    transform: inViewFeature ? 'translateY(0)' : 'translateY(50px)',
    config: config.slow,
  });

  const { ref: refCards1, inView: inViewCards1 } = useInView({
    triggerOnce: true, // Trigger animation once
    threshold: 0.3, // Trigger when 10% of the element is visible
  });
  const cardsProps1 = useSpring({
    opacity: inViewCards1 ? 1 : 0,
    transform: inViewCards1 ? 'translateX(0)' : 'translateX(-50px)',
    config: config.slow,
  });

  const { ref: refCards2, inView: inViewCards2 } = useInView({
    triggerOnce: true, // Trigger animation once
    threshold: 0.3, // Trigger when 10% of the element is visible
  });
  const cardsProps2 = useSpring({
    opacity: inViewCards2 ? 1 : 0,
    transform: inViewCards2 ? 'translatex(0)' : 'translatex(50px)',
    config: config.slow,
  });
  const cardData = [
    {
      title: "Dental-Specific Drug Information",
      description: "Concise information supporting oral health specialists enabling safer use of medicines",
      imageSrc: "/carousel/DentalSpecificDrugInformation.svg",
    },
    {
      title: "Towards Safer Prescribing",
      description: "Up-to-date relevant information on prescription, over-the-counter and complementary medicines",
      imageSrc: "/carousel/TowardsSaferPrescribing.svg",
    },
    {
      title: "Patient Education",
      description: "Patient-friendly information on appropriate use of antibiotics for specific dental scenarios",
      imageSrc: "/carousel/PatientEducation.svg",
    },
    {
      title: "Clinical Decision Support",
      description: "Information on medication-related effects and risks that can affect dental treatments",
      imageSrc: "/carousel/ClinicalDecisionSupport.svg",
    },
    {
      title: "Drug & Allergy Interactions",
      description: "Rapid access to primary evidence-based information on medicine interactions, contraindications with other medicines of interest or patient’s allergic profile.",
      imageSrc: "./videos/Drugs4dent-Interaction-Checker.mp4",
    },
    {
      title: "Pregnancy & Lactation Guide",
      description: "Recommendations on potentially unsafe medications during pregnancy and lactation for safer prescribing",
      imageSrc: "./videos/Drugs4dent-Pregnancy-Lactation-Guide.mp4",
    },
    {
      title: "Dosing Calculations",
      description: "Easy-to-use tool to assist with dosage recommendations especially paediatric dosing for dental indications",
      imageSrc: "./videos/Drugs4dent-Dosage-Calculator.mp4",
    },
  ];

  return (
  <>
  <MetaTags>
            <meta name="viewport" content="width=device-width, initial-scale=1" />
  </MetaTags>
  <LandingPageBanner/>
  
  <Container fluid id="landingPage">
      <Row id="hero">
        <Col lg={6} md={12} className="d-flex flex-column justify-content-center " id="heroCaption">
          <animated.div ref={refCaption} style={captionProps}>
            <h1>Towards Safer Medication Use:</h1>
            <h2>A Tailored Medicines Resource For Dental Practitioners</h2>
            <h4>powered by <span>MIMS</span></h4>
            <Button id="signupButton" variant="outlined" color="primary" endIcon={loggedIn ? <IconHome /> : <IconArrowForward />} onClick={handleSignUpClick}>
              {loggedIn ? 'Go back to homepage' : 'Sign up for a 30-day trial'}
            </Button>
          </animated.div>
        </Col>
        <Col lg={6} md={12}  id="heroBanner">
        <Lottie animationData={animationData} loop={true} />
        </Col>
      </Row>
    
      <Row className="d-flex justify-content-center" id="discover">
        <Col className="text-center" id="videoContainer">
          <animated.div ref={refGrow} style={growProps}>
            <div className="react-player-container">
              <div className="aspect-ratio-container">
                <ReactPlayer
                  url='./videos/20240730_MIMSD4d_Video_V6_Final.mp4'
                  light='./videos/Drugs4dent-Teaser-Thumbnail.jpg'
                  controls={true}
                  playing
                  width='100%'
                  height='100%'
                  className="react-player"
                />
              </div>
            </div>
          </animated.div>
        </Col>
      </Row>

      <Row className="d-flex justify-content-center" id="features">
        <animated.div ref={refFeature} style={featureProps}>
          <div id="featureCaption">
            <h4>What's New?</h4>
            <h1>Succinct, Dental Relevant Information</h1>
          </div>
        </animated.div>
        <animated.div ref={refCards1} style={cardsProps1}>
        <Row className="row-equal-height">
          {cardData.slice(0, 4).map((card, index) => (
            <Col xs={12} md={6} lg={3} className="mb-4" key={index}>
              <Card className="card-custom">
                <div className="card-media">
                  <Card.Img variant="top" src={card.imageSrc} alt={card.title} />
                </div>
                <Card.Body>
                  <Card.Title className="card-title">{card.title}</Card.Title>
                  <Card.Text className="card-text">{card.description}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
        </animated.div>
        <animated.div ref={refCards2} style={cardsProps2}>
        <Row className="row-equal-height">
          {cardData.slice(4).map((card, index) => (
            <Col xs={12} md={6} lg={4}  className="mb-4" key={index}>
              <Card className="card-custom">
                <div className="card-media-gif">
                  <video autoPlay playsinline muted loop controlsList="nodownload">
                    <source src={card.imageSrc} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
                <Card.Body>
                  <Card.Title className="card-title">{card.title}</Card.Title>
                  <Card.Text className="card-text">{card.description}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
        </animated.div>
      </Row>
  
  </Container>
  </>
  );
}

export default LandingPage;