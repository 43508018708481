import { useHistory, useParams } from "react-router-dom";
import React, { useState, useEffect } from 'react';
//import useFetch from "./useFetch";
import useFetch from '../../useFetch';
import Banner from '../../components/banner.js';
import Sidebar from '../../components/sidebar.js';
import parse from 'html-react-parser';
import { SearchDetailById, getAbbrPiByID, getDrugInfo } from '../../Services/ProductSearchService.js'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { cleanUp } from "../../Services/Utilities.js";
import { logPageViews } from "../../Services/LoginService.js";

const ProductDetails = () => {
    const [activeButton, setActiveButton] = useState('search');
    const { id } = useParams();
    const history = useHistory();
    const { error, isPending, data } = useFetch('api/MimsDrug4dent/GetProductById?id=' + id)
    const [abbreviatedId, setAbbreviatedId] = useState('');

    const [DrugInfoDetails, setDrugInfo] = useState([]);
    const [AbbrevPI, setAbbrevPI] = useState([]);
    const [PrecautionText, setPrecautionText] = useState("");
    const [ContraindicationText, setContraindicationText] = useState("");
    const [OralEffectText, setOralEffectText] = useState([]);
    const [OralEffectTitle, setOralEffectTitle] = useState([]);
 
    const [modalShow, setModalShow] = useState(false);
    const [PrecautionmodalShow, setPrecautionmodalShow] = useState(false);
    const [OralModalShow, setOralModalShow] = useState(false);
    const [IsShowPrecaution, setIsShowPrecaution] = useState(false);

    const [InteractionDetails, setInteractionDetails] = useState([]);

    useEffect(() => {
        document.title = "MIMS Product Information"
    }, [])

    useEffect(() => {
        setAbbreviatedId('')
        if (!isPending) {
            // console.log(())
            if (data.brands?.length > 0) {

                setAbbreviatedId(data.brands[0].brandId)
                MyVerticallyCenteredModal()
                checkInteraction()
            }
        }
        async function checkInteraction() {
            setInteractionDetails(await SearchDetailById(data.brands[0].brandId))
            setDrugInfo(await getDrugInfo(data.brands[0].brandId))
            setIsShowPrecaution(true)
        }
        if(data?.productName != undefined) logPage()
        async function logPage() {
            await logPageViews('Product', data.productName)
        }
    }, [data])

    useEffect(() => {
        if (DrugInfoDetails.abbreviatedPi != null) {
            CheckPrecaution()
        }
        async function CheckPrecaution() {
            setAbbrevPI(await getAbbrPiByID(DrugInfoDetails.abbreviatedPi.abbreviatedPiId))
        }
    }, [DrugInfoDetails])

    useEffect(() => {
        if (AbbrevPI.content != null) {
            if (AbbrevPI.content[2].text.length > 0) {
                setPrecautionText(AbbrevPI.content[2].text)
            }
            setIsShowPrecaution(true)
        }
    }, [AbbrevPI])

    function MyVerticallyCenteredModal(props) {
        return (

            <Modal
                {...props}
                // size="xxl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="modalImgate"
            >
                <Modal.Header closeButton >
                    <Modal.Title id="contained-modal-title-vcenter">

                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="AbbreviatedBoxz">
                    <div className="AbbreviatedBox">
                        <iframe className="iframeStyle" src={"https://app.emims.plus/medicineview?id=" + abbreviatedId + "&type=abbpi&hideNavigation=true&token=raghav-plus"} loading='eager'></iframe>
                    </div>
                </Modal.Body>
                {/* <Modal.Footer>
                    <Button onClick={props.onHide}>Close</Button>
                </Modal.Footer> */}
            </Modal>
        );
    }
    function PrecautionModal(props) {
        return (

            <Modal
                {...props}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='modalZIndex'
            >
                <Modal.Header closeButton >
                    <Modal.Title id="contained-modal-title-vcenter">
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    {ContraindicationText != "" &&
                        <div className="mb-3">
                            <h5>Contraindications: </h5>
                            <div>{ContraindicationText}</div>
                        </div>
                    }
                    {PrecautionText != "" &&
                        <div>
                            <h5>Precautions: </h5>
                            <div>{PrecautionText}</div>
                        </div>
                    }
                </Modal.Body>
            </Modal>
        );
    }
    function OralEffectModal(props) {
        return (

            <Modal
                {...props}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='modalZIndex'
            >
                <Modal.Header closeButton >
                    <Modal.Title id="contained-modal-title-vcenter">
                        <h4>{OralEffectTitle}</h4>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    {OralEffectText}
                </Modal.Body>
            </Modal>
        );
    }

    const handleTxtValuePrecaution = () => {

        AbbrevPI.content.map(e => {
            if (e.header == "Precautions") { setPrecautionText(parse(e.text)) }
            if (e.header == "Contraindications") { setContraindicationText(parse(e.text)) }
        })
        setPrecautionmodalShow(true)
    };
    const handleTxtValueOralEffect = (Effect = '', Discription = '') => {
        setOralEffectTitle(Effect)
        //setOralEffectText(Discription)
        setOralEffectText(Discription.split('\\n').map((instruction, index) => {
            return <div key={index} className='mb-2'>{` ${instruction.split("\\r").join("")}`}</div>
        }))
        setOralModalShow(true)
    };


    const handleNavigateInteraction = (path) => {
        history.push('/druginteractions?id=' + InteractionDetails[0].id + "&name=" + InteractionDetails[0].name + "&type=" + InteractionDetails[0].type);
        // history.push(path);
    };
    return (
        <>
            <MyVerticallyCenteredModal
                show={modalShow}
                onHide={() => setModalShow(false)}

            />
            <PrecautionModal
                show={PrecautionmodalShow}
                onHide={() => setPrecautionmodalShow(false)}

            />
            <OralEffectModal
                show={OralModalShow}
                onHide={() => setOralModalShow(false)}

            />
            {/* {console.log(data.brands[0].brandId)} */}
            {isPending && <div>Loading...</div>}
            {error && <div>{error}</div>}
            <Banner />
            <div className=" body-content">
                <div className="row">
                    <div className="wit-100">
                        <div id="mySidenav" className="sidenav">
                            <Sidebar activeButton={activeButton} />
                        </div>
                    </div>
                    <div className="col leftmar overflow-auto">

                        {data.productName && (
                            <div className="boxBody">

                                <div className="mp120 ms-2 mb-5 me-4">
                                    <div className="row d-grid">
                                        <div className="col searchT2 d-flex ">
                                            <div className="col"><h2>{parse(data.productName)}{data.mimsClasses?.length == 0 && <div style={{ fontSize: '0.7em', color: 'red' }}> (Discontinued)</div>}</h2></div>
                                            <div className="col d-flex  justify-content-end gap-1 row">
                                                <div class="col d-flex  justify-content-end gap-1">
                                                    {!IsShowPrecaution && 
                                                        <Button variant="primary" className="" disabled>
                                                            Loading Buttons....
                                                        </Button>
                                                    }
                                                    {IsShowPrecaution &&
                                                        <Button variant="primary" className="" onClick={() => handleNavigateInteraction()}>
                                                            Drug Interactions
                                                        </Button>
                                                    }
                                                    {IsShowPrecaution && PrecautionText != "" &&
                                                        <Button variant="primary" className="" onClick={() => handleTxtValuePrecaution()}>
                                                            Contraindications & Precautions
                                                        </Button>
                                                    }
                                                    {IsShowPrecaution && AbbrevPI != "" &&
                                                        <Button variant="primary" className="" onClick={() => setModalShow(true)}>
                                                            MIMS Drug Information
                                                        </Button>
                                                     }
                                                </div>

                                                <div class="w-100"></div>
                                                <div class="col"></div>
                                                <div class="col"></div>



                                            </div>


                                        </div>
                                        <div className="col searchB mb-5">
                                            <div>
                                                <table className="table table-bordered">
                                                    <tbody>
                                                        <tr>
                                                            <td className="col-3">Brand Name:</td>
                                                            <td>
                                                                <ul className="pl-1">
                                                                    {data.brands && data.brands.map(blog => (
                                                                        parse(blog.name)
                                                                    ))}

                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        {data.mimsClasses?.length > 0 &&
                                                            <tr>
                                                                <td class="col-3">Drug Class:</td>
                                                                <td>
                                                                    {data.mimsClasses.length == 1
                                                                        ? data.mimsClasses.map(blog => (
                                                                            <ul className="pl-1">
                                                                                {blog.name}
                                                                            </ul>))
                                                                        :
                                                                        <ul>
                                                                            {data.mimsClasses.map(blog => (
                                                                                <li key={blog.id}>{blog.name}</li>
                                                                            ))}
                                                                        </ul>
                                                                    }
                                                                </td>
                                                            </tr>}
                                                        {data.acgs?.length > 0 &&
                                                            <tr>
                                                                <td className="col-3">Active Ingredients:</td>
                                                                <td>
                                                                    {data.acgs.length == 1
                                                                        ? data.acgs.map(blog => (
                                                                            <ul className="pl-1">
                                                                                {blog.name}
                                                                            </ul>))
                                                                        :
                                                                        <ul>
                                                                            {data.acgs.map(blog => (
                                                                                <li key={blog.id}>{blog.name}</li>
                                                                            ))}
                                                                        </ul>
                                                                    }
                                                                </td>
                                                            </tr>}
                                                        {data.indications.length > 0 &&
                                                            <tr>
                                                                <td className="col-3">Indications: </td>
                                                                <td>
                                                                    {data.indications != null
                                                                        ?
                                                                        <ul>
                                                                            {data.indications.map(blog => (
                                                                                <li key={blog.id}>{blog.name}</li>
                                                                            ))}
                                                                        </ul>
                                                                        :
                                                                        <ul class="pl-1">
                                                                            {data.indications[0]?.name}
                                                                        </ul>
                                                                    }


                                                                </td>
                                                            </tr>}
                                                        <tr>
                                                            <td className="col-3">Dental Procedural Considerations:</td>
                                                            <td>

                                                                {data.proceduralConsiderations == ''
                                                                    ? <ul className="pl-1"> Under review</ul>
                                                                    : data.proceduralConsiderations.length <= 1
                                                                        ? <ul className="pl-1">
                                                                            {data.proceduralConsiderations.map(blog => (cleanUp(blog.name)))}
                                                                        </ul>
                                                                        : <ul>
                                                                            {data.proceduralConsiderations.map(blog => (
                                                                                <li key={blog.id}>{cleanUp(blog.name)}</li>
                                                                            ))}
                                                                        </ul>
                                                                }


                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="col-3">Oral Adverse Effects</td>
                                                            <td>


                                                                {data.oralAdversEffects == '' ? <ul className="pl-1"> Under review</ul> :
                                                                    data.oralAdversEffects.length <= 1 ? <ul className="pl-1">
                                                                        {data.oralAdversEffects.map(blog => (

                                                                            <a href="#" onClick={() => handleTxtValueOralEffect(blog.effect, blog.description,)} >{blog.effect}</a>

                                                                        ))}
                                                                    </ul> :
                                                                        <ul>{data.oralAdversEffects.map(blog => (

                                                                            <li key={blog.id}><a href="#" onClick={() => handleTxtValueOralEffect(blog.effect, blog.description,)} >{blog.effect}</a> </li>

                                                                        ))}</ul>
                                                                }

                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>

                                    </div>

                                </div>

                            </div>
                        )}
                    </div>
                </div>
            </div>

        </>

    );
}

export default ProductDetails;