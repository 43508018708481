import Banner from '../../components/banner.js';
import Sidebar from '../../components/sidebar.js';
import LandingPageBanner from '../LandingPageBanner.js';
import React, { useState, useEffect, useRef } from 'react';
import { isLoggedIn } from '../../Services/Auth';
import { logPageViews } from '../../Services/LoginService.js';
import { Container, Row, Col, Placeholder } from 'react-bootstrap';
import MetaTags from 'react-meta-tags';

const Privacy = () => {
    
    const [loggedIn, setLoggedIn] = useState(false);

    useEffect(() => {
        document.title = "Privacy"
        setLoggedIn(isLoggedIn());
        logPage(); 
        async function logPage() {
            await logPageViews('Privacy',"")
        }
    }, []);


    function getData() {
        //try {document.getElementById("loader").style.display = "none"; } catch {}
        return (
            <>
                <p>This Privacy Policy applies to MIMS Australia Pty Ltd (ACN 050 695 157) and its related entities, within the meaning of the <i> Corporations Act 2001</i> (Cth) (<b>We, Our, Us</b>).</p>
                <p>As the publisher of a number of websites, including <a href="http://wwww.mimsdrugs4dent.plus">www.mimsdrugs4dent.com</a>, <a href="http://wwww.mims.plus">www.emims.plus</a>, <a href="http://app.mims.plus">app.emims.plus</a>, <a href="http://www.mims.com.au">www.mims.com.au</a>, <a href="http://www.mimsonline.com.au">www.mimsonline.com.au</a> and <a href="http://www.emims.com.au">www.emims.com.au</a> (<b>Websites</b>), and owner of products available on the Websites which may be purchased or licensed (<b>Products</b>), we are committed to protecting the privacy of the users of the Websites and/or Products (<b>You</b>).  </p>
                <p>We are bound by the Australian Privacy Principles (<b>Principles</b>) in the <i>Privacy Act 1988</i> (Cth) (<b>Act</b>). </p>
                <p>Please read this Privacy Policy carefully before You submit any information to Us as usage of the Websites and Products are conditional on You agreeing to be bound by this Privacy Policy. If You haveany questions, or would like to provide feedback, please feel free to contact Us at the address or email address listed below. </p>
                <h2>1. Types of personal information We collect about You</h2>
                <p>We aim only to collect 'personal information' (as defined under the Act) that is reasonably necessary for Our activities. The types of personal information that We collect will depend on the nature of the interaction between You and Us, and where and how We collect the information.</p>
                <p>Most commonly, the information collected from You may include Your name, address/es, email address/es, telephone and fax number/s, and other contact details, and also information about Your use of Our services. We may also collect information about Your use ofthe Websites such as what pages You visit and Your internet protocol(<b>IP</b>) address. By having this information We can tailor Your experience of the Website to Your personal interests.</p>
                <p>Any further collection of Your personal information than that explained here will only occur with Your consent.</p>
                <h2>2. How We collect Your personal information</h2>
                <p>We collect most of Your personal information directly from You, for example when You send us correspondence by e-mail, via the Websites or in writing; deal with Us over the telephone or in person; or register or post information on the Websites.</p>
                <p>Sometimes, someone else may provide Us with personal information about You, with or without Your direct involvement. For example, we might collect Your personal information from:</p>
                <ol type="a">
                    <li>another organisation or alliance you are a member of;</li>
                    <li>your representative;</li>
                    <li>publicly available sources of information (such as telephone directories); or</li>
                    <li>commercially available mailing or marketing lists.</li>
                </ol>
                <p>We will only retain this personal information if the personal information is reasonably necessary for, or directly related to, one or more of Our functions and activities and it is unreasonable or impracticable to obtain the information directly from You. We take will reasonable steps to either notify You or make you aware if we have collected information from someone other than You.</p>
                <h2>3. Using <b>Your</b> personal information</h2>
                <p>In most cases, if we ask You to provide information about Yourself it is because we need that information for the purpose it was collected or to provide You with the information You require to access one of the Websites. In addition, we may use Your personal information to:</p>
                <ol type="a">
                    <li>verify that You are an Australian healthcare practitioner (if relevant);</li>
                    <li>tailor Your experience of the Websites to Your interests;</li>
                    <li>provide You with access to certain parts of one of the Websites;</li>
                    <li>provide ongoing support or help manage the Websites, for example if You have a query about this Privacy Policy;</li>
                    <li>communicate with You, for example to seek feedback regarding your experiences with the Websites, or the information, the services and the promotions offered on the Websites;</li>
                    <li>provide information about other events, promotions, products or services that We believe would interest You (which you can tell us not to do at any time);</li>
                    <li>help Us run Our organisation, for example to improve the Websites, Our Products or services or Our security, train staff or undertake marketing activities; and</li>
                    <li>comply with legal obligations.</li>
                </ol>
                <p>By continuing to use our Websites, You consent to Us using your personal information for the additional purposes stated above.</p>
                <p>We may also use pooled information obtained from visitors to the Websites to understand Our users' interests and improve Our services. These reports may be shared with Our partners and sponsors, but they will not disclose personally identifiable information.</p>
                <h2>4. Disclosure of personal information </h2>
                <p>We aim to confine our disclosure of any personal information to the primary purpose for which it has been collected, or for a related purpose. This means we will usually only disclose personal information in order to fulfil our business and administrative functions, including when disclosure is necessary to complete a transactionon Your behalf; provide You with a product, service or activity You have requested; help Us with the running of Our organisation; or for security reasons.</p>
                <p>We may also disclose your account details if in good faith we believe that such disclosure is necessary to:</p>
                <ol type="a">
                    <li>comply with legal process;</li>
                    <li>enforce the terms and conditions of use of the third party servers;</li>
                    <li>respond to any claims that content You posted on the Websites violates the rights of any third parties;</li>
                    <li>respond to Your requests for customer service; or</li>
                    <li>protect Our rights and privacy and the rights and privacy of the users of the Websites and Our Products and services.</li>
                </ol>
                <p>We may provide Your personal information to:</p>
                <ol type="a">
                    <li>companies related to Us that are located in Australia;</li>
                    <li>outsourced service providers who perform functions on Our behalf, such as organisations providing security services, providing web hosting services, conducting market research, providing advertising/marketing services, or providing hosting, email or mail handling services to Us or Our service providers, including our disaster recovery servers which are located in Singapore and our mail fulfilment partner located in Singapore;</li>
                    <li>anyone authorised by You to receive your personal information (Your consent may be express or implied and can be withdrawn at any time);</li>
                    <li>an actual or prospective purchaser in the event we sell part of Our business; and</li>
                    <li>anyone We are required by law to disclose it to.</li>
                </ol>
                <p>By continuing to use our Websites, You consent to Us disclosing your personal information as stated above. We require Our service providers to adhere to strict privacy guidelines and not to keep this information or use it for any unauthorised purposes.</p>
                <h2>5. Cookies on the Website</h2>
                <p>We sometimes use cookie technology on the Websites to enableus to provide information and services to Websites' visitors. Cookies are pieces of information that a Website transfers to Your computer's hard disk for record keeping purposes and are a necessary part of facilitating online transactions. Most web browsers are set to accept cookies. The Websites use cookies to make Your use of the Websites and services as enjoyable as possible. Cookies in and of themselves do not personally identify users, although they do identify a user's browser. Cookies are useful to estimate Our number of members and determine overall traffic patterns through the Websites, as well as responding to Your requests for particular services.</p>
                <p>If You do not wish to receive any cookies You may set Your browser to refuse cookies. This may mean You will not be able to take full advantage of the Websites and/or Our services.</p>
                <h2>6. Links to other sites</h2>
                <p>The Websites contains links to other websites operated by third parties. Those linked websites are not controlled by us and we are not responsible for the privacy practices of the site operators. Before you disclose your personal information to any linked websites, we advise you to examine their privacy policies and terms and conditions of use.</p>
                <h2>7. Storage and security of personal information</h2>
                <p>We will endeavour to take all reasonable steps to ensure Your personal information is kept secure, accurate and up to date. Your information is stored on secure servers in Australia and Singapore that are protected in controlled facilities. Unfortunately, no data transmission over the Internet can be guaranteed to be 100% secure. So, while We make use of available technology, such as encryption software in taking reasonable steps to protect Your personal information, We cannot give an absolute assurance that the information You provide to Us will be secure at all times. You accordingly do so at Your own risk and We will not be held responsible for events arising from unauthorised access to Your personal information.</p>
                <h2>8. Updating personal information that <b>We</b> hold about You</h2>
                <p>If at any time You want to know exactly what personal information We hold about You, You are Welcome to access Your records by emailing Us at <a href="mailto:privacy.officer@mims.com.au">privacy.officer@mims.com.au</a>. We will, except in special circumstances set out in the Act, makeOur file of Your personal information available to You.</p>
                <p>If at any time You wish to change personal information that is inaccurate or out of date, please also contact Us by emailing <a href="mailto:admin@mims.com.au">privacy.officer@mims.com.au</a> and We will amend the record where it is reasonable to do so.</p>
                <h2>9. Password and log-in security</h2>
                <p>You are responsible for the security of Your password and log-in information. You can play an important role in keeping Your personal information secure by maintaining the confidentiality of any passwords used on Our Websites. Please notify Us immediately if there is any unauthorised use of Your account by any other Internet user or any other breach of security.</p>
                <h2>10. How to complain about a privacy breach</h2>
                <p>If You wish to complain about a potential breach of Your privacy by Us, contact us at <a href="mailto:admin@mims.com.au">privacy.officer@mims.com.au</a> and We will endeavour to resolve Your complaint as soon as possible. The Australian Information Commissioner also handles complaints under the Act (go to <a href="http://www.privacy.gov.au">www.privacy.gov.au</a> for more information).</p>
                <h2>Contact us</h2>
                <p>If You have questions about Our Privacy Policy and practices,or would like to provide feedback, please contact Us.</p>
                <p>
                    <b>Email:</b>
                    <br />
                    <a href="mailto:privacy.officer@mims.com.au">privacy.officer@mims.com.au</a>
                </p>
                <p>
                    <b>Mail:</b>
                    <br />MIMS Australia Pty Ltd<br />Privacy and Security<br />Locked Bag 3000<br />St Leonards, NSW 1590, Australia
                </p>
                <h2>Future Changes</h2>
                <p>We will continue to evolve as We introduce new services and features to the Websites. Because of this, from time to time, Our policies will be reviewed and may be revised. We reserve the right to change Our privacy policy at any time and notify You by posting an updated version of the policy on the Website. If You object to any changes, You may discontinue Your use of the services. The amended Privacy Policy will apply between Us whether or not We have given You specific notice of any change. We recommend that You review the Privacy Policy on Our Websites from time to time to keep abreast of any changes to Our Privacy Policy.</p>
                <h2>Your agreement</h2>
                <p>I agree that I have read and agree with the terms and conditions outlined in this privacy policy.</p>
                <br />
                <p>
                    <small>
                        <i>Last revised: March 21st 2014 (legal/41393579_1)</i>
                    </small>
                </p>
                <hr />
            </>
        )
    }
    return (<>
        <MetaTags>
            <meta name="viewport" content="width=device-width, initial-scale=1" />
        </MetaTags>
         {loggedIn ? (
            <Banner />
          ) : (
            <LandingPageBanner/>
          )}
          {loggedIn ? <Sidebar /> : null}
        <Container fluid id="main">
            <Row id="content">
              <Col>
                  <div id="contentCaption">
                    <h1>Privacy Policy</h1>
                    <hr className='d-lg-none d-md-flex'/>
                  </div>
                  <Row id="contentContainer">
                    <Col>
                     {getData()}
                     </Col>
                  </Row>
                </Col>
            </Row>
        </Container>
        </>);
}
export default Privacy;