export async function getPregnancy(moleculeId='', brandId='', mimsClass='', operator='OR', fetchPage='1',fetchLimit='20') {

    try
    {
        let url = process.env.REACT_APP_API_URL
        let res = await fetch(`${process.env.REACT_APP_API_URL}/pregnancy/search?moleculeId=${moleculeId}&brandId=${brandId}&mimsClass=${encodeURIComponent(mimsClass)}&matching=${operator}&page=${(fetchPage)}&limit=${(fetchLimit)}`,
//        let res = await fetch(`${process.env.REACT_APP_API_URL}/pregnancy/search?moleculeId=${moleculeId}&brandId=${brandId}&mimsClass=${encodeURIComponent(mimsClass)}&matching=${operator}&page=${(fetchPage)}&limit=${(fetchLimit)}`,
        {
            method:"GET",
            headers: {
                'Authorization':`Bearer ${localStorage.getItem('token').replaceAll('"', "")}`
            }
        });
     
        let json = await res.json();
        var count = parseInt(res.headers.get('total-count'));
        var page = parseInt(res.headers.get('page-index'));
        return {success:true, count:count, page:page, data:json};
        
    }
    catch(error)
    {
       return {success:false, count:0, page:0, data:[]};
    }
}   