

import React, { useState, useEffect } from 'react';
import useFetchGetToken from '../../Services/GetToken';
import GetFetchWithToken from '../../Services/GetFetchWithToken';
import { getEntities, gatewayInteraction } from "../../Services/IMGatewayInteractionService"

import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Banner from '../../components/banner.js';
import Sidebar from '../../components/sidebar.js';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Pagination, TabItem, Tabs, SelectField, Divider } from "@aws-amplify/ui-react";
import { logPageViews } from '../../Services/LoginService.js';

const IMGatewayHerbalInteractions = () => {
    const [activeButton, setActiveButton] = useState('imgateway');



    const [token, setToken] = useState('');

    const [getdatas, setgetdatas] = useState([]);

    const [entities, setEntities] = useState([]);
    useEffect(() => {
        document.title = "Drug & Allergy Interactions"

        document.getElementById('loader').style.display = 'flex';
        getEntities().then(items => {
            setEntities(items)
            document.getElementById('loader').style.display = 'none';
        })

        logPage("IMGateway", "", "")
    }, [])

    //console.log(entities.herbs);

    const [dataherb, setdataherb] = useState('')
    const [datadrug, setdatadrug] = useState('')
    const [datarecomendation, setdatarecomendation] = useState('')
    const [datapatientadvise, setdatapatientadvise] = useState('')
    const [dataevedentype, setdataevedentype] = useState('')
    const [datageneralcomemtary, setdatageneralcomemtary] = useState('')
    const [datareports, setdatareports] = useState('')
    const [recommendationRanking, setrecommendationRanking] = useState('')

    async function logPage(pageName, input1, input2) {
        await logPageViews(pageName, input1, input2)
    }

    function OnClicked(event) {
        //console.log(event.currentTarget.getAttribute("data-route"));
        setdataherb(event.currentTarget.getAttribute("data-herb"))
        setdatadrug(event.currentTarget.getAttribute("data-drug"))
        setdatarecomendation(event.currentTarget.getAttribute("data-recomendation"))
        setrecommendationRanking(event.currentTarget.getAttribute("data-recommendationanking"))
        setdatapatientadvise(event.currentTarget.getAttribute("data-patientadvise"))
        setdataevedentype(event.currentTarget.getAttribute("data-evedentype"))
        setdatageneralcomemtary(event.currentTarget.getAttribute("data-generalcomemtary"))
        setdatareports(event.currentTarget.getAttribute("data-reports"))

        //console.log(dataherb)
        setModalShow(true)
        logPage("IMGateway Details"
            , event.currentTarget.getAttribute("data-herb")
            , event.currentTarget.getAttribute("data-drug")
        )
    };
    function MyVerticallyCenteredModal(props) {
        return (
            <Modal
                {...props}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                size='xl'
                className="modalImgate"
            >
                <Modal.Header closeButton className='mPadLR'>
                    <Modal.Title>
                        <img src='../imgateway-logo.png' height='60px' />&nbsp;&nbsp;&nbsp;<img src='../unisyd-logo.png' height='60px' />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='mPadLR'>

                    <h2 className='mb-3'>{dataherb} <span className="text-danger">↔</span> {datadrug}</h2>

                    <Row className='align-items-center d-flex'>
                        <Col xs={4} className='align-items-center'><h5>GENERAL RECOMMENDATION</h5></Col>
                        <Col xs={8} ><div className={'recommendation recommendation' + recommendationRanking}>{datarecomendation}</div></Col>
                    </Row>


                    <Row className="pt-2">
                        <Col>
                            <h5>PATIENT ADVICE</h5>
                            {datapatientadvise}
                        </Col>
                    </Row>
                    <Row className="mt-3 pt-2">
                        <Col xs={2} className='align-items-center d-flex'><h5>EVIDENCE TYPE</h5></Col>
                        <Col xs={10} className="evidence  d-flex" >
                            <div className={(dataevedentype == "Well Established" ? " isActve" : "")}>Well Established</div>
                            <div className={(dataevedentype == "Good" ? " isActve" : "")}>Good</div>
                            <div className={(dataevedentype == "Limited" ? " isActve" : "")}>Limited</div>
                            <div className={(dataevedentype == "Not Established" ? " isActve" : "")}>Not Established</div>
                        </Col>
                    </Row>


                    {/* <div className=''>
                        <label className='col-12'>GENERAL COMMEMTARY ON EVIDENCE</label>
                        <label>{datageneralcomemtary}</label>
                    </div> */}

                    <Row className="mt-3 pt-2">
                        <Col md={12}><h5>GENERAL COMMENTARY ON EVIDENCE</h5></Col>
                    </Row>
                    <Row>
                        <Col md={12}>{datageneralcomemtary}</Col>
                    </Row>


                    {/* <div className=''>
                        <label className='col-12'>REPORTS</label>
                        <label>{datareports}</label>
                    </div> */}

                    {datareports.length > 0 && <>
                        <Row className="mt-3 pt-2">
                            <Col md={12}><h5>REPORTS</h5>

                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} className="reports">
                                <ul><li>
                                    {datareports}
                                </li>

                                </ul>
                            </Col>
                        </Row></>
                    }
                </Modal.Body>

            </Modal>
        );
    }

    const [modalShow, setModalShow] = useState(false);

    const [currentPageGW, setCurrentPageGW] = useState(1);
    const [herb, setHerb] = useState({})
    const [drug, setDrug] = useState({})
    const [drugClass, setDrugClass] = useState({})

    const [inputs, setInputs] = useState([]);
    const [interactingEntities, setInteractingEntities] = useState([]);
    const [gatewayinteractions, setgatewayInteraction] = useState([]);
    const [totalCountGW, setTotalCountGW] = useState(0);
    const onGWDropDownChangeHandler = async (type, event) => {

        //document.getElementById('loader').style.display = 'flex';

        const id = event.target.value
        const label = event.target.options[event.target.selectedIndex].text

        //allow only drug OR drugClass, not both
        if (id !== '' && type === 'drugClass' && drug.id !== '') {
            setDrug({})
            document.getElementById('drugsOption').value = ''

        }
        if (id !== '' && type === 'drugs' && drugClass.id !== '') {
            setDrugClass({})
            document.getElementById('drugClassOption').value = ''

        }

        if (type === "herbs") setHerb({id: id, name: label})
        else if (type === "drugs") setDrug({id: id, name: label})
        else if (type === "drugClass") setDrugClass({id: id, name: label})

        categoriseInputs()
    }

    useEffect(() => {
        logPage("IMGateway"
            , herb.name ?? ''
            , drug.name ? drug.name : drugClass.name ?? ''
            )
    }, [inputs])


    const categoriseInputs = async () => {
        //this function is only used on Page mode. Not used in Component mode
        //this divides selections into Inputs and InteractingEntities 

        setInputs([])
        setInteractingEntities([])

        const sHerb = document.getElementById('herbsOption')
        const sDrug = document.getElementById('drugsOption')
        const sDrugClass = document.getElementById('drugClassOption')

        if (sHerb.value !== '') {
            setInputs([{ id: sHerb.value, type: 'herbs'}])
            { sDrug.value !== '' && setInteractingEntities([{ id: sDrug.value, type: 'drugs'}]) }
            { sDrugClass.value !== '' && setInteractingEntities([{ id: sDrugClass.value, type: 'drugClass'}]) }
        }
        else {
            { sDrug.value !== '' && setInputs([{ id: sDrug.value, type: 'drugs'}]) }
            { sDrugClass.value !== '' && setInputs([{ id: sDrugClass.value, type: 'drugClass' }]) }
        }
    }
    const [countPerPage] = useState(20);

    useEffect(() => {

        if (inputs.length > 0) {
            document.getElementById('loader').style.display = 'flex';
            const filteredInput = (inputs)

            gatewayInteraction(filteredInput, interactingEntities, currentPageGW)
                .then(items => {
                    if (items.status === 204) {
                        setgatewayInteraction([])
                        setTotalCountGW(-1)
                        //console.log('asd'+gatewayinteractions);
                    }
                    else {
                        setgatewayInteraction(items.data.interactions);
                        //setNoInteractionDisclaimer(items.data.noInteractionDisclaimer);
                        setTotalCountGW(items.count);
                        document.getElementById('loader').style.display = 'none';
                        //console.log('df'+gatewayinteractions);
                    }
                    //document.getElementById('loader').style.display = 'none';
                })
                .catch(
                //document.getElementById('loader').style.display = 'none
            )

        }
        else {
            setgatewayInteraction([])
        }
    }, [inputs, interactingEntities, currentPageGW]);

    const clearAllImGatewayOnClick = () => {
        setInputs([])
        setInteractingEntities([])
        setgatewayInteraction([])
        setTotalCountGW(0)
        setCurrentPageGW(1)
        setHerb({})
        setDrug({})
        setDrugClass({})
        document.getElementById('herbsOption').value = ''
        document.getElementById('drugsOption').value = ''
        document.getElementById('drugClassOption').value = ''
    }



    return (
        <>
            <Banner />
            <div className=" body-content">
                <div className="row">
                    <div className="wit-100">
                        <div id="mySidenav" className="sidenav">
                            <Sidebar activeButton={activeButton} />
                        </div>
                    </div>
                    <div className="col leftmar overflow-auto">
                        <div className="boxBody mr-1">


                            <div className=" mp120">
                                <div className="row d-grid">
                                    <div className="col searchT border-0">
                                        <h2>IMGateway Herbal Interactions</h2>
                                    </div>

                                    {/* <Button variant="primary" onClick={() => setModalShow(true)}>
                        Launch vertically centered modal
                    </Button> */}

                                    <MyVerticallyCenteredModal
                                        show={modalShow}
                                        onHide={() => setModalShow(false)}
                                    />
                                    <div className='backgr d-flex'>

                                        <div className="row">
                                            <div className="col-3 d-flow white-box">
                                                <h4>Medications</h4>
                                                <div className="">
                                                    <label className="col-12">Herb / Supplement / Food</label>
                                                    <select name="herbsOption" id="herbsOption" defaultValue={herb.id} className="w-100" onChange={(event) => onGWDropDownChangeHandler('herbs', event)}>
                                                        <option value=""></option>
                                                        {entities.herbs && entities.herbs.map(val => (
                                                            <option key={val.id} value={val.id}>{val.name}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="row" style={{ margin: "30px 0 20px 0" }}>
                                                    <div className="col"><hr /></div>

                                                    <div className="col col-auto" style={{ color: "#2D6DA6", opacity: "75%" }}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="35" fill="rgb(45, 109, 166)" className="bi bi-plus-circle-fill" viewBox="0 0 16 16">
                                                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3z" />
                                                        </svg>
                                                    </div>
                                                    <div className="col"><hr /></div>
                                                </div>
                                                <div className="">
                                                    <label className="col-12">Drug</label>
                                                    <select name="drugsOption" id="drugsOption" defaultValue={drug.id} className="w-100" onChange={(event) => onGWDropDownChangeHandler('drugs', event)}>
                                                        <option value=""></option>
                                                        {entities.drugs && entities.drugs.map(val => (
                                                            <option key={val.id} value={val.id}>{val.name}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="row" style={{ margin: "10px 0 0 0" }}>
                                                    <div className="col"><hr /></div>
                                                    <div className="col col-auto justify-content-center d-flex">
                                                        <span className="">OR</span>

                                                    </div>
                                                    <div className="col"><hr /></div>
                                                </div>
                                                <div className="">
                                                    <label className="col-12">Drug class</label>
                                                    <select defaultValue={drugClass.id} name="drugClassOption" id="drugClassOption" className="w-100" onChange={(event) => onGWDropDownChangeHandler('drugClass', event)}>
                                                        <option value=""></option>
                                                        {entities.drugClass && entities.drugClass.map(val => (
                                                            <option key={val.id} value={val.id}>{val.name}</option>
                                                        ))}
                                                    </select>
                                                    {/* <SelectField defaultValue='148' id="drugClassOption" label="Drug Class" onChange={(e) => onGWDropDownChangeHandler('drugClass', e.target.value)}>
                                        <option value=""></option>
                                        {entities.drugClass && entities.drugClass.map(val => (
                                            <option key={val.id} value={val.id}>{val.name}</option>
                                        ))}
                                    </SelectField> */}
                                                </div>
                                                <div className="row mt-1"><div className="col"><a onClick={() => clearAllImGatewayOnClick()} id="ClearAll" >Clear All</a></div></div>

                                            </div>

                                            <div className="col white-box ml-2 ps-3 pe-3">
                                                <h2>IMGateway Interaction Results</h2>
                                                {totalCountGW > 0 && 
                                                    <label>Showing results {(currentPageGW * countPerPage) + 1 - countPerPage} to {(totalCountGW < (countPerPage * currentPageGW)) ? totalCountGW : parseInt(currentPageGW * countPerPage)} of {totalCountGW} total results.</label>
                                                }
                                                <div className="table-container mt-3">

                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                                <th>Herb/Supplement/Food</th>
                                                                <th>Drug</th>
                                                                <th>Drug class</th>
                                                                <th>Recommendation</th>
                                                                <th></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className='interactTb'>

                                                            {gatewayinteractions && gatewayinteractions.map(val =>
                                                            (
                                                                <tr key={val.generalInteractionId} data-route='mic'
                                                                    data-herb={val.herb}
                                                                    data-drug={val.drug}
                                                                    data-recomendation={val.recommendation}
                                                                    data-recommendationanking={val.recommendationRanking}
                                                                    data-patientadvise={val.patientAdvice}
                                                                    data-evedentype={val.evidenceType}
                                                                    data-generalcomemtary={val.commentaryOnEvidence}
                                                                    data-reports={val.reports.map(re => (
                                                                        re.title
                                                                    ))} onClick={OnClicked}>
                                                                    <td>{val.herb}</td>
                                                                    <td>{val.drug}</td>
                                                                    <td>{val.drugClass}</td>
                                                                    <td>
                                                                        <label className={'recommendation recommendation' + val.recommendationRanking}>{val.recommendation}</label>
                                                                    </td>
                                                                    <td className='align-middle gPosition'>
                                                                        <button className='gButton'
                                                                          
                                                                        >
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="blue" className="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
                                                                                <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                                                                            </svg>
                                                                        </button>

                                                                    </td>
                                                                </tr>
                                                            ))
                                                            }
                                                        </tbody>
                                                    </table>

                                                </div>
                                            </div>
                                        </div>


                                    </div>


                                </div>
                            </div>

                        </div >
                    </div>
                </div>
            </div>
        </>

    );
}

export default IMGatewayHerbalInteractions;