import React, { useState, useEffect, useRef } from 'react';

import { useHistory } from 'react-router-dom';
import { logPageViews, VerifyEmailAcc } from '../../Services/LoginService'
import { useParams } from 'react-router-dom';
import { Row, Col, Container } from 'react-bootstrap';
import { TextField, Modal, Button, Typography, FormControl, InputAdornment, IconButton, Stepper, Step, StepLabel, Box } from '@mui/material';
import { IconUser, IconBuilding, IconBriefcase, IconMapPin, IconPhone, IconMail, IconLock, IconEye, IconEyeOff, IconLogin, IconCheck, IconArrowForward, IconArrowLeft, IconArrowRight } from '@tabler/icons-react';

const VerifyEmailAccount = () => {
    const { id } = useParams();
    const [token, setToken] = useState('')
    const [isVerify, setisVerify] = useState(false)
    const [isVerify2, setisVerify2] = useState(false)
    //setToken(VerifyEmailAcc(id))
    useEffect(() => {
        logPage()
        async function logPage(){
            await logPageViews ("Verify Email", id)
        }

        async function Vemail() {
            setToken(await VerifyEmailAcc(id))
        }
        if (isVerify) {
            setisVerify2(true)
        } else {
            Vemail()
        }


    }, [isVerify]);
    const [seconds, setSeconds] = useState(7);
    const history = useHistory();
    useEffect(() => {

        if (token !== '') {
            if (token.success = 'true') {

                //console.log(token.data.isSuccess)
                setisVerify(token.data.isSuccess)
                 // Start the timer if verification is successful
                const interval = setInterval(() => {
                    setSeconds((prev) => prev - 1);
                }, 1000);
        
                // Redirect after the timer reaches 0
                if (seconds === 0) {
                    clearInterval(interval);
                    history.push('/login'); // Use history.push for redirection
                }
        
                // Cleanup interval on component unmount
                return () => clearInterval(interval);
                    }
        }
    }, [token, seconds, history]);
    
   
  
    // Function to immediately redirect to the login page
    const handleRedirect = () => {
      history.push('/login'); // Redirect to the login page
    };
    return (<>

        {isVerify2 ?
            <div className="d-flex flex-column align-items-center justify-content-center vh-100 w-50 mx-auto text-center">
            <img src="/Verified.svg" alt="Illustration" 
                style={{ maxWidth: '240px', width: 'auto', height: 'auto', marginBottom: '20px' }} 
              />
              <h2 style={{fontWeight: 'bold', color: '#0262AB'}}>Verified!</h2>
              <h5 style={{color: '#727272', marginBottom: '40px'}}>You can now access MIMS Drugs4dent® using your sign in credentials</h5>
              <span style={{color: '#727272', marginBottom: '20px'}}>You will be redirected to the Sign In page in <strong>{seconds}</strong>, if you are not redirected automatically you can click the button below:</span>
              <Button
                onClick={handleRedirect}
                variant="contained"
                color="primary"
                sx={{
                  borderRadius: '32px',
                  padding: '16px 32px',
                  fontSize: '16px',
                  backgroundColor: '#0262AB',
                  '&:hover': { boxShadow: '0px 8px 24px 0px rgba(0, 153, 255, 0.15)' },
                }}
              >
                Proceed to sign in
              </Button>
          </div> 
            : 
            <div>Invalid Account</div>
        }
        
    </>);
}

export default VerifyEmailAccount;