import useFetch from '../../useFetch';
import useFetch1 from '../../useFetch';
import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from "react-router-dom";
import Banner from '../../components/banner.js';
import Sidebar from '../../components/sidebar.js';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Accordion from 'react-bootstrap/Accordion';
import { IconInfoCircleFilled } from '@tabler/icons-react';
import { getAbbrPiByID, getDrugInfo} from '../../Services/ProductSearchService.js'
import parse from 'html-react-parser';
import { cleanUp } from '../../Services/Utilities.js';
import { logPageViews } from '../../Services/LoginService.js';

const DosingCalculations = () => {
    const [activeButton, setActiveButton] = useState('dosing');

    const history = useHistory();
    const { error, isPending, data } = useFetch('api/MimsDrug4dent/GetDosingCalculationDrugName')

    const [optiDCDrugNameon, setDCDrugName] = useState()
    const [PatientClassification, setPatientClassification] = useState([])
    const [doseId, setDoseId] = useState(undefined)
    const { id } = useParams();
    const [vpName, setvpName] = useState('')
    const [tabkey, setTabkey] = useState('0')

    const [brandId, setBrandId] = useState('')
    const [mimsContraText, setMimsContraText] = useState('')
    const [DrugInfo, setDrugInfo] = React.useState([]);
    const [AbbrevPI, setAbbrevPI] = React.useState([]);

    const [isMIMSLoading, setisMIMSLoading] = useState(false)

    function AcKey(event) {
        //console.log(event)
        setTabkey(event)
        if (event != null) {
        }
        setExpandDosingCalc(false)
        setExpandCalcRef(false)

    }

    useEffect(() => {
        document.title = "Dosing Calculations"
    }, [])

    useEffect(() => {
        if (brandId=="") { setMimsContraText("") }
        else { getDrugInfoAsync() }

        async function getDrugInfoAsync () {
            setisMIMSLoading(true)
            setDrugInfo(await getDrugInfo(brandId))
        }
    }, [brandId])

    useEffect(() => {
        //console.log('di', DrugInfo)
        if (DrugInfo.abbreviatedPi != null) {
            getAbbrevPIAsync()
        }
        async function getAbbrevPIAsync() {
            setAbbrevPI(await getAbbrPiByID(DrugInfo.abbreviatedPi.abbreviatedPiId))
        }
    }, [DrugInfo])

    useEffect(() => {
        //console.log('apic',AbbrevPI.content)
        if(AbbrevPI?.content) { GetMimsPrecaution() }
        async function GetMimsPrecaution() {
           let txt = "" 
           AbbrevPI.content.map(e => {
            if (e.header == "Contraindications" & e.text !="") { 
                txt =`<div>
                        <h5>Contraindications: </h5>
                        <div>${e.text}</div>
                    </div><br />`
            }
            if (e.header == "Precautions" & e.text !="") {
                 txt =txt + `<div>
                        <h5>Precautions: </h5>
                        <div>${e.text}</div>
                    </div>`
           }})
           setMimsContraText(parse(txt))
           setisMIMSLoading(false)
        }
    }, [AbbrevPI])

    useEffect(() => {
        if (data.length > 0) {
            if (id == undefined && doseId == undefined) {
                setDoseId(data[0]?.id)
                setvpName(data[0]?.name)
                setBrandId(data[0]?.brandId);
            }   
            if (id !== undefined) {
                setDoseId(id)
                setvpName(data.find(e=>e.id==id)?.name)
                setBrandId(data.find(e=>e.id==id)?.brandId);
            }

            if (doseId !== undefined) {
                setvpName(data.find(e=>e.id==doseId)?.name)
                setBrandId(data.find(e=>e.id==doseId)?.brandId);
            }

        }
        //checkToken()
    }, [data]);

    useEffect(() => {
        if (data.length > 0 && doseId!=undefined) {  
            setTabkey(0)
            setvpName(data.find(e=> e.id==doseId)?.name); 
            checkToken();
            history.push('/dosingcalculations/'+doseId)
        }
        
        async function checkToken() {
            fetch(process.env.REACT_APP_APIDrug4dents_URL + 'api/MimsDrug4dent/GetDosingCalculationPatientClassification?drugName=' + doseId,
                { headers: { 'Authorization': `Bearer ${localStorage.getItem('token').replaceAll('"', "")}` } }
            ).then((res) => {
                return res.json();
            })
                .then((data) => {
                    setPatientClassification(data);
                    setadminRoute('');
                    setshowGuidline(false)
                    setBrandId(data[0].brandId);
                }, [doseId]);
        }

        if(doseId!=undefined) logPage(); 
            async function logPage() {
                await logPageViews('Dosing',data?.find(e=> e.id==doseId)?.name ?? "")
            }
    }, [doseId]);

    const handleTxtValue = (buttonTypeId, TxtInfo, SecondTxtInfo) => {

        if (buttonTypeId == "Pregnancy") {
            setModalTitle('Pregnancy & Lactation')
            setModalTxtValue(TxtInfo.replaceAll("\\r\\n", " "))
            setModalTxtValue2(SecondTxtInfo.replaceAll("\\r\\n", " "))
            setPregnancyModalShow(true)
        }
        else if (buttonTypeId == "Precautions") {
            setModalTitle('Contraindications & Precautions')
            //console.log(TxtInfo)
            let txt = cleanUp(TxtInfo)
            setModalTxtValue(txt)
            // setModalTxtValue(TxtInfo.split('\\r\\n').map((instruction, index) => {
            //     return <div key={index} className='mb-2'>{` ${instruction.replaceAll("INSERT INTO prescriptions VALUES", " ")}`}</div>
            // }))
            setButtonModalShow(true)
        } 
        else if (buttonTypeId=="MIMSContra") {
            setModalTitle('Contraindications & Precautions')
            setModalTxtValue("")
            setButtonModalShow(true)
        }


    };
        const handleRedirectToInteraction = (vp_id, vp_name, molecule_id) => {
        console.log('mol', molecule_id)
        if(molecule_id) {
            history.push('/druginteractions?id=' + molecule_id + "&name=" + vpName + "&type=molecule");
            
        }
        else {
            history.push('/druginteractions?id=' + vp_id + "&name=" + vpName + "&type=vp");
        }
        //console.log(vp_id)

    };
    const [adminRoute, setadminRoute] = useState('')
    const [Prescriptionguideline, setPrescriptionguideline] = useState('')
    const [pctitle, setpctitle] = useState('')
    const [pcCalculation, setpcCalculation] = useState('')
    const [calculationReference, setcalculationReference] = useState('')
    function Patientclass(event) {
        if (event !== null) {
            //console.log(data);
            setadminRoute(event.target.selectedOptions[0].getAttribute('data-route'));
            setPrescriptionguideline(event.target.selectedOptions[0].getAttribute('data-prescriptionguideline'));
            setpctitle(event.target.selectedOptions[0].getAttribute('data-pctitle'));
            setpcCalculation(event.target.selectedOptions[0].getAttribute('data-pccalculation'));
            setcalculationReference(event.target.selectedOptions[0].getAttribute('data-calculationreference'));
        };
    }
    //console.log(data);
    const [showGuidline, setshowGuidline] = useState(false)
    const onClickshowGuidline = () => setshowGuidline(true)
    if (isPending) {
        document.getElementById('loaderPage').style.display = 'flex'
    } else {
        document.getElementById('loaderPage').style.display = 'none'
    }
    const [ButtonmodalShow, setButtonModalShow] = useState(false);
    const [ModalTxtValue, setModalTxtValue] = useState('');
    const [ModalTxtValue2, setModalTxtValue2] = useState('');
    const [ModalTitle, setModalTitle] = useState('');
    const [InteractionmodalShow, setInteractionModalShow] = useState(false);
    const [PregnancymodalShow, setPregnancyModalShow] = useState(false);
    const [LactationmodalShow, setLactationModalShow] = useState(false);
    const [PrecautionsContraindicationmodalShow, setPrecautionsContraindicationModalShow] = useState(false);
    const [PrintEmailmodalShow, setPrintEmailModalShow] = useState(false);

    const [expandDosingCalc, setExpandDosingCalc] = useState(false)
    const [expandCalcRef, setExpandCalcRef] = useState(false)

    const OnClickDosingCalc = () => { setExpandDosingCalc(!expandDosingCalc) }
    const OnClickCalcRef = () => { setExpandCalcRef(!expandCalcRef) }

    // function ButtonModal(props) {
    //     return (

    //         <Modal
    //             {...props}
    //             size="sl"
    //             aria-labelledby="contained-modal-title-vcenter"
    //             centered

    //         >
    //             <Modal.Header closeButton >
    //                 <Modal.Title id="contained-modal-title-vcenter">
    //                     <h4>{ModalTitle}</h4>
    //                 </Modal.Title>
    //             </Modal.Header>
    //             <Modal.Body>
    //                 {ModalTxtValue.replaceAll('\\r\\n', " ")}
    //                 {/* {
    //                     ModalTxtValue.split('\\r\\n').map((instruction, index) => {
    //                          <div key={index}>{` ${instruction}`}</div>
    //                     })
    //                 } */}

    //             </Modal.Body>
    //             <Modal.Footer>
    //                 <Button onClick={props.onHide}>Close</Button>
    //             </Modal.Footer>
    //         </Modal>
    //     );
    // }
    function ButtonPregLacModal(props) {
        return (

            <Modal
                {...props}
                size="sl"
                aria-labelledby="contained-modal-title-vcenter"
                centered

            >
                <Modal.Header closeButton >
                    <Modal.Title id="contained-modal-title-vcenter">
                        <h4>{ModalTitle}</h4>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h5>Pregnancy:</h5>
                    {ModalTxtValue}
                    <br />
                    <br />
                    <h5>Lactation:</h5>
                    {ModalTxtValue2}

                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={props.onHide}>Close</Button>
                </Modal.Footer>
            </Modal>
        );
    }
    function ButtonModal(props) {
        return (

            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered

            >
                <Modal.Header closeButton >
                    <Modal.Title id="contained-modal-title-vcenter">
                        <h4>{ModalTitle}</h4>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* {console.log(abbreviatedId)
                   
                   } */}
                
                    {ModalTxtValue!="" 
                        ? ModalTxtValue
                        : isMIMSLoading ? "Loading..." : mimsContraText }
                    {/* {ModalTxtValue.split("\r\n").map(function (item, idx) {
                        return (
                            <span key={idx}>
                                {item}
                                <br />
                            </span>
                        )
                    })} */}

                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={props.onHide}>Close</Button>
                </Modal.Footer>
            </Modal>
        );
    }

    return (
        <>
            <ButtonModal
                show={ButtonmodalShow}
                onHide={() => setButtonModalShow(false)}

            />
            <ButtonPregLacModal
                show={PregnancymodalShow}
                onHide={() => setPregnancyModalShow(false)}

            />

            <ButtonModal
                show={ButtonmodalShow}
                onHide={() => setButtonModalShow(false)}

            />

            <Banner />
            <div className=" body-content">
                <div className="row">
                    <div className="wit-100">
                        <div id="mySidenav" className="sidenav">
                            <Sidebar activeButton={activeButton} />
                        </div>
                    </div>
                    <div className="col leftmar overflow-auto">
                        <div className="boxBody mr-1">

                            <div className="mp120">

                                <div className="row d-grid">
                                    <div className="col">
                                        <h2>Dosing Calculations</h2>
                                        <p>Recommended dosages for prescribing of medicines in dentistry for adults and children</p>
                                        <p>{optiDCDrugNameon}</p>
                                    </div>
                                    <div className="col searchB mb-5">
                                        <div className='d-flex'>
                                            <div className='col-1'>
                                                <h5>Drug List:</h5>
                                            </div>
                                            <div className='col-4'>
                                                <select name="Drugname" id="Drugname" defaultValue={doseId} value={doseId} onChange={(event)=>setDoseId(event.target.value)}  >
                                                    <option value=""></option>
                                                    {data.length > 0 &&
                                                        data.map((val, index) => 
                                                            (!['Amitriptyline', 'Amphotericin', 'Articaine with/without adrenaline', 'Carbamazepine', 'Lidocaine with/without adrenaline', 'Mepivacaine with/without adrenaline', 'Methoxyflurane', 'Nitrous oxide', 'Oxcarbazepine', 'Prilocaine with felypressin', 'Prilocaine with/without adrenaline'
                                                                ].includes(val.name)
                                                                && <option key={val.id + "-" + index} value={val.id}>{val.name}</option>
                                                            ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className='d-flex col-12 mt-4'>
                                            <Tab.Container id="left-tabs-example" activeKey={tabkey} >
                                                <Row className='col-12 p-3'>
                                                    <Col sm={3} className='pclass'>
                                                        <h4>Patient classification: </h4>
                                                        <Nav variant="pills" className="flex-column">

                                                            {PatientClassification.length > 0 && PatientClassification.map((val, index) => {
                                                                return (<Nav.Item key={val.id + "-" + index} >
                                                                    <Nav.Link eventKey={index} onClick={() => { AcKey(index) }} >{cleanUp(val.patientCaterory)}</Nav.Link>
                                                                </Nav.Item>)

                                                            })}
                                                        </Nav>
                                                    </Col>
                                                    <Col sm={9}>
                                                        <Tab.Content>
                                                            {PatientClassification.length > 0 && PatientClassification.map((val, index) => {
                                                                return <Tab.Pane key={val.id + "-" + index} eventKey={index}>

                                                                    <div id="showGuide" className="ps-3">
                                                                        <table className="table table-bordered">
                                                                            <thead>
                                                                                <tr><td colSpan="2"><h2>{vpName}</h2></td></tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td className="small">Patient&nbsp;category:</td>
                                                                                    <td>
                                                                                        <label>{cleanUp(val.patientCaterory)}</label>
                                                                                    </td>
                                                                                </tr>
                                                                                {
                                                                                    // val.routeOfAdmin == "Oral" ? '' : 
                                                                                    <tr>
                                                                                        <td className="small">Route&nbsp;of&nbsp;administration:</td>
                                                                                        <td>
                                                                                            <label>{val.routeOfAdmin}</label>
                                                                                        </td>
                                                                                    </tr>}

                                                                                <tr>
                                                                                    <td className="small">Suggested&nbsp;Prescription:</td>
                                                                                    <td>
                                                                                        <label>{val.prescriptionDescription}</label>
                                                                                    </td>
                                                                                </tr>

                                                                                {val.prescriptionCalculation.map(e=>e.pcCalculation).join("") !="" &&
                                                                                <tr><td colSpan="2">
                                                                                    <a className="hover" onClick={() => OnClickDosingCalc()} style={{ fontSize: 12 }}>Dose calculation<IconInfoCircleFilled style={{ marginTop: -5, marginLeft: 3 }} size="15" color='blue' className='mleft-1' /></a>
                                                                                    {expandDosingCalc &&

                                                                                        // { console.log(val.prescriptionCalculation) }

                                                                                        < div style={{ fontSize: 14, marginTop: 10, marginLeft: 20 }}>


                                                                                            {val.prescriptionCalculation.map((presCal) => {

                                                                                                return (
                                                                                                    <div><b><u>{presCal.pcTitle}</u></b>
                                                                                                    {
                                                                                                        presCal.pcCalculation.split('\\r\\n').map((instruction, index) => {
                                                                                                            return <div>{` ${instruction}`}</div>;
                                                                                                        })
                                                                                                    }
                                                                                                    <br/>
                                                                                                    </div>
                                                                                                )
                                                                                            }

                                                                                            )}

                                                                                            {/* <div>{val.pcTitle}</div>
                                                                                    {
                                                                                       
                                                                                        val.pcCalculation.split('\\r\\n').map((instruction, index) => {
                                                                                            return <div>{` ${instruction}`}</div>;
                                                                                        })
                                                                                    } */}
                                                                                        </div>}
                                                                                </td>
                                                                                </tr>
                                                                                }
                                                                                {val.calculationReference !="" &&
                                                                                <tr>
                                                                                    <td colSpan="2">
                                                                                        <a className="hover" onClick={() => OnClickCalcRef()} style={{ fontSize: 12 }}>Calculation reference<IconInfoCircleFilled style={{ marginTop: -5, marginLeft: 3 }} size="15" color='blue' className='mleft-1' /></a>
                                                                                        {expandCalcRef &&
                                                                                        <div style={{ fontSize: 14, marginTop: 10, marginLeft: 20 }}>
                                                                                            {
                                                                                                val.calculationReference.split('\\r\\n').map(function (item, idx) {
                                                                                                    return (
                                                                                                        <label key={idx}>
                                                                                                            {item.replaceAll('\\"', "")}
                                                                                                            <br />
                                                                                                        </label>
                                                                                                    )
                                                                                                })
                                                                                            }
                                                                                        </div>}
                                                                                    </td>
                                                                                </tr>
                                                                                }


                                                                            </tbody>
                                                                        </table>

                                                                    </div>
                                                                    {/* <div className="ms-3">

                                                                    <Accordion>
                                                                        <Accordion.Item eventKey="0">
                                                                            <Accordion.Header><div class="small">Dose calculation </div>
                                                                            <IconInfoCircleFilled  className='mleft-1 blue'/>
                                                                              </Accordion.Header>
                                                                            <Accordion.Body aria-expanded>
                                                                            <Accordion.Header>Dose calculation
                                                                                <IconInfoCircleFilled className='mleft-1' />
                                                                            </Accordion.Header>
                                                                            <Accordion.Body>
                                                                                <div>{val.pcTitle}</div>
                                                                                {
                                                                                    val.pcCalculation.split('\\r\\n').map((instruction, index) => {
                                                                                        return <div>{` ${instruction}`}</div>;
                                                                                    })
                                                                                }
                                                                            </Accordion.Body>
                                                                        </Accordion.Item>
                                                                        <Accordion.Item eventKey="1">
                                                                            <Accordion.Header>Calculation Reference 
                                                                            <IconInfoCircleFilled className='mleft-1' />
                                                                                </Accordion.Header>
                                                                            <Accordion.Body>
                                                                                {
                                                                                    val.calculationReference.split('\\r\\n').map(function (item, idx) {
                                                                                        return (
                                                                                            <label key={idx}>
                                                                                                {item.replaceAll('\\"', "")}
                                                                                                <br />
                                                                                            </label>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </Accordion.Body>
                                                                        </Accordion.Item>
                                                                    </Accordion>

                                                                    </div> */}
                                                                    <div className="col d-flex  justify-content-start gap-1 ms-3 mt-3 pt-2">
                                                                        <Button variant="primary" onClick={() => handleRedirectToInteraction(val.vpId, val.name, val.moleculeId)}>
                                                                            Drug & Allergy Interactions
                                                                        </Button>
                                                                        {val.brandId=="" 
                                                                         ?  <Button variant="primary" onClick={() => handleTxtValue('Precautions', val.precaution, '')}>
                                                                                Contraindications & Precautions
                                                                            </Button>
                                                                         :  <Button variant="primary" onClick={() => handleTxtValue('MIMSContra', mimsContraText, '')}>
                                                                                Contraindications & Precautions
                                                                            </Button>
                                                                        }

                                                                        <Button variant="primary" onClick={() => handleTxtValue('Pregnancy', val.pregnancyInfo, val.lactaionInfo)}>
                                                                            Pregnancy & Lactation
                                                                        </Button>
                                                                        {/* <Button variant="primary" onClick={() => handleTxtValue('Print', "val.pregnancyInfo")}>
                                                                            Print & Email
                                                                        </Button> */}
                                                                    </div>
                                                                </Tab.Pane>

                                                            })}



                                                            {/* <Tab.Pane eventKey="first">First tab content</Tab.Pane>
                                            <Tab.Pane eventKey="second">Second tab content</Tab.Pane> */}
                                                        </Tab.Content>
                                                    </Col>
                                                </Row>
                                            </Tab.Container>
                                        </div>



                                        <div className="row">
                                            <div className="col-2 d-grid">
                                                {/* <label>Drug name</label> */}
                                                {/* <label>Patient classification</label>
                                <label>Route of administration</label> */}

                                            </div>
                                            <div className="col-4 d-grid">

                                                {/* <select name="Patientclass" id="Patientclass" onChange={Patientclass}>
                                    <option value=""></option>
                                    {PatientClassification.length > 0 && PatientClassification.map((val, index) => (
                                        <option key={val.id + "-" + index} value={val.id}
                                            data-route={val.routeOfAdmin}
                                            data-prescriptionguideline={val.prescriptionDescription}
                                            data-pctitle={val.pcTitle}
                                            data-pccalculation={val.pcCalculation}
                                            data-calculationreference={val.calculationReference}
                                        >{val.patientCaterory}</option>
                                    ))}
                                </select>
                                <input type="text" value={adminRoute} disabled /> */}


                                            </div>
                                        </div>
                                        {/* <button type="button" className="btn btn-primary btnFot gui martop" onClick={onClickshowGuidline}>Show Guideline</button> */}
                                        {showGuidline &&
                                            <div id="showGuide" className="martop">
                                                <table className="table table-bordered">
                                                    <tbody>
                                                        <tr>
                                                            <td className="col-2">Suggested prescription:</td>
                                                            <td>
                                                                <label>{Prescriptionguideline}</label>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Dose calculation:</td>
                                                            <td>
                                                                <div>{pctitle}</div>
                                                                {
                                                                    pcCalculation.split('\\r\\n').map(function (item, idx) {
                                                                        return (
                                                                            <label key={idx}>
                                                                                {item}
                                                                                <br />
                                                                            </label>
                                                                        )
                                                                    })
                                                                }

                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Calculation Reference:</td>
                                                            <td>
                                                                {
                                                                    calculationReference.split('\\r\\n').map(function (item, idx) {
                                                                        return (
                                                                            <label key={idx}>
                                                                                {item}
                                                                                <br />
                                                                            </label>
                                                                        )
                                                                    })
                                                                }
                                                            </td>
                                                        </tr>

                                                    </tbody>
                                                </table>

                                            </div>
                                        }


                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div >
        </>







    );
}

export default DosingCalculations;