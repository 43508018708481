

export async function ValidateLogin(email = '', password = '', token = '',  ipAddress = '',  urls = '') {

    try {
        console.log(process.env.REACT_APP_APIDrug4dents_URL)
        let url = process.env.REACT_APP_API_URL
       // let res = await fetch(`${process.env.REACT_APP_APIDrug4dents_URL}api/MimsDrug4dent/Login?email=${email}&password=${password}&ipAddress=${ipAddress}&urls=${urls}&token=${token}`);
        //console.log(await res.text())

        let res = await fetch(`${process.env.REACT_APP_APIDrug4dents_URL}api/MimsDrug4dent/Login`,
        {
            method:"POST",
            headers: {
                'Accept': 'application/json, text/plain',
                'Content-Type': 'application/json;charset=UTF-8'
            },
            body:JSON.stringify({
               'email':email,
               'password':password,
               'token':token,
               'ipAddress':ipAddress,
               'urls':urls
            })
        });
        let json = await res.json();

        var count = parseInt(res.headers.get('total-count'));
        var page = parseInt(res.headers.get('page-index'));
        return { success: true, count: 0, page: 0, data: json };

    }
    catch (error) {
        console.log(error)
        return { success: false, count: 0, page: 0, data: [] };
    }
}

export async function UserSession(ProcessType = '',Sessionid = '',GroupId = '', UserId = '', IpAddress = '',  Url = '',  LoginType = '',DeviceType = '',DeviceOs = '',DeviceVersion = '',Apptype = '') {

    try {
        console.log('seesionlog In')
        console.log(Sessionid)
      

        let res = await fetch(`${process.env.REACT_APP_APIDrug4dents_URL}api/UserLog/UserSession`,
        {
            method:"POST",
            headers: {
                'Accept': 'application/json, text/plain',
                'Content-Type': 'application/json;charset=UTF-8'
            },
            body:JSON.stringify({
                "processType": ProcessType,
                "sessionid": Sessionid.toString(),
                "groupId": GroupId.toString(),
                "userId": UserId.toString(),
                "ipAddress": IpAddress.toString(),
                "url": Url.toString(),
                "loginType": LoginType.toString(),
                "deviceType": DeviceType.toString(),
                "deviceOs": DeviceOs.toString(),
                "deviceVersion": DeviceVersion.toString(),
                "apptype":  Apptype.toString()
            })
        });
        let json = await res.json();

        return {  data: json };

    }
    catch (error) {
        console.log(error)
        return { success: false, count: 0, page: 0, data: [] };
    }
}
export function RefreshToken(expiredToken, refreshToken, sessionId) {
    try {
        return fetch(`${process.env.REACT_APP_API_URL}/account/RefreshToken`,
            {

                method: "POST",
                body: JSON.stringify({
                    'expiredToken': expiredToken.replaceAll('"', ""),
                    'refreshToken': refreshToken.replaceAll('"', ""),
                    'sessionId': sessionId,
                }),
                headers: {
                    'Content-Type': 'application/json',

                }
            })
            .then(data => data.json())

    }
    catch (error) {
        return [];
    }
}
export async function ForgotAcc(email = '') {

    try {
        console.log(process.env.REACT_APP_APIDrug4dents_URL)
        let url = process.env.REACT_APP_API_URL
        let res = await fetch(`${process.env.REACT_APP_APIDrug4dents_URL}api/MimsDrug4dent/ForgotAccount?Email=${email}`);
        //console.log(await res.text())
        let json = await res.json();

        var count = parseInt(res.headers.get('total-count'));
        var page = parseInt(res.headers.get('page-index'));
        return { success: true, count: 0, page: 0, data: json };

    }
    catch (error) {
        console.log(error)
        return { success: false, count: 0, page: 0, data: [] };
    }
}
export async function ResetPass(token = '', newPass = '', confirmPass = '') {

    try {
        console.log(newPass)
        console.log(process.env.REACT_APP_APIDrug4dents_URL)
        let url = process.env.REACT_APP_API_URL
        // let res = await fetch(`${process.env.REACT_APP_APIDrug4dents_URL}api/MimsDrug4dent/ResetPassword?token=${token}&NewPassword=${'11111111'}&ConfirmPassword=${'11111111'}`,
        //     {
        //         method:"POST"
        //     }
        // );
        //console.log(await res.text())
        let res = await fetch(`${process.env.REACT_APP_APIDrug4dents_URL}api/MimsDrug4dent/ResetPassword`,
        {
            method:"POST",
            headers: {
                'Accept': 'application/json, text/plain',
                'Content-Type': 'application/json;charset=UTF-8'
            },
            body:JSON.stringify({
               'token':token,
               'newPassword':newPass,
               'confirmPassword':confirmPass
            })
        });
        let json = await res.json();

        var count = parseInt(res.headers.get('total-count'));
        var page = parseInt(res.headers.get('page-index'));
        return { success: true, count: 0, page: 0, data: json };

    }
    catch (error) {
        console.log(error)
        return { success: false, count: 0, page: 0, data: [] };
    }
}
export async function VerifyEmailAcc(token = '') {

    try {
        console.log(process.env.REACT_APP_APIDrug4dents_URL)
        let url = process.env.REACT_APP_API_URL
        let res = await fetch(`${process.env.REACT_APP_APIDrug4dents_URL}api/MimsDrug4dent/VerifyEmail?token=${token}`);
        //console.log(await res.text())
        let json = await res.json();

        var count = parseInt(res.headers.get('total-count'));
        var page = parseInt(res.headers.get('page-index'));
        return { success: true, count: 0, page: 0, data: json };

    }
    catch (error) {
        console.log(error)
        return { success: false, count: 0, page: 0, data: [] };
    }
}
export async function VerifySubscriptionEmailAcc(token = '') {

    try {
        console.log(process.env.REACT_APP_APIDrug4dents_URL)
        let url = process.env.REACT_APP_API_URL
        let res = await fetch(`${process.env.REACT_APP_APIDrug4dents_URL}api/MimsDrug4dent/eStoreD4D?eStoreToken=${token}`);
        //console.log(await res.text())
        let json = await res.json();

        var count = parseInt(res.headers.get('total-count'));
        var page = parseInt(res.headers.get('page-index'));
        return { success: true, count: 0, page: 0, data: json };

    }
    catch (error) {
        console.log(error)
        return { success: false, count: 0, page: 0, data: [] };
    }
}

export async function Signup(email = '', newPass = '', confirmPass = '', fullName = '', company = '', occupation = '', address = '', phoneNumber = '') {

    try {
        // ?Email=${email}&NewPassword=${newPass}&ConfirmPassword=${confirmPass}
        console.log(process.env.REACT_APP_APIDrug4dents_URL)
        let url = process.env.REACT_APP_API_URL
        let res = await fetch(`${process.env.REACT_APP_APIDrug4dents_URL}api/MimsDrug4dent/SignUp`,
            {

                method: 'POST',
                headers: {
                    'Accept': 'application/json, text/plain',
                    'Content-Type': 'application/json;charset=UTF-8'
                },
                body: JSON.stringify({
                    "email": email,
                    "newPassword": newPass,
                    "confirmPassword": confirmPass,
                    "fullName": fullName,
                    "company": company,
                    "occupation": occupation,
                    "address": address,
                    "phoneNumber": phoneNumber
                })
                
            }
        );
        //console.log(await res.text())
        let json = await res.json();

        var count = parseInt(res.headers.get('total-count'));
        var page = parseInt(res.headers.get('page-index'));
        return { success: true, count: 0, page: 0, data: json };

    }
    catch (error) {
        console.log(error)
        return { success: false, count: 0, page: 0, data: [] };
    }
}
export function GetIpAddress() {
    try
    {
        //checkip/api/user/GetIp
        //https://app.emims.plus/checkip/api/user/GetIp?isAll
        return   fetch(`checkip/api/user/GetIp`)
        .then(response => response.json())
        .then(data => data.ip)
    }
    catch(error)
    {
        return [];
    }
}
export async function FeedBack(name='',email='',feedback='',rate=0) {

    try {
        // ?Email=${email}&NewPassword=${newPass}&ConfirmPassword=${confirmPass}
        console.log(process.env.REACT_APP_APIDrug4dents_URL)
        let url = process.env.REACT_APP_API_URL
        let res = await fetch(`${process.env.REACT_APP_APIDrug4dents_URL}api/MimsDrug4dent/FeedBack`,
            {

                method: 'POST',
                headers: {
                    'Accept': 'application/json, text/plain',
                    'Content-Type': 'application/json;charset=UTF-8'
                },
                body: JSON.stringify({
                    "fullName": name,
                    "email": email,
                    "feedback": feedback,
                    "rate": rate
                  })
                
            }
        );
        //console.log(await res.text())
        let json = await res.json();

        var count = parseInt(res.headers.get('total-count'));
        var page = parseInt(res.headers.get('page-index'));
        return { success: true, count: 0, page: 0, data: json };

    }
    catch (error) {
        console.log(error)
        return { success: false, count: 0, page: 0, data: [] };
    }
}
export async function CheckuserSub() {

    try {
        // ?Email=${email}&NewPassword=${newPass}&ConfirmPassword=${confirmPass}
        //console.log(process.env.REACT_APP_APIDrug4dents_URL)
        const token = localStorage.getItem('userId');

        let url = process.env.REACT_APP_API_URL
        let res = await fetch(`${process.env.REACT_APP_APIDrug4dents_URL}api/MimsDrug4dent/CheckUserSub?Userid=`+ token,
            {
                method: 'GET',
                headers: {
                    // 'api-key': '191a3bca840c4db7bd8f3bcf81974e56',
                    // 'Content-Type': 'application/json',
                    // 'Accept': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token').replaceAll('"', "")}`
                }
            }
        );
        //console.log(await res.text())
        let json = await res.json();

        return { success: true, count: 0, page: 0, data: json };

    }
    catch (error) {
        console.log(error)
        return { success: false, count: 0, page: 0, data: [] };
    }
}

export async function logPageViews(pageName, input1="", input2="") {
    // getToken();
    try {
        console.log(localStorage?.getItem('userId'), pageName, input1, input2)
        return fetch(`${process.env.REACT_APP_APIDrug4dents_URL}api/MimsDrug4dent/LogPageView?userId=${localStorage?.getItem('userId') ?? '0'}&sessionId=&pageName=${pageName}&input1=${input1}&input2=${input2}`,
            {
                method: "GET",
                headers: {
                    'Authorization': `Bearer ${localStorage?.getItem('token')?.replaceAll('"', "")}`
                }
            })
    }
    catch (error) {
        return [];
    }
}