import React, { useState, useEffect } from 'react';
import { useHistory, useParams, useLocation } from "react-router-dom";
import Banner from '../../components/banner.js';
import Sidebar from '../../components/sidebar.js';
import { getProductList, getSearchHints } from '../../Services/ProductSearchService'
import parse from 'html-react-parser';
import '../../styles/searchresults.scss'
import { logPageViews } from '../../Services/LoginService.js';

const SearchResults = () => {
    const [activeButton, setActiveButton] = useState('search');
    const {name: molecule } = useParams();
    const [results, setResults] = useState([])
    const [hints, setHints] = useState([])
    const history = useHistory();

    const search = useLocation().search;
    const params = new URLSearchParams(search);

    const showHints = params.get("hints") ?? ''
    
    useEffect(() => {
        document.title = "Search Results"
    }, [])

    useEffect ( ()=> {
        setResults([])

        if (molecule.length > 1) {
            getList()
        }
        if(showHints=='') setHints([])


        async function getList() {
          await getProductList(molecule, true)
            .then(data => {
              setResults(data?.map(items => ({
                id: items.id,
                type: items.type,
                label: items.productName,
                acg:items.acg
              })));
            })
        }
    


        logPage()
        async function logPage() {
            await logPageViews("Search", molecule, showHints)
        }
    },[molecule])

    useEffect (()=> {
        if(showHints!='') {
            getHints()
        }

        async function getHints() {
            await getSearchHints(showHints).then (data => {
                setHints(data?.map(items => ({
                    id: items.id,
                    label: parse(items.productName),
                })));
            })
        }    
    }, [showHints])

    return (
        <>
          <Banner />

          <div className=" body-content">
                <div className="row">
                    <div className="wit-100">
                        <div id="mySidenav" className="sidenav">
                            <Sidebar activeButton={activeButton} />
                        </div>
                    </div>
                    <div className="col leftmar mb-5 overflow-auto">
                        <div className="boxBody mp120" >

                            {hints?.length > 0 &&
                            <div className="row hints-container">
                                <div className='label'>You searched for: <b>{showHints}</b></div>
                                <div className='label'>Did you mean: 
                                    { hints.map((items,index) => <a key ={index} href={`/SearchResults/${items.label}`} className='hints'>{items.label}</a>)}
                                </div>
                            </div>
                            }

                            <div className="row mt-3 mb-1" >
                                <div className="col"><span style={{fontSize: 18}}>
                                    {hints?.length> 0 
                                    ? <>Showing results for</> 
                                    : <>Products containing</>
                                    }</span> <span style={{fontWeight:600, fontSize: 22, color:'#0262ab'}}>{molecule}</span></div>
                            </div>                                
                            <div className="row" >
                                <span>Results count: {results.length}</span>
                            </div>
                            <div className="row" style={{paddingLeft: 5, width: 'calc(100vw - 150px)'}}>
                                {results.length >0 &&
                                    <table className="table results-table">
                                    {
                                        <thead>
                                            <tr>
                                                <th>Product Name</th>
                                                <th>Active Ingredients</th>
                                                <th style={{ minWidth: 10 }}></th>
                                            </tr>
                                        </thead>
                                    }
                                    <tbody className='interactTb'>
                                        {results.map((e,index) => {
                                            return(
                                                <tr key={index} onClick={()=> history.push('/ProductDetails/' + e.id)}>
                                                    <td>{e.label}</td>
                                                    <td>{e.acg.split(" + ").length > 4 ? 'Multiple Ingredients' : e.acg}</td>
                                                    <td><i className="fa-solid fa-circle-chevron-right" style={{ color: '#0262ab' }}></i></td>
                                                </tr>
                                        )})}
                                    </tbody>
                                </table>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SearchResults;
