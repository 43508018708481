export function SendEmail(toEmail,emailMessage,base64,subject,fileName) {
  try
  {
      fetch(
          "https://hdr3q370tc.execute-api.ap-southeast-1.amazonaws.com/sendEmail",
          {
            mode: "no-cors",
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              senderName: "support@mims.com.au",
              senderEmail: toEmail,
              message: emailMessage,
              base64Data:  base64,
              subject: subject,
              fileName: fileName,
              bcc:"customer_docs@mims.com.au;"
            }),
          }
        )
    
  }
  catch(error)
  {
      return [];
  }
}

export function SendFeedbackEmail(toEmail,emailMessage,base64,subject,fileName) {
  try
  {
      fetch(
          "https://hdr3q370tc.execute-api.ap-southeast-1.amazonaws.com/sendEmail",
          {
            mode: "no-cors",
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              senderName: "support@mims.com.au",
              senderEmail: toEmail,
              message: emailMessage,
              base64Data:  base64,
              subject: subject,
              fileName: fileName,
              bcc:"customer_docs@mims.com.au, jonathan.au@mims.com.au, luigi.yambao@mims.com, michael.sisgon@mims.com;"
            }),
          }
        )
    
  }
  catch(error)
  {
      return [];
  }
}