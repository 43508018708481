import '../../styles/footer.scss'
const AllFooter = () => {
    return (<>
        <div id="Footer" style={{ zIndex: '1300'}}>
            <div id="FooterLeft">

                <div><a href="/about" target="_blank">About</a></div>
                <div><a href="/privacy">Privacy</a></div>
                <div><a href="/feedback" target="_blank">Feedback</a></div>
                <div><a href="/terms">Terms & Conditions</a></div>
            </div>
            <div id="FooterRight">
                <div><a href="/copyright">&copy; MIMS Australia. All rights reserved.</a></div>
            </div>
        </div>

    </>);
}

export default AllFooter;