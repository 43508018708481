import LT_photo_1 from '../../Content/images/LT_photo_1.jpg'
import michaelMccullough from '../../Content/images/michael-mccullough-2023.jpeg'
import Banner from '../../components/banner.js';
import { isLoggedIn } from '../../Services/Auth';
import Sidebar from '../../components/sidebar.js';
import LandingPageBanner from '../LandingPageBanner.js';
import React, { useState, useEffect, useRef } from 'react';
import { logPageViews } from '../../Services/LoginService.js';
import { Container, Row, Col, Placeholder } from 'react-bootstrap';
import MetaTags from 'react-meta-tags';
const About = () => {
    const [loggedIn, setLoggedIn] = useState(false);

    useEffect(() => {
        document.title = "About"
        setLoggedIn(isLoggedIn());
        logPage(); 
            async function logPage() {
                await logPageViews('About',"")
            }
    }, []);

    function getData() {
        //try {document.getElementById("loader").style.display = "none"; } catch {}
        return (
            <>
                <h4 style={{color: '#0262AB'}}>MIMS Australia</h4>
                        <hr />
                        <p>MIMS Australia has been publishing medicines information since 1963. The acronym, MIMS, was derived from the original publication which was called Monthly Index of Medical Specialties.</p>
                        <p>MIMS is the leading supplier of trusted, quality, independent medicine information to Australian healthcare professionals, known for its high level of editorial integrity and independence. </p>
                        <p>MIMS Australia offers a range of digital products that reflect current advances in information delivery; from hand held devices to online to integrating databases into clinical software applications. MIMS also continues to offer the printed reference products that have supported health care professionals for over 60 years.</p>
                        <br />
                        <h4 style={{color: '#0262AB', marginTop: '40px'}}>MIMS Drugs4dent&#174;</h4>
                        <hr />
                        <p>In 2024, MIMS Australia and the University of Melbourne collaborated to produce MIMS Drugs4dent®, a reliable, evidenced-based source of drug information for dentists to use at point-of-care.</p>
                        <p>With drug information constantly evolving, MIMS Drugs4dent® provides up-to-date dental-relevant information on prescription drugs, over-the-counter products and complementary medicines, so dental practitioners will be alerted to the impacts that drugs can have on dental procedures. MIMS Drugs4dent® provides clinical decision support to improve medication safety by including drug and allergy interactions, pregnancy and lactation recommendations, and paediatric dosing guidance for dental indications. </p>
                        <p>The inventors of Drugs4dent®, Dr Teoh and Professor McCullough, anticipate that this will be a practical tool for dental practitioners to use in everyday clinical practice, to help inform prescribing decisions and assist with safe management of patients with respect to the use of medicines.</p>
                        <br />
                        <h4 style={{color: '#0262AB', marginTop: '40px'}}>Drugs4dent&#174;</h4>
                        <hr />
                        <p>Drugs4dent® was developed by Australian academics from the University of Melbourne, Dr Leanne Teoh and Professor Michael McCullough.</p>
                        <p>Dr Teoh is a Senior Research Fellow, and a dentist and pharmacist. She realised that drug resources were lacking in the dental industry to assist dentists regarding the impact of drugs on dental treatment, prescribing guidance and clinical tools to assist with dose calculations and safe prescribing. Professor McCullough is the Professor of Oral Medicine at the Melbourne Dental School with a long standing interest in dental prescribing. He noted a lack of a resource for dentists that included all aspects of medications encountered in dental practice that would enhance dentists’ knowledge of medications and their potential unwanted side-effects. This is not only for the medications that we prescribe, but the medications our patients are taking, which are increasing in both number and complexity. </p>
                        <p>Drugs4dent® is the first Australian medicines information resource designed specifically for dental practitioners. It provides succinct, dental-relevant information for practitioners in clinical settings to understand the effect of medications on dental treatment and support clinicians to accurately and safely prescribe. This clinical decision tool should allow for safe dental prescribing of both systemic and topical medications.</p>
            </>
        )
    }
    return (<>
        <MetaTags>
            <meta name="viewport" content="width=device-width, initial-scale=1" />
        </MetaTags>
         {loggedIn ? (
            <Banner />
          ) : (
            <LandingPageBanner/>
          )}
          {loggedIn ? <Sidebar /> : null}
        <Container fluid id="main">
            <Row id="content">
              <Col>
                  <div id="contentCaption">
                    <h1>About</h1>
                  </div>
                  <Row id="contentContainer">
                     <Col>
                     {getData()}
                     </Col>
                  </Row>
                </Col>
            </Row>
        </Container>
        </>);
}
export default About;