export function getProductAutocomplete(input, limit = '1', allergy = false) {
    // getToken();
    let types = `molecule,brand${allergy ? `,allergyclass` : ''}`
    let method = "startswith"
    //let limit=20
    let includeOffMarket = false

    const abortCont = new AbortController();

    try {
        return fetch(`${process.env.REACT_APP_API_URL}/searchSuggest?term=${encodeURIComponent(input)}&allergyclass=true&molecule=true&type=${types}&method=${method}&offMarket=${includeOffMarket}&limit=${limit}`,
            {
                signal: abortCont.signal,
                method: "GET",
                headers: {
                    // 'api-key': '191a3bca840c4db7bd8f3bcf81974e56',
                    // 'Content-Type': 'application/json',
                    // 'Accept': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token').replaceAll('"', "")}`
                }
            })
            .then(data => data.json()).catch(err => {
                if (err.name === 'AbortError') {
                  //console.log('fetch aborted')
                } else {
                  // auto catches network / connection error
                  //setIsPending(false);
                  //setError(err.message);
                }
              })

    }
    catch (error) {
        return [];
    }
}
export function SearchDetailById(Id = '') {
    // getToken();
    let types = 'molecule,brand,allergyclass'
    let method = "startswith"
    //let limit=20
    let includeOffMarket = true
    try {
        return fetch(`${process.env.REACT_APP_API_URL}/searchdetails?id=${Id}`,
            {
                method: "GET",
                headers: {
                    // 'api-key': '191a3bca840c4db7bd8f3bcf81974e56',
                    // 'Content-Type': 'application/json',
                    // 'Accept': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token').replaceAll('"', "")}`
                }
            })
            .then(data => data.json())

    }
    catch (error) {
        return [];
    }
}
export async function getAbbrPiByID(input) {
    //getToken();


    try {
        //return  fetch(`https://stagsgwsapim.azure-api.net/au/referenceproduct/v2/abbrevPI/${encodeURIComponent(data.ProductName)}`,
        //return  fetch(`${process.env.REACT_APP_API_URL}/abbrevPI/3e153a57-973c-4bf4-8f1d-a533011fc820`,
        return await fetch(`${process.env.REACT_APP_API_URL}/abbrevPI/${encodeURIComponent(input)}`,
            {

                method: "GET",
                headers: {
                    // 'api-key': '191a3bca840c4db7bd8f3bcf81974e56',
                    // 'Content-Type': 'application/json',
                    // 'Accept': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token').replaceAll('"', "")}`
                }
            })
            .then(data => data.json());
    }
    catch (error) {
        return [];
    }
}
export async function getDrugInfo(input) {
    try {
        let response = await fetch(`${process.env.REACT_APP_API_URL}/drugInfo/${encodeURIComponent(input)}`,
            {
                method: "GET",
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token').replaceAll('"', "")}`
                }
            });
        return await response.json();
    }
    catch (error) {
        return [];
    }
}

export function getProductAutocompleteByMolecule(input) {
    // getToken();
    let types = 'molecule'
    let method = "startswith"
    let limit = 20
    let includeOffMarket = true
    try {
        return fetch(`${process.env.REACT_APP_API_URL}/searchSuggest?term=${encodeURIComponent(input)}&allergyclass=true&molecule=true&type=${types}&method=${method}&offMarket=${includeOffMarket}&limit=${limit}`,
            {
                method: "GET",
                headers: {
                    // 'api-key': '191a3bca840c4db7bd8f3bcf81974e56',
                    // 'Content-Type': 'application/json',
                    // 'Accept': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token').replaceAll('"', "")}`
                }
            })
            .then(data => data.json())

    }
    catch (error) {
        return [];
    }
}
export function getProductAutocompleteByBrand(input) {
    // getToken();
    let types = 'Brand'
    let method = "startswith"
    let limit = 20
    let includeOffMarket = true
    try {
        return fetch(`${process.env.REACT_APP_API_URL}/searchSuggest?term=${encodeURIComponent(input)}&allergyclass=true&molecule=true&type=${types}&method=${method}&offMarket=${includeOffMarket}&limit=${limit}`,
            {
                method: "GET",
                headers: {
                    // 'api-key': '191a3bca840c4db7bd8f3bcf81974e56',
                    // 'Content-Type': 'application/json',
                    // 'Accept': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token').replaceAll('"', "")}`
                }
            })
            .then(data => data.json())

    }
    catch (error) {
        return [];
    }
}
export async function getProductList(input, check) {
    // getToken();
    let types = 'Brand'
    let method = "startswith"
    let limit = 20
    let includeOffMarket = true
    try {
        return fetch(`${process.env.REACT_APP_APIDrug4dents_URL}api/MimsDrug4dent/GetDrugList?input=${input}&isChecked=${check}`,
            {
                method: "GET",
                headers: {
                    // 'api-key': '191a3bca840c4db7bd8f3bcf81974e56',
                    // 'Content-Type': 'application/json',
                    // 'Accept': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token').replaceAll('"', "")}`
                }
            })
            .then(data => data.json())

    }
    catch (error) {
        return [];
    }
}
export async function getSearchHints(input) {
    // getToken();
    try {
        return fetch(`${process.env.REACT_APP_APIDrug4dents_URL}api/MimsDrug4dent/SearchDrugListHint?input=${input}`,
            {
                method: "GET",
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token').replaceAll('"', "")}`
                }
            })
            .then(data => data.json())
    }
    catch (error) {
        return [];
    }
}