import parse from 'html-react-parser';

export function getDataVersion() {
    
    try
    {
        return  fetch(`${process.env.REACT_APP_API_URL}/dataVersion`,
        {
            method:"GET",
            headers: {

                'Authorization':`Bearer ${localStorage.getItem('token').replaceAll('"', "")}`
            }
        })
        .then(data => data.json())
      
    }
    catch(error)
    {
        return [];
    }
}

export function cleanUp(text) {
    let s = text ?? ''
    if(s.includes("\\r\\n")) {
        s = `<p>${s}</p>`
        s = s.split("\\r\\n").join("</p><p>")
    }
    else s = s.split("\\r\\n").join("<br /><br />")
    
    s = s.replace('contains molecule', 'contains')
    s = s.replace('containing:', 'containing ')
    s = s.split(">=").join("&ge;")
    s = s.split('\\"').join('"')
    return parse(s)
}