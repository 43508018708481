import React, { useState, useEffect } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import Banner from '../../components/banner.js';
import parse from 'html-react-parser';
import '../../styles/Interactions.scss'
import MetaTags from 'react-meta-tags';
import Sidebar from '../../components/sidebar.js';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import AsyncSelect from "react-select/async";
import CreatableSelect from 'react-select/creatable';
import { getProductAutocomplete } from '../../Services/ProductSearchService'
import { getInteraction, getAllergyInteractions, getInteractionVP, getInteractionsDisclaimer } from '../../Services/IMGatewayInteractionService'
//import { Pagination, TabItem } from "@aws-amplify/ui-react";
//import { Pagination, usePagination } from '@aws-amplify/ui-react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useHistory, useParams, useLocation } from "react-router-dom";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import '@fortawesome/fontawesome-free/css/all.min.css';
import Select from 'react-select';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { logPageViews } from '../../Services/LoginService.js';

//import Pagination from 'react-bootstrap/Pagination';
const DrugAllergyInteractions = () => {
    const history = useHistory();
    const [activeButton, setActiveButton] = useState('interactions');
    const search = useLocation().search;
    const params = new URLSearchParams(search);
    const [interId, SetinterId] = useState(params.get('id'))
    const [interName, SetinterName] = useState(params.get('name'))
    const [interType, SetinterType] = useState(params.get('type'))
    const [countPerPage] = useState(5);
    const [totalCount, setTotalCount] = useState(0);
    const [totalCountAllergy, settotalCountAllergy] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [AllergycurrentPage, setAllergyCurrentPage] = useState(1);
    const totalPages = totalCount < countPerPage ? 1 : Math.ceil(totalCount / countPerPage)

    //vp
    const vp_interId = params.get('vp_id')
    const vp_interName = params.get('vp_name')
    const [op2, setOp2] = useState([]);

    const [InteractionResults, setInteractionResults] = useState([])
    const [interactionDisclaimer, setInteractionDisclaimer] = useState("")
    const [noInteractionDisclaimer, setNoInteractionDisclaimer] = useState("")
    const [AllergyResults, setAllergy] = useState([])
    const [allergyDisclaimer, setAllergyDisclaimer] = useState("")
    const [noAllergyDisclaimer, setNoAllergyDisclaimer] = useState("")
    const [isCountInter, setisCountInter] = useState(false);
    const [isCountAllergy, setisCountAllergy] = useState(false);

    const handleSearchNavigate = (event) => {
        setIsloading(false)
        if (event !== null) {
            setInputs(event.map(val => ({ id: val.id, type: val.type, label: val.label })))
        }
    };

    const handleSearchChangeAllergies = (event) => {
        setIsloading(false)
        if (event !== null) {
            setInputAllergy(event.map(val => ({ allergenId: val.id, allergenType: val.type, allergenName: val.label })))
            if(event[0]?.label?.toLowerCase().includes("sulfur")) setSulfurWarningModal(true)
        }
    }

    const [query, setQuery] = useState(interName);
    const [Allergyquery, setAllergyQuery] = useState('');
    const [inputs, setInputs] = useState([]);
    const [inputAllergy, setInputAllergy] = useState([]);

    async function CheckInteractionHandle() {
        document.getElementById('loader').style.display = 'flex';
        setInteractionResults([])
        setAllergy([])
        if (inputs.length > 0) {
            setInteractionResults(await getInteractionVP(inputs, 1))
            if (inputAllergy.length > 0) {
                setAllergy(await getAllergyInteractions(inputs, inputAllergy, 1))
            }
        }
        
        logPage('Interactions'
            , inputs ? inputs.map(e => e.label).join (", ") : ''
            , inputAllergy ? inputAllergy.map(e => e.allergenName).join (", ") : ''
        ); 

        document.getElementById('loader').style.display = 'none';
    }

    async function CheckPageInteractionHandleIndex(event, value) {
        console.log(inputs)
        document.getElementById('loaderPage').style.display = 'flex'
        setInteractionResults([])
        setCurrentPage(value)
        if (vp_interId !== null) {
            setInteractionResults(await getInteractionVP(inputs, value))
        } else {
            setInteractionResults(await getInteractionVP(inputs, value))
        }

        document.getElementById('loaderPage').style.display = 'none'
    }

    async function logPage(pageName, input1, input2) {
        await logPageViews(pageName
            , input1
            , input2
        )
    }


    useEffect(() => {
         document.title = "Drug & Allergy Interactions"
         logPage("Interactions", "", "")
    }, [])

    useEffect(() => {
        setCurrentPage(InteractionResults.page)
        setTotalCount(InteractionResults.count)
        if (InteractionResults.data?.noInteractionDisclaimer != "") {
            getInteractionsDisclaimer(InteractionResults.data?.noInteractionDisclaimer)
                .then(e => { setNoInteractionDisclaimer(e.disclaimer) })
        }
        else setNoInteractionDisclaimer("")

        getInteractionsDisclaimer(InteractionResults.data?.generalDisclaimer)
            .then(e => setInteractionDisclaimer(e.disclaimer))

        //console.log(InteractionResults.count !== 'undefined')
        settotalCountAllergy(AllergyResults.count)
        if (AllergyResults.data?.noAllergyDisclaimer != "") {
            getInteractionsDisclaimer(AllergyResults.data?.noAllergyDisclaimer)
                .then(e => setNoAllergyDisclaimer(e.disclaimer))
        }
        else setNoAllergyDisclaimer("")

        getInteractionsDisclaimer(AllergyResults.data?.generalDisclaimer)
            .then(e => setAllergyDisclaimer(e.disclaimer))

    }, [InteractionResults, AllergyResults])

    useEffect(() => {
        setCurrentPage(currentPage)
    }, [currentPage])

    const [InteractionShow, setInteractionShow] = useState(false);
    const [AllergymodalShow, setAllergyModalShow] = useState(false);
    const [sulfurWarningModal, setSulfurWarningModal] = useState(false)


    const [severityLevel, setseverityLevel] = useState('');
    const [severityLevelName, setseverityLevelName] = useState('');
    const [documentationLevel, setdocumentationLevel] = useState('');
    const [documentationLevelName, setdocumentationLevelName] = useState('');

    const [molecule, setmolecule] = useState('');
    const [interactingMolecule, setinteractingMolecule] = useState('');
    const [observation, setobservation] = useState('');

    const [className1, setClassName1] = useState('');
    const [className2, setClassName2] = useState('');
    const [classDescription1, setclassDescription1] = useState('');
    const [classDescription2, setclassDescription2] = useState('');
    const [precaution, setprecaution] = useState('');
    const [drugInteractionDetails, setdrugInteractionDetails] = useState('');
    const [references, setreferences] = useState([]);

    function tagNames(input) {
        return input
            .replace(molecule, '<span class="molecule">' + (molecule) + '</span>')
            .replace(interactingMolecule, '<span class="interactingMolecule">' + (interactingMolecule) + '</span>')
            .replace(className1 + ' class', '<span class="mimsclass">' + (className1) + '</span> class')
            .replace(className2 + ' class', '<span class="mimsclass">' + (className2) + '</span> class')
    }

    function InteractionModal(props) {
        return (

            <Modal
                {...props}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='modalZIndex interModalHeight'

            >
                <Modal.Header closeButton >
                    <Modal.Title id="contained-modal-title-vcenter">
                        <h4>Interactions</h4>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container">
                        <div className="mb-3 pt-2 row">
                            <div className="col-xl-8">
                                <h2>{molecule} <span className="text-danger">↔</span> {interactingMolecule}</h2>
                                <div className="observation mt-3" style={{ fontSize: '1.2em' }} >
                                    <i className="fa-solid fa-triangle-exclamation me-2" style={{ color: "red" }}></i>{parse(tagNames(observation))}
                                </div>
                            </div>
                            <div className="col-xl-4">
                                <table className="w-100">
                                    <tr>
                                        <td>Severity:</td>
                                        <td>
                                            <div className={`severity severity${severityLevel} mb-1`}>{severityLevelName}</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Documentation:</td>
                                        <td>
                                            <div className={`likelihood likelihood${documentationLevel}`}>{documentationLevelName}</div>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                        <div className="mb-2 pt-2 row">
                            <div className="col"><strong>Actions to be taken</strong></div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <ul className='d-grid'>

                                    {precaution.split('<BR>').map(function (item, idx) {
                                        return (
                                            <label key={idx}>
                                                {item}
                                            </label>
                                        )
                                    })}
                                    {/* {precaution.replaceAll('<BR>', '<br/>')} */}


                                </ul>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col"><strong>Drug interactions details</strong></div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <ul>
                                    <li>{drugInteractionDetails}</li>
                                </ul>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col"><strong>References</strong></div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <ul>
                                    {references.length > 0 && references.map((item, index) =>
                                    (
                                        <li key={index}> {item}</li>
                                    )
                                    )}
                                </ul>
                            </div>
                        </div>
                        <div className="row">
                            <div className="mb-2 pt-2 col"><strong>Description of Classes</strong></div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <ul>
                                    <li>{parse(tagNames(classDescription1))}</li>
                                    <li>{parse(tagNames(classDescription2))}</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={props.onHide}>Close</Button>
                </Modal.Footer>
            </Modal>
        );
    }

    const [summaryStatement, setsummaryStatement] = useState('');
    const [AllergyReference, setAllergyReference] = useState([]);
    function AllergyModal(props) {
        return (

            <Modal
                {...props}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='modalZIndex'

            >
                <Modal.Header closeButton >
                    <Modal.Title id="contained-modal-title-vcenter">
                        <h4>Medicine Allergy Details</h4>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container">
                        <div className="mb-3 pt-2 row">
                            <div className="col">
                                <h4>{molecule} <span className="text-danger">↔</span> {interactingMolecule}</h4>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="interaction" style={{ fontSize: '1.2em' }} ><i className="fa-solid fa-triangle-exclamation me-2" style={{ color: 'red' }}></i>{cleanUp(summaryStatement)}</div>
                            </div>
                        </div>
                        <div className="row mt-4">
                            {references.length > 0 && <div className="col"><strong>References</strong></div> }
                        </div>
                        <div className="row">
                            <div className="col">
                                <ul>
                                    {references.length > 0 && references.map((item, index) =>
                                    (
                                        <li key={index}> {item}</li>
                                    )
                                    )}
                                </ul>
                            </div>
                        </div>
                        <div className="row">
                            {AllergyReference.length > 0 && <div className="col"><strong>References</strong></div>}
                        </div>
                        <div className="row">
                            <div className="col">
                                <ul>

                                    {AllergyReference.length > 0 && AllergyReference.map((item, index) =>
                                    (
                                        <li key={index}> {item}</li>
                                    )
                                    )}
                                </ul>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={props.onHide}>Close</Button>
                </Modal.Footer>
            </Modal>
        );
    }
    const handleTxtValueInteraction = (severityLevel, severityLevelName, documentationLevel, documentationLevelName,
        molecule, interactingMolecule, observation, className1, classDescription1, className2, classDescription2, precaution, drugInteractionDetails, references
    ) => {


        setseverityLevel(severityLevel)
        setseverityLevelName(severityLevelName)
        setdocumentationLevel(documentationLevel)
        setdocumentationLevelName(documentationLevelName)

        setmolecule(molecule)
        setinteractingMolecule(interactingMolecule)
        setobservation(observation)

        setClassName1(className1)
        setclassDescription1(classDescription1)
        setClassName2(className2)
        setclassDescription2(classDescription2)
        setprecaution(precaution)
        setdrugInteractionDetails(drugInteractionDetails)
        setreferences(references)

        setInteractionShow(true)
        logPage("Interaction Details", molecule, interactingMolecule)
        //console.log('dad')

    };
    const handleTxtValueAllergy = (molecule, interactingMolecule, summaryStatement, references) => {

        setmolecule(molecule)
        setinteractingMolecule(interactingMolecule)
        setsummaryStatement(summaryStatement)
        setreferences(references)
        setAllergyModalShow(true)
        logPage("Allergy Details", molecule, interactingMolecule)


    };

    function cleanUp(input) {
        input = input.replace('contains molecule', 'contains')
        input = input.replace('containing:', 'containing ')
        return parse(input)
    }

    useEffect(() => {
        checInteract()
        async function checInteract() {
            if (interId !== null) {
                setInputs([{ id: interId, type: interType }])
                const interactInput = ([{ id: interId, type: interType }])
                setInteractionResults(await getInteractionVP(interactInput, 1))
            }
        }
    }, [interId]);

    useEffect(() => {
        checInteract()
        async function checInteract() {
            if (vp_interId !== null) {
                document.getElementById('loaderPage').style.display = 'flex'
                setQuery(vp_interName)
                setInputs([{ id: vp_interId, type: 'vp' }])
                const interactInput = ([{ id: vp_interId, type: 'vp' }])
                setInteractionResults(await getInteractionVP(interactInput, 1))
                document.getElementById('loaderPage').style.display = 'none'
            }
        }
        if (vp_interId !== null) {
            console.log(vp_interName)
            SetinterId(vp_interId)
            SetinterName(vp_interName)
            SetinterType('vp')
        }

    }, [vp_interId]);


    function OnClickClear() {
        setOp2([])
        setInputs([])
        setInputAllergy([])
        setQuery('')
        setInteractionResults([])
        setAllergy([])
        SetinterId(null)
        SetinterName(null)
        SetinterType(null)

        if (params.get("id") != null && params.get("id") != "" ) {
             history.push('/druginteractions')
             window.location.reload();
            } else {
            window.location.reload();
        }
        //
    }


    function generalDisclaimer(text) {
        return (
            <div className="notes mt-5">
                {text != '' && parse(`<p>
            <span className='warning'>Disclaimer: </span>
            ${text?.split("<br />")?.join("</p><p>")}
            </p>`
                )}
            </div>
        )
    }

    useEffect(() => {
        setOp2([])
        async function checkToken() {
            await getProductAutocomplete(query, '10', false)
                .then(data => {
                    setOp2(data?.map(items => ({
                        id: items.id,
                        type: items.type,
                        label: items.name,
                    })));
                })
        }
        if (query !== null) {
            if (query.length > 2) {
                setIsloading(true)
                checkToken()
            }
        }
    }, [query]);

    useEffect(() => {
        setOp2([])
        async function checkToken() {
            await getProductAutocomplete(Allergyquery, '10', true)
                .then(data => {
                    setOp2(data?.map(items => ({
                        id: items.id,
                        type: items.type,
                        label: items.name,
                    })));
                })
        }
        if (Allergyquery !== null) {
            if (Allergyquery.length > 2) {
                setIsloading(true)
                checkToken()
            }
        }
    }, [Allergyquery]);

    const [isLoding, setIsloading]= useState(false)
    return (<>

        <InteractionModal
            show={InteractionShow}
            onHide={() => setInteractionShow(false)}
        />
        <AllergyModal
            show={AllergymodalShow}
            onHide={() => setAllergyModalShow(false)}

        />
        <MetaTags>
            <meta name="viewport" content="width=device-width, initial-scale=.7" />
        </MetaTags>
        <Banner />
        <div className=" body-content">
            <div className="row">
                <div className="wit-100">
                    <div id="mySidenav" className="sidenav">
                        <Sidebar activeButton={activeButton} />
                    </div>
                </div>
                <div className="col leftmar overflow-auto">




                    <div className="boxBody mr-1">
                        <Banner />

                        <div className=" mp120">
                            <div className="row d-grid">
                                <div className="col searchT border-0">
                                    <h2>Drug & Allergy Interactions</h2>
                                </div>
                                <div className="col searchB2 mb-5">
                                    <div className="row row-cols-2">
                                        <div className="col-3">
                                            <div className="pmBox">
                                                <div className="">
                                                    <label className='mb-2' >Prescribed Medicines</label>
                                                    <Autocomplete
                                                        autoHighlight
                                                        multiple
                                                        //disablePortal
                                                        freeSolo //hides the dropdown arrow
                                                        id="combo-box-Prescribed"
                                                        options={op2}
                                                        filterOptions={(options)=> options}
                                                        //sx={{ width: 300 }}
                                                        //inputValue={query.toString()}
                                                        loading={isLoding}
                                                        loadingText={query?.length < 3 ? "Enter 3 or more characters for suggestions" : "No options found"}
                                                        //getOptionLabel={(option) => option.title}
                                                        defaultValue={interId != null ? [{
                                                            id: interId + '|' + interType,
                                                            "label": interName
                                                        }] : []}

                                                        //placeholder='Enter 3 or more characters for suggestions'
                                                        onChange={(event, newInputValue) => {
                                                            handleSearchNavigate(newInputValue)
                                                        }}
                                                        onInputChange={(event, newInputValue) => {
                                                            //setInputs(newInputValue);
                                                            //console.log(newInputValue)
                                                            setQuery(newInputValue)
                                                        }}
                                                        //onInputChange={handleInputChange}
                                                        //sx={{ width: 300 }}
                                                        renderInput={(params) =>
                                                             <TextField
                                                                 {...params}
                                                                 placeholder={inputs?.length >0 ? "Add more..." : "Enter 3 or more characters for suggestions"}
                                                             />}
                                                    />

                                                </div>
                                                <div className="">
                                                    <label>Known Allergies</label>
                                                    <Autocomplete
                                                        autoHighlight
                                                        multiple
                                                        //disablePortal
                                                        id="combo-box-Allergies"
                                                        freeSolo  //hides the dropdown arrow
                                                        options={op2}
                                                        filterOptions={(options)=> options}
                                                        //inputValue={Allergyquery ?? ''}
                                                        loading={ isLoding}
                                                        loadingText={Allergyquery.length < 3 ? "Enter 3 or more characters for suggestions" : "No options found"}
                                                        //sx={{ width: 300 }}
                                                        //placeholder='Enter 3 or more characters for suggestions'
                                                        onChange={(event, newInputValue) => {
                                                            handleSearchChangeAllergies(newInputValue)
                                                        }}
                                                        onInputChange={(event, newInputValue) => {
                                                            setAllergyQuery(newInputValue);

                                                        }}
                                                        //sx={{ width: 300 }}
                                                        renderInput={(params) => <TextField {...params}
                                                             placeholder={inputAllergy?.length >0 ?  "Add more..." : "Enter 3 or more characters for suggestions" }
                                                        />}
                                                    />

                                                </div>
                                                <div className="row" >
                                                    <div className="col-6">
                                                        <button type="button" className="btn btn-block" style={{borderColor:'lightgray'}} onClick={()=> {OnClickClear()}}>Clear All</button>
                                                    </div>
                                                    <div className="col-6">
                                                        <button type="button" className="btn btn-primary bstatus" style={{ fontSize: 16, lineHeight: '1em' }} onClick={CheckInteractionHandle} disabled={inputs.length==0}>Check Interaction</button>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="col-9 ps-0">
                                            <div className="pmBox">
                                                {InteractionResults.success === true ?

                                                    <Tabs
                                                        defaultActiveKey="Drug Interactions"
                                                        id="Interaction-Tabs"
                                                        className="mb3"
                                                    >
                                                        <Tab eventKey="Drug Interactions" title={parse('Drug Interactions ' + (InteractionResults.data.drugInteractions ? '<span class="badge bg-danger">' + totalCount?.toString() + '</span>' : ''))}>
                                                            <div className="ps-3 pe-3 pb-3">
                                                                <h4>Drug Interaction Results </h4>
                                                                {/* {InteractionResults.success === true? console.log(InteractionResults.data.length()) : 'sdfsd'} */}
                                                                {totalCount > 0 && <label>Showing results {(currentPage * countPerPage) + 1 - countPerPage} to {(totalCount < (countPerPage * currentPage)) ? totalCount : parseInt(currentPage * countPerPage)} of {totalCount} total results.</label>}
                                                                <table className="table results-table">

                                                                    {totalCount > 0 &&
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Medicine</th>
                                                                                <th>Route</th>
                                                                                <th>Interacting medicine</th>
                                                                                <th>Route</th>
                                                                                <th>Severity</th>
                                                                                <th>Documentation level</th>
                                                                                <th style={{ minWidth: 10 }}></th>
                                                                            </tr>
                                                                        </thead>
                                                                    }
                                                                    <tbody className='interactTb'>

                                                                        {InteractionResults.success === true && InteractionResults.data.drugInteractions.map((val, index) =>
                                                                        (
                                                                            <tr key={index} onClick={() => handleTxtValueInteraction(val.severityLevel, val.severityLevelName, val.documentationLevel, val.documentationLevelName, val.molecule, val.interactingMolecule, val.observation, val.className1, val.classDescription1, val.className2, val.classDescription2, val.precaution, val.drugInteractionDetails, val.references
                                                                            )}>
                                                                                <td className="molecule">{val.molecule}</td>
                                                                                <td>{val.route1}</td>
                                                                                <td className="molecule">{val.interactingMolecule}</td>
                                                                                <td>{val.route2}</td>
                                                                                <td>
                                                                                    <label className={`severity severity${val.severityLevel}`}>{val.severityLevelName}</label>
                                                                                </td>
                                                                                <td>
                                                                                    <label className={`likelihood likelihood${val.documentationLevel}`}>{val.documentationLevelName}</label>
                                                                                </td>
                                                                                <td><i className="fa-solid fa-circle-chevron-right" style={{ color: 'blue' }}></i></td>
                                                                            </tr>
                                                                        )

                                                                        )}

                                                                    </tbody>
                                                                </table>
                                                                <div className="row mt-1">
                                                                    <div className="d-flex flex-row align-items-center justify-content-center">
                                                                        <div className="drug-table-pagination">
                                                                            {totalPages > 1 && <> <Stack spacing={2}>
                                                                                <Pagination
                                                                                    count={totalPages}
                                                                                    defaultPage={currentPage}
                                                                                    onChange={CheckPageInteractionHandleIndex}

                                                                                    shape="rounded" />
                                                                            </Stack></>}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {noInteractionDisclaimer &&
                                                                    <div id="NoInteractionAlert">
                                                                        <p><i className="fa-solid fa-triangle-exclamation fa-5x fa-pull-left"></i>
                                                                            <h4>No drug-drug interaction records found</h4>
                                                                            {parse(noInteractionDisclaimer.toString())}</p>
                                                                    </div>
                                                                }
                                                                {InteractionResults.data.generalDisclaimer && generalDisclaimer(interactionDisclaimer)}
                                                            </div>
                                                        </Tab>
                                                        <Tab eventKey="Allergy Check" title={parse('Allergy Check ' + (AllergyResults.status === 200 && AllergyResults.data.drugAllergies ? '<span class="badge bg-danger">' + totalCountAllergy?.toString() + '</span>' : ''))} disabled={AllergyResults.status !== 200 || AllergyResults?.data?.drugAllergies.length === 0}>
                                                            <div className="ps-3 pe-3 pb-3">
                                                                <h4>Allergy Check Results</h4>
                                                                {AllergyResults.status === 200 && totalCountAllergy > 0 &&
                                                                    <label>Showing results {(AllergycurrentPage * countPerPage) + 1 - countPerPage} to {(totalCountAllergy < (countPerPage * AllergycurrentPage)) ? totalCountAllergy : parseInt(AllergycurrentPage * countPerPage)} of {totalCountAllergy} total results.</label>
                                                                }

                                                                <table className="table results-table">
                                                                    {totalCountAllergy > 0 &&
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Medicine</th>
                                                                                <th>Known Allergy</th>
                                                                                <th>Interaction</th>
                                                                                <th style={{ minWidth: 10 }}></th>
                                                                            </tr>
                                                                        </thead>
                                                                    }
                                                                    <tbody>
                                                                        {AllergyResults.status === 200 && AllergyResults.data.drugAllergies.map((val, index) => (
                                                                            <tr key={index} className="align-middle trAllergy" onClick={() => handleTxtValueAllergy(val.prescribedMedicine.name, val.allergen.name, val.summaryStatement, val.references)}>
                                                                                <td className="molecule">{val.prescribedMedicine.name}</td>
                                                                                <td>{val.allergen.name}</td>
                                                                                <td>{cleanUp(val.summaryStatement)}</td>
                                                                                <td><i className="fa-solid fa-circle-chevron-right" style={{ color: 'blue' }}></i></td>
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                </table>

                                                                {noAllergyDisclaimer &&
                                                                    <div id="NoInteractionAlert">
                                                                        <p><i className="fa-solid fa-triangle-exclamation fa-5x fa-pull-left"></i>
                                                                            <h4>No allergy warnings found</h4>
                                                                            {parse(noAllergyDisclaimer.toString())}</p>
                                                                    </div>
                                                                }
                                                                {AllergyResults.data?.generalDisclaimer && generalDisclaimer(allergyDisclaimer)}
                                                            </div>
                                                        </Tab>

                                                    </Tabs> :
                                                    <div className='centerFlex'>
                                                        <h5>Use the search filters to start your interactions check.</h5>
                                                    </div>
                                                }



                                            </div>


                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
                          
            <Modal id="SulfurWarningModal" size="lg" show={sulfurWarningModal} onHide= {() => setSulfurWarningModal(false)} centered>
                <Modal.Header closeButton>
                  <Modal.Title>
                        <i className="fa-solid fa-triangle-exclamation me-2" style={{ color: "red" }}></i><span className="text-muted">Important Note</span>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Container>
                    <p>Selecting the active ingredient <b>Sulfur</b> in this checker will trigger allergy and cross-sensitivity alerts specific to elemental sulfur and its compounds only. </p>
                    <p>Please select the actual <b>sulfonamide</b> drug (e.g., sulfamethoxazole, sulfadiazine, etc) or the <b>Sulfonamide Antibiotics</b> allergy class if you are intending to see potential allergies to a sulfonamide medicine or group of medicines.</p>
                    </Container>
                </Modal.Body>
              </Modal>
        </div>


    </>
    );
}

export default DrugAllergyInteractions;