import React, { useState, useEffect } from 'react';
import { SendEmail } from "../../Services/EmailService"
import { Button } from 'react-bootstrap';

const Renewal = () => {

    const [template, setTemplate] = useState('');
    useEffect(() => {
      fetch('/EmailReminder.html')
          .then(response => response.text())
          .then(data => setTemplate(data));
    }, []);

    const sendEmail = ( fullName, email, expireDate, numberOfDays) => {
      if (template) {
        //console.log(email)
          const processedTemplate = template
              .replace(/{fullName}/g, fullName)
              .replace('{email}', email)
              .replace(/{expire_date}/g, expireDate)
              .replace(/{no_of_days}/g, numberOfDays)
          SendEmail(
              email,
              processedTemplate,
              '',
              'Your MIMS Drugs4dent Trial is ending'
          );
          setTimeout(() => {
            //setOpen(true)
          }, 1000);
     
      }
    };

    return (
        <Button onClick={()=> sendEmail('Jonathan Au', 'jonathan.au@mims.com.au', '22 Aug 2024', '10')}>Send Email</Button>
    )
  

}


export default Renewal;