import React, { useState, useEffect, useRef } from 'react';
import MetaTags from 'react-meta-tags';
import { isLoggedIn } from '../../Services/Auth';
import { logPageViews } from '../../Services/LoginService.js';
import Banner from '../../components/banner.js';
import Sidebar from '../../components/sidebar.js';
import { Row, Col, Container } from 'react-bootstrap';
import LandingPageBanner from '../LandingPageBanner.js';
import '../../styles/account.scss'
import { Alert, Divider, Button } from '@mui/material';
import { IconShoppingCartFilled, IconUser, IconBuilding, IconBriefcase, IconMapPin, IconPhone, IconMail, IconLock, IconEye, IconEyeOff, IconLogin, IconChecks, IconArrowForward, IconArrowLeft, IconArrowRight } from '@tabler/icons-react';

const MyAccount = () => {
   const [loggedIn, setLoggedIn] = useState(false);

    useEffect(() => {
        document.title = "Manage Account"
        setLoggedIn(isLoggedIn());
        logPage(); 
            async function logPage() {
                await logPageViews('Manage Account',"")
            }
    }, []);
    const fullName = localStorage.getItem('fullName') || 'Guest'; // Get full name of user
    const email = localStorage.getItem('email') || 'Guest'; // Get email of user
    const address = localStorage.getItem('address') || 'N/A'; // Get address of user
    const phoneNumber = localStorage.getItem('phoneNumber') || 'N/A'; // Get phone number of user
    const company = localStorage.getItem('company') || 'N/A'; // Get company of user
    const occupation = localStorage.getItem('occupation') || 'N/A'; // Get occupation of user
    
    // User Fields
    const userFields = [
      { id: 'email', label: 'Email', value: email, icon: <IconMail style={{ height: '16px', marginRight: '4px' }} /> },
      { id: 'phoneNumber', label: 'Phone Number', value: phoneNumber, icon: <IconPhone style={{ height: '16px', marginRight: '4px' }} /> },
      { id: 'companyName', label: 'Company Name', value: company, icon: <IconBuilding style={{ height: '16px', marginRight: '4px' }} /> },
      { id: 'occupation', label: 'Occupation', value: occupation, icon: <IconBriefcase style={{ height: '16px', marginRight: '4px' }} /> },
      { id: 'address', label: 'Address', value: address, icon: <IconMapPin style={{ height: '16px', marginRight: '4px' }} /> },
    ];

    const subscriptionType = localStorage.getItem('subscriptionType'); // Get full name of user
    const subscriptionEnd = localStorage.getItem('subscriptionEnd'); // Get full name of user
    const licenseCount = localStorage.getItem('licenseCount');
    
    
    const subscriptionStart = localStorage.getItem('subscriptionStart');
    const subscriptionStartDate = new Date(subscriptionStart);
    const startDate = subscriptionStartDate.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });
    const subscriptionEndDate = new Date(subscriptionEnd);
    const endDate = subscriptionEndDate.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });

    // Get today's date
    const today = new Date();
    // Set the time components to zero to ensure accurate comparison
    today.setHours(0, 0, 0, 0);
    subscriptionEndDate.setHours(0, 0, 0, 0);

    // Calculate the difference in time (in milliseconds)
    const timeDifference = subscriptionEndDate - today;

    // Convert the time difference from milliseconds to days
    const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24)); // 1 day = 1000ms * 60s * 60m * 24h

    // Determine if the remaining days are 30 or less
    const displayRemainingDays = daysDifference <= 30;

    // Check IMGateway
    const gateway = localStorage.getItem('gateway');
    const checkgateway = gateway !== 'false' && gateway !== undefined && gateway !== null && gateway !== '';
    
return (<>
        <MetaTags>
            <meta name="viewport" content="width=device-width, initial-scale=1" />
        </MetaTags>
         {loggedIn ? (
            <Banner />
          ) : (
            <LandingPageBanner/>
          )}
          {loggedIn ? <Sidebar /> : null}
        <Container fluid id="main-MyAccount">
            <Row id="content">
              <Col>
                  <div id="contentCaption">
                    <h1>Manage Account</h1>
                    <Divider className='d-lg-none d-md-flex' sx={{ my: 2, borderBottomWidth: 1, borderColor: '#727272'}}/>
                  </div>
                  <Row id="contentContainer">
                 
                     <Col lg={6} xs={12} id="personalInformation">
                      <Row className="info">
                        <h2>{fullName}</h2>
                        {/* <div className="d-flex mt-2" id="mainInfo">
                          <div className="extraInfo d-flex align-items-center">
                            <IconMail />
                            <span>{email}</span>
                          </div>
                          
                          <Divider 
                            orientation="vertical" 
                            flexItem 
                            sx={{ mx: 2, my: "4px", borderRightWidth: 1, borderColor: '#202020' }} 
                          />
                        
                          <div className="extraInfo d-flex align-items-center">
                            <IconPhone />
                            <span>{phoneNumber}</span>
                          </div>
                        </div> */}
                      </Row>
                      <Row className="info">
                      {userFields.map((field) => (
                        <Col lg={field.id ==='address' ?  8:6}xs={12} className='details pe-lg-4 my-2'>
                              <div className="label d-flex align-items-center mb-2">
                                {field.icon} <span>{field.label}</span>
                              </div>
                              <h6 style={field.value === 'N/A' ? { fontStyle: 'italic', color: '#727272' } : {}}>
                                {field.value}
                              </h6>
                            </Col>
                          ))}
                      </Row>
                        </Col>
                        <Col lg={6} xs={12} id="subscription">
                          <h4>Your Subscription</h4>
                          <Divider sx={{ my: 2, borderBottomWidth: 1, borderColor: '#727272'}}/>
                         
                            <span>Subscription</span>
                            <h6>{subscriptionType}</h6>
                            <Row id="subscriptionDate" className="mb-4">
                              <Col xs={6}>
                                <span>Start</span>
                                <h6>{startDate}</h6>
                              </Col>
                              <Col xs={6}>
                                <span>End</span>
                                <h6>{endDate}</h6>
                              </Col>
                           </Row>
                           {displayRemainingDays && (
                            <Alert severity="info">
                              Your subscription will end in {daysDifference} days.
                            </Alert>
                          )}

                           <Row className='mt-4'>
                           <Col xs={6}>
                           <span>License Count</span>
                           <h6>{licenseCount}</h6>
                              </Col>
                              <Col xs={6}> 
                            <span>IMGateway</span>
                            <div id="imgateway" className="d-flex">
                            {checkgateway ? (
                              <>
                                <h6>Included</h6>
                                <IconChecks />
                              </>
                            ) : (
                              <h6 style={{ color: '#727272' }}>Not Included</h6>
                            )}
                          </div>

                              </Col>
                              </Row>
                              {subscriptionType === "Trial" && (
                                <>
                                  <Button
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    id="purchaseButton"
                                    endIcon={<IconShoppingCartFilled />}
                                    onClick={() => window.open('https://estore.mims.com.au', '_blank')}
                                  >
                                    Purchase or Renew Subscription
                                  </Button>
                                  <p style={{ marginTop: '20px', marginBottom: '8px', color: '#727272', fontSize: '12px', textAlign: 'center' }}>
                                    You will be redirected to MIMS eStore to purchase your subscription. You may need to register for an eStore account to login.
                                  </p>
                                </>
                              )}
                              
                        </Col>
                  </Row>
                </Col>
            </Row>
        </Container>
</>);
}
export default MyAccount;