import React, { useState, useEffect, useRef } from 'react';
import useFetch from '../../useFetch'
import { ValidateLogin, ForgotAcc } from '../../Services/LoginService'
import { Link } from "react-router-dom";
import { useHistory, BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { SendEmail } from "../../Services/EmailService"
import Homepage from '../Shared/Homepage'
import Footer from '../Shared/AllFooter'
import { Row, Col, Container } from 'react-bootstrap';
import MetaTags from 'react-meta-tags';
import Background from '../../components/background';
import '../../styles/Login.scss'
import { TextField, Button, Typography, FormControl, InputAdornment, IconButton, Checkbox, FormControlLabel, Box, Modal, CircularProgress } from '@mui/material';
import { IconMailFilled, IconLockFilled, IconEye, IconEyeOff, IconLogin, IconUserSearch, IconX } from '@tabler/icons-react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { isLoggedIn } from '../../Services/Auth';
import { history } from '../../Services/History';
import { logPageViews, GetIpAddress,UserSession } from '../../Services/LoginService';
const Login = () => {





  function adjustLayout() {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }

  window.addEventListener('resize', adjustLayout);
  window.addEventListener('orientationchange', adjustLayout);
  document.addEventListener('DOMContentLoaded', adjustLayout);
  const history = useHistory();

  useEffect(() => {
    logPage("Login")

    if (isLoggedIn()) {
      history.replace('/Home'); // Redirect if already logged in
      window.location.reload();
    }

    const handlePopState = () => {
      if (isLoggedIn()) {
        history.replace('/Home'); // Prevent going back to login page
        window.location.reload();
      }
    };


    window.addEventListener('popstate', handlePopState);


    return () => {
      window.removeEventListener('popstate', handlePopState);
    };

  }, []);

  async function logPage(pageName, input1, input2) {
    await logPageViews(pageName, input1, input2)
  }

  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);

  const [CheckIp, setCheckIp] = useState('');
  const [CheckIpIsFalse, setCheckIpIsFalse] = useState(false);


  useEffect(async () => {
    localStorage.setItem("documentRefer", document.referrer)
    const tokenResponse = await ValidateLogin(email, password, '', await GetIpAddress(), document.referrer);

    //console.log(document.referrer);
  

    if (tokenResponse.success && tokenResponse.data.isSuccess) {
      localStorage.setItem("ip", await GetIpAddress())
      localStorage.setItem('token', tokenResponse.data.token);
      localStorage.setItem('refreshToken', tokenResponse.data.refreshToken);
      localStorage.setItem('sessionId', tokenResponse.data.sessionId);
      localStorage.setItem('fullName', tokenResponse.data.fullName);
      localStorage.setItem('email', email);
      localStorage.setItem('phoneNumber', tokenResponse.data.phoneNumber);
      localStorage.setItem('address', tokenResponse.data.address);
      localStorage.setItem('company', tokenResponse.data.company);
      localStorage.setItem('occupation', tokenResponse.data.occupation);
      localStorage.setItem('userId', tokenResponse.data.userId);
      localStorage.setItem('gateway', tokenResponse.data.isImGateway);
    
        localStorage.setItem('subscriptionType', tokenResponse.data.eStoreWebModels[0].subType);
        localStorage.setItem('subscriptionStart', tokenResponse.data.eStoreWebModels[0].subcriptionStartDate);
        localStorage.setItem('subscriptionEnd', tokenResponse.data.eStoreWebModels[0].subcriptionEndDate);
        localStorage.setItem('licenseCount', tokenResponse.data.eStoreWebModels[0].licenseCount);
    
      await UserSession('Add',tokenResponse.data.sessionId,'',tokenResponse.data.userId,'','','','','','','')

      setError({ email: false, password: false });
      history.replace('/Home'); // Redirect to the home page
      window.location.reload();
    } else {
      console.log('suc')
    }
    // localStorage.setItem("ip",await GetIpAddress())
    // if(CheckIp)
    //   {

    //   }else{
    //     //logPage()
    //   }

    // async function logPage() {
    //   await 
    // }


    //console.log(tokenResponse);
  }, []);


  //Shrink label when there's an autofill value 
  const [passwordHasValue, setPasswordHasValue] = React.useState(false);
  const [emailHasValue, setEmailHasValue] = React.useState(false);
  const [forgotPasswordEmailHasValue, setForgotPasswordEmailHasValue] = React.useState(false);
  const makeAnimationStartHandler = (stateSetter) => (e) => {
    const autofilled = !!e.target?.matches("*:-webkit-autofill");
    if (e.animationName === "mui-auto-fill" || e.animationName === "mui-auto-fill-cancel") {
      stateSetter(autofilled || e.target.value !== '');
    }
  };

  const [password, setPassword] = useState('');
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setPasswordHasValue(e.target.value !== '');
    setError((prevState) => ({ ...prevState, password: false }));
  };

  const [email, setEmail] = useState('');
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setEmailHasValue(e.target.value !== '');
    setError((prevState) => ({ ...prevState, email: false }));
  };

  const [error, setError] = useState({ email: false, password: false, forgotPasswordEmail: false });
  const [emailErrorMessage, setEmailErrorMessage] = useState('');
  const [passwordErrorMessage, setPasswordErrorMessage] = useState('');
  const [forgotPasswordEmailErrorMessage, setForgotPasswordEmailErrorMessage] = useState('');


  const handleClickShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };



  // User Verification
  const [token, setToken] = useState('')
  const VerifyUser = async (event) => {
    event.preventDefault();
    // Validate email and password fields before making API call
    if (email === '') {
      setError((prevState) => ({ ...prevState, email: true }));
      setEmailErrorMessage('Email is required');
      return; // Exit early if validation fails
    } else if (!email.includes('@')) {
      setError((prevState) => ({ ...prevState, email: true }));
      setEmailErrorMessage('Invalid email. Must include @');
      return; // Exit early if validation fails
    } else {
      setError((prevState) => ({ ...prevState, email: false }));
    }

    if (password === '') {
      setError((prevState) => ({ ...prevState, password: true }));
      setPasswordErrorMessage('Password is required');
      return; // Exit early if validation fails
    } else {
      setError((prevState) => ({ ...prevState, password: false }));
    }

    // Submit form logic if validation passes
    document.getElementById('loader').style.display = 'flex';

    try {
      logPage("Login", email)
      const tokenResponse = await ValidateLogin(email, password);
      

      if (tokenResponse.success && tokenResponse.data.isSuccess) {
        localStorage.setItem('token', tokenResponse.data.token);
        localStorage.setItem('refreshToken', tokenResponse.data.refreshToken);
        localStorage.setItem('sessionId', tokenResponse.data.sessionId);
        localStorage.setItem('fullName', tokenResponse.data.fullName);
        localStorage.setItem('email', email);
        localStorage.setItem('phoneNumber', tokenResponse.data.phoneNumber);
        localStorage.setItem('address', tokenResponse.data.address);
        localStorage.setItem('company', tokenResponse.data.company);
        localStorage.setItem('occupation', tokenResponse.data.occupation);
        localStorage.setItem('userId', tokenResponse.data.userId);
        localStorage.setItem('gateway', tokenResponse.data.isImGateway);
        localStorage.setItem('subscriptionType', tokenResponse.data.eStoreWebModels[0].subType);
        localStorage.setItem('subscriptionStart', tokenResponse.data.eStoreWebModels[0].subcriptionStartDate);
        localStorage.setItem('subscriptionEnd', tokenResponse.data.eStoreWebModels[0].subcriptionEndDate);
        localStorage.setItem('licenseCount', tokenResponse.data.eStoreWebModels[0].licenseCount);

        setError({ email: false, password: false });
        await UserSession('Add',tokenResponse.data.sessionId,'',tokenResponse.data.userId,'','','','','','','')
        history.replace('/Home'); // Redirect to the home page
        window.location.reload();

      } else {
        // Handle API response errors
        localStorage.removeItem('token');

        if (tokenResponse.data.reason === 'expired') {
          setError((prevState) => ({ ...prevState, email: true }));
          setEmailErrorMessage('Email is expired');
        } else {
          setError((prevState) => ({ ...prevState, email: true, password: true }));
          setEmailErrorMessage('Invalid Email or Password');
          setPasswordErrorMessage('Invalid Email or Password');
        }

        localStorage.clear();
      }
    } catch (error) {
      console.error('An error occurred:', error);
      setError({ email: true, password: true });
      setEmailErrorMessage('An unexpected error occurred');
      setPasswordErrorMessage('An unexpected error occurred');
    } finally {
      document.getElementById('loader').style.display = 'none'; // Hide loader
    }
  };



  // Forgot password
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState('');
  const handleForgotPasswordEmailChange = (e) => {
    setForgotPasswordEmail(e.target.value);
    setForgotPasswordEmailHasValue(e.target.value !== '');
    setError((prevState) => ({ ...prevState, forgotPasswordEmail: false }));
  };
  // Forgot password modal
  const [open, setOpen] = useState(false);
  const [isEmailSubmitted, setIsEmailSubmitted] = useState(false);

  // Open Modal
  const handleOpen = () => {
    setOpen(true);
  };

  // Close modal then proceed to login
  const handleClose = () => {
    setOpen(false);
    setIsEmailSubmitted(false);
    setForgotPasswordEmail('')
    setError((prevState) => ({ ...prevState, forgotPasswordEmail: false }));
  };

  const [template, setTemplate] = useState('');
  useEffect(() => {
    fetch('/ForgotPasswordEmail.html')
      .then(response => response.text())
      .then(data => setTemplate(data));
  }, []);
  const sendEmail = (forgotPasswordEmail, resetPasswordUrl) => {
    if (template) {
      //console.log(email)
      const processedTemplate = template
        .replace(/{resetPasswordUrl}/g, resetPasswordUrl)

      SendEmail(
        forgotPasswordEmail,
        processedTemplate,
        '',
        'Reset Your Password'
      );
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false); // Stop loading
        setIsEmailSubmitted(true); // Set to success state
      }, 1000);

    }
  };

  const [forgotPasswordToken, setforgotPasswordToken] = useState('')
  const [isLoading, setIsLoading] = useState(false); // State for loading
  async function ForgotPasswordVerifyUser(event) {
    event.preventDefault();
    if (forgotPasswordEmail === '') {
      setError((prevState) => ({ ...prevState, forgotPasswordEmail: true }));
      setForgotPasswordEmailErrorMessage('Email is required');
    }
    else {
      setforgotPasswordToken(await ForgotAcc(forgotPasswordEmail));
      logPage("Forgot Password", forgotPasswordEmail)
    }
  }
  useEffect(() => {
    if (forgotPasswordToken) {


      // Check email existence
      if (forgotPasswordToken.data.reason === "invalid") {
        setError((prevState) => ({ ...prevState, forgotPasswordEmail: true }));
        setForgotPasswordEmailErrorMessage('Invalid email');

      } else {
        setError((prevState) => ({ ...prevState, forgotPasswordEmail: false }));
      }

      // Sign up successful
      if (forgotPasswordToken.data.isSuccess) {
        //   const verificationUrl = `https://www.mimsdrugs4dent.com/`;
        //   sendEmail( fullName, verificationUrl, email, password);

        let resetPasswordUrl = "https://www.mimsdrugs4dent.com/ResetPassword/" + forgotPasswordToken.data.token;
        sendEmail(forgotPasswordEmail, resetPasswordUrl);
      }

    }
  }, [forgotPasswordToken]);


  return (
    <>
      <MetaTags>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </MetaTags>
      <Modal id="modal" open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" onClose={isEmailSubmitted || isLoading ? undefined : handleClose}>
        <Box id="forgotPassword">
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              top: 16,
              right: 16,
              color: 'grey.500',
            }}
          >

            <IconX sx={{ fontSize: 32 }} />

          </IconButton>
          {isLoading ? (
            <CircularProgress sx={{ color: '#0262AB', margin: 'auto', display: 'block' }} />
          ) : isEmailSubmitted ? (
            // Display this after the email search is successful
            <>
              <img
                src="/ForgotPasswordMail.svg"
                alt="Illustration"
                style={{ maxWidth: '240px', width: '100%', height: 'auto', marginBottom: '16px' }}
              />
              <Typography id="modal-modal-title" variant="h4" component="h2" gutterBottom sx={{ fontWeight: 'bold', color: '#0262AB' }}>
                Check your mail!
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2, mb: 4, color: '#727272', fontSize: { xs: '0.9rem', sm: '1rem', md: '1.2rem' } }}>
                An email with password reset instructions has been sent to <strong>{forgotPasswordEmail}</strong>
              </Typography>
              <Button
                onClick={handleClose}
                variant="contained"
                color="primary"
                sx={{
                  borderRadius: '32px',
                  padding: '16px 32px',
                  fontSize: '16px',
                  marginTop: '16px',
                  backgroundColor: '#0262AB',
                  '&:hover': { boxShadow: '0px 8px 24px 0px rgba(0, 153, 255, 0.15)' },
                }}
              >
                Got it
              </Button>
            </>
          ) : (
            // Original display
            <>
              <img
                src="/ForgotPassword.svg"
                alt="Illustration"
                style={{ maxWidth: '240px', width: '100%', height: 'auto', marginBottom: '16px' }}
              />
              <Typography id="modal-modal-title" variant="h4" component="h2" gutterBottom sx={{ fontWeight: 'bold', color: '#0262AB' }}>
                Forgot your password?
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2, mb: 4, color: '#727272', fontSize: { xs: '0.9rem', sm: '1rem', md: '1.2rem' } }}>
                No worries! Just enter your account email and we'll help you recover your access
              </Typography>
              <form onSubmit={ForgotPasswordVerifyUser}>
                <FormControl margin="normal" required fullWidth error={error.email}>
                  <TextField
                    id="forgotPasswordEmail"
                    type="email"
                    value={forgotPasswordEmail}
                    onChange={handleForgotPasswordEmailChange}
                    fullWidth
                    autoComplete='email'
                    error={error.forgotPasswordEmail}
                    helperText={
                      error.forgotPasswordEmail ? forgotPasswordEmailErrorMessage : ''
                    }
                    label="Email"
                    variant="outlined"
                    InputLabelProps={{ shrink: forgotPasswordEmailHasValue, sx: { fontWeight: 'bold' } }}
                    InputProps={{
                      onAnimationStart: makeAnimationStartHandler(setForgotPasswordEmailHasValue),
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconMailFilled sx={{ fontSize: 16, pl: '10px' }} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
                <Button
                  onClick={ForgotPasswordVerifyUser}
                  variant="contained"
                  color="primary"
                  sx={{
                    borderRadius: '32px',
                    padding: '16px 32px',
                    fontSize: '16px',
                    marginTop: '16px',
                    backgroundColor: '#0262AB',
                    '&:hover': { boxShadow: '0px 8px 24px 0px rgba(0, 153, 255, 0.15)' },
                  }}
                  endIcon={<IconUserSearch />}
                >
                  Search Account
                </Button>
              </form>
            </>
          )}
        </Box>
      </Modal>
      <Container fluid id="loginPage">
        <Row className="h-100">
          <Col xl={5} lg={6} className="d-flex d-md-flex flex-column align-items-center justify-content-lg-start" id="loginContainer" >


            <form onSubmit={VerifyUser} id="loginForm">
              <a href="/" className="align-self-center align-self-lg-start">
                <img src="/Drugs4dent.svg" alt="Logo" id="loginLogo" />
              </a>
              <h1 className="align-self-start">Sign In</h1>
              <FormControl margin="normal" required fullWidth error={error.email}>
                <TextField
                  id="email"
                  type="email"
                  value={email}
                  onChange={handleEmailChange}
                  fullWidth
                  autoComplete='email'
                  error={error.email}
                  helperText={
                    error.email ? emailErrorMessage : ''
                  }
                  label="Email"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: emailHasValue, sx: {
                      fontWeight: 'bold', // Change this value to the desired font weight
                    },
                  }}
                  InputProps={{
                    onAnimationStart: makeAnimationStartHandler(setEmailHasValue),
                    startAdornment: (
                      <InputAdornment position="start" >
                        <IconMailFilled sx={{ fontSize: 16, pl: '10px' }} />
                      </InputAdornment>
                    ),
                  }}

                />
              </FormControl>

              <FormControl margin="normal" required fullWidth error={error.password}>
                <TextField
                  id="password"
                  label="Password"
                  variant="outlined"
                  type={showPassword ? 'text' : 'password'}
                  value={password}
                  onChange={handlePasswordChange}
                  fullWidth
                  error={error.password}
                  helperText={
                    error.password ? passwordErrorMessage : ''
                  }
                  InputLabelProps={{
                    shrink: passwordHasValue, sx: {
                      fontWeight: 'bold', // Change this value to the desired font weight
                    },
                  }}
                  InputProps={{
                    onAnimationStart: makeAnimationStartHandler(setPasswordHasValue),
                    startAdornment: (
                      <InputAdornment position="start" >
                        <IconLockFilled sx={{ fontSize: 16, pl: '10px' }} />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {showPassword ? <IconEyeOff /> : <IconEye />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>

              <div className="d-flex justify-content-between align-items-center w-100">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={rememberMe}
                      onChange={(e) => setRememberMe(e.target.checked)}
                      color="primary"
                    />
                  }
                  label="Remember Me"
                />
                <a onClick={handleOpen} style={{ color: '#016aaa', cursor: 'pointer' }}> Forgot Password?</a>
              </div>
              <Button id="loginButton" onClick={VerifyUser} type="submit" fullWidth variant="contained" color="primary" endIcon={<IconLogin />}>
                Sign In
              </Button>
            </form>
            <div id="signupLink">
              Don't have an account? <a href="/SignUp" id="signupText">Sign up for a 30-day trial</a>
            </div>


          </Col>
          <Col xl={7} lg={6} className="d-flex flex-column align-items-center d-none d-lg-flex" id="carouselContainer">
            {/* Content for the second column */}
            <h1 style={{ textAlign: 'center' }} >Discover What MIMS Drugs4dent® Can Do for You</h1>
            <Carousel
              showArrows={true}
              autoPlay={true}
              interval={7000}
              infiniteLoop={true}
              showThumbs={false}
              showStatus={false}

              className="carousel"
            >
              <div className="carouselItem">
                <img src="/carousel/DentalSpecificDrugInformation.svg" alt="Illustration 1" className="illustration" />
                <div className="carousel-caption">
                  <h3>Dental-Specific Drug Information</h3>
                  <p style={{ textAlign: 'center' }}>Succinct, dental-relevant drug information is provided about your patient’s medications to enable you to perform dental treatment safely with respect to medication use.</p>
                </div>
              </div>
              <div className="carouselItem">
                <img src="/carousel/TowardsSaferPrescribing.svg" alt="Illustration 2" className="illustration" />
                <div className="carousel-caption">
                  <h3>Towards Safer Prescribing</h3>
                  <p style={{ textAlign: 'center' }}>Paediatric dosing, drug and allergy interactions, contraindications, pregnancy and lactation recommendations are all provided to assist with safer prescribing.</p>
                </div>
              </div>
              <div className="carouselItem">
                <img src="/carousel/PatientEducation.svg" alt="Illustration 3" className="illustration" />
                <div className="carousel-caption">
                  <h3>Patient Education</h3>
                  <p style={{ textAlign: 'center' }}>Patient-friendly information is provided about the appropriate use of antibiotics for specific dental scenarios.</p>
                </div>
              </div>
              <div className="carouselItem">
                <img src="/carousel/ClinicalDecisionSupport.svg" alt="Illustration 4" className="illustration" />
                <div className="carousel-caption">
                  <h3>Clinical Decision Support</h3>
                  <p style={{ textAlign: 'center' }}>Medication related effects that can impact dental treatment are presented to assist with clinical decision making and safe treatment procedures. Examples include drugs associated with increased bleeding risk, infection risk and osteonecrosis of the jaw.</p>
                </div>
              </div>
            </Carousel>
          </Col>
        </Row>

      </Container>
      <Background />
    </>
  );
}

export default Login;