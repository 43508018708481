import React, { useState, useEffect } from 'react';
import { SendEmail } from "../../Services/EmailService"
import { Signup } from '../../Services/LoginService'
import { createBrowserHistory } from 'history';
import { Row, Col, Container } from 'react-bootstrap';
import MetaTags from 'react-meta-tags';
import '../../styles/Signup.scss'
import { TextField, Modal, Button, Typography, FormControl, InputAdornment, IconButton, Stepper, Step, StepLabel, Box } from '@mui/material';
import { IconUser, IconBuilding, IconBriefcase, IconMapPin, IconPhone, IconMail, IconLock, IconEye, IconEyeOff, IconLogin, IconCheck, IconArrowForward, IconArrowLeft, IconArrowRight } from '@tabler/icons-react';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; 
import { isLoggedIn } from '../../Services/Auth';
import { history } from '../../Services/History';
import { useHistory, useParams } from "react-router-dom";
import { logPageViews } from '../../Services/LoginService';

const SignUp = () => {
  const history = useHistory();
   useEffect(() => {
    document.title = "Signup - MIMS Drugs4dent"; // Change "Your App Name" to your desired title
    logPage()
    async function logPage() {
      await logPageViews("Signup","")
    }
  }, []);

  useEffect(() => {
    if (isLoggedIn()) {
      history.replace('/Home'); // Redirect if already logged in
    }

    const handlePopState = () => {
      if (isLoggedIn()) {
        history.replace('/Home'); // Prevent going back to login page
      }
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);
  const [token, setToken] = useState('')

  // Form fields
  const formFields = [
      { id: 'fullName', label: 'Full Name', icon: <IconUser sx={{ fontSize: 16, pl: '10px' }} />, type: 'text' },
      { id: 'companyName', label: 'Company Name', icon: <IconBuilding sx={{ fontSize: 16, pl: '10px' }} />, type: 'text' },
      { id: 'occupation', label: 'Occupation', icon: <IconBriefcase sx={{ fontSize: 16, pl: '10px' }} />, type: 'text' },
      { id: 'address', label: 'Address', icon: <IconMapPin sx={{ fontSize: 16, pl: '10px' }} />, type: 'text' },
      { id: 'phoneNumber', label: 'Phone Number', icon: <IconPhone sx={{ fontSize: 16, pl: '10px' }} />, type: 'tel' },
      { id: 'email', label: 'Email', icon: <IconMail sx={{ fontSize: 16, pl: '10px' }} />, type: 'email' },
      { id: 'password', label: 'Password', icon: <IconLock sx={{ fontSize: 16, pl: '10px' }} />, type: 'password' },
      { id: 'confirmPassword', label: 'Confirm Password', icon: <IconLock sx={{ fontSize: 16, pl: '10px' }} />, type: 'password' },
  ];

  // Form fields states
  const initialFieldState = formFields.reduce((acc, field) => {
    acc[field.id] = '';
    return acc;
  }, {});
  const [formValues, setFormValues] = useState(initialFieldState);

  // Form fields error states
  const initialErrorstate = formFields.reduce((acc, field) => {
    acc[field.id] = false;
    return acc;
  }, {});
  const [error, setError] = useState(initialErrorstate);
  const [emailErrorMessage, setEmailErrorMessage] = useState('');
  const [passwordErrorMessage, setPasswordErrorMessage] = useState('');
  const [confirmPasswordErrorMessage, setConfirmPasswordErrorMessage] = useState('');

  // Form fields focused states
  const initialFocusState = formFields.reduce((acc, field) => {
    acc[field.id] = false;
    return acc;
  }, {});
  const [focused, setFocused] = useState(initialFocusState);

  // Form fields label srhink states
  const initialShrinkState = formFields.reduce((acc, field) => {
    acc[field.id] = false;
    return acc;
  }, {});
  const [shrinked, setShrinked] = useState(initialShrinkState);
  // Shrink label when there's an autofill value 
  const makeAnimationStartHandler = (fieldId) => (e) => {
  const autofilled = !!e.target?.matches("*:-webkit-autofill");
    if (e.animationName === "mui-auto-fill" || e.animationName === "mui-auto-fill-cancel") {
        setShrinked((prevShrinked) => ({ ...prevShrinked, [fieldId]: autofilled || e.target.value !== '', }));
     }
  };
  
  // Input Validation on change
  const [isConfirmPasswordEnabled, setIsConfirmPasswordEnabled] = useState(false);
  const [existingEmails, setExistingEmails] = useState([]);
  // Regular expression for email and password validation
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const passwordRegex = /^.{8,}$/;
  const handleInputChange = (fieldId) => (e) => {
    const {value } = e.target;
    setFormValues((prevValues) => ({ ...prevValues, [fieldId]: value, }));
    setShrinked((prevShrinked) => ({ ...prevShrinked, [fieldId]: value !== '', }));
    setError((prevError) => ({ ...prevError, [fieldId]: false }));
    setFocused((prevFocused) => ({ ...prevFocused, [fieldId]: value !== '', }));

    if (fieldId === 'email'){
        setFocused((prevFocused) => ({ ...prevFocused, email: false, }));
        if (value.includes('@')) {
            if (!emailRegex.test(value)) {
                setError((prevState) => ({ ...prevState, email: true }));
                setEmailErrorMessage('Invalid email');
            } else {
                if (existingEmails.includes(value)) {
                    setError((prevState) => ({ ...prevState, email: true }));
                    setEmailErrorMessage('Email already exists');
                    setFocused((prevFocused) => ({ ...prevFocused, email: false, }));
                } else {
                    setError((prevState) => ({ ...prevState, email: false }));
                    setEmailErrorMessage('');
                    setFocused((prevFocused) => ({ ...prevFocused,  email: true, }));
                }
            }
        } else {
            // Handle the case where '@' is not present (optional)
            setError((prevState) => ({ ...prevState, email: false }));
            setFocused((prevFocused) => ({  ...prevFocused, email: false,  }));
        }
    }
    
    if (fieldId === 'password'){
        setIsConfirmPasswordEnabled(false);
        setFocused((prevFocused) => ({ ...prevFocused, password: false }));
        if (value.length>=8){
            setIsConfirmPasswordEnabled(true);
            setFocused((prevFocused) => ({ ...prevFocused, password: true }));
            if (formValues.confirmPassword.length > 7){
                if (value !== formValues.confirmPassword){
                    setError((prevState) => ({ ...prevState, confirmPassword: true }));
                    setConfirmPasswordErrorMessage('Passwords do not match');
                    setFocused((prevFocused) => ({ ...prevFocused, confirmPassword: false }));
                } else{
                    setError((prevState) => ({ ...prevState, confirmPassword: false }));
                    setFocused((prevFocused) => ({ ...prevFocused, confirmPassword: true }));
                }
            }    
        }
        else if (value.length > 0 && value.length < 8){
            setIsConfirmPasswordEnabled(false);
            setFocused((prevFocused) => ({ ...prevFocused, confirmPassword: false }));
            setError((prevState) => ({ ...prevState, confirmPassword: false }));
        }
        else{
            setFormValues((prevValues) => ({ ...prevValues, confirmPassword: '', }));
            setFocused((prevFocused) => ({ ...prevFocused, confirmPassword: false }));
            setError((prevState) => ({ ...prevState, confirmPassword: false }));
            setConfirmPasswordErrorMessage('');
            setShrinked((prevShrinked) => ({ ...prevShrinked, confirmPassword: false, }));
        }
    }

    if (fieldId === 'confirmPassword'){
        setFocused((prevFocused) => ({ ...prevFocused, confirmPassword: false }));
        if (value.length>=8){
            if (value !== formValues.password){
                setError((prevState) => ({ ...prevState, confirmPassword: true }));
                setConfirmPasswordErrorMessage('Passwords do not match');
            }
            else{
                setFocused((prevFocused) => ({ ...prevFocused, confirmPassword: true }));
            }
        } 
    }   
  };

  // Sign up stepper
  const [activeStep, setActiveStep] = useState(0);
  const steps = ['Personal Information', 'Account Details'];

  // Validation logic for step 1
  const handleNext = (event) => {
    event.preventDefault();
    let isValid = true;

    formFields.slice(0, 5).forEach((field) => {
        const fieldValue = formValues[field.id]?.trim(); 
        if(field.id=="companyName" || field.id=="address" || field.id=="occupation") {
          //no validation needed
        }
        else {
          //validate   
          if (!fieldValue) {
              setError((prevError) => ({ ...prevError, [field.id]: true }));
              setFocused((prevFocused) => ({ ...prevFocused, [field.id]: false }));
              setFormValues(prevValues => ({ ...prevValues, [field.id]: '', }));
              setShrinked(prevShrinked => ({ ...prevShrinked, [field.id]: false, }));
              isValid = false;
          } else {
              setError((prevError) => ({ ...prevError, [field.id]: false }));
              setFocused((prevFocused) => ({ ...prevFocused, [field.id]: true }));
          }
        }
    });


    if (isValid) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
    
  };


  // Function to handle going back
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // Show password toggle
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  // Show confirm password toggle
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  // Email template
  const [template, setTemplate] = useState('');
  useEffect(() => {
    fetch('/EmailVerification.html')
        .then(response => response.text())
        .then(data => setTemplate(data));
  }, []);
  const sendEmail = ( fullName, verificationUrl, email, password) => {
    if (template) {
      //console.log(email)
        const processedTemplate = template
            .replace(/{fullName}/g, fullName)
            .replace(/{verificationUrl}/g, verificationUrl)
            .replace('{email}', email)
            .replace('{password}', password);

        SendEmail(
            email,
            processedTemplate,
            '',
            'Welcome to your MIMS Drugs4dent Free Trial'
        );
        setTimeout(() => {
          setOpen(true)
        }, 1000);
   
    }
  };

  // Sign up button disabler to prevent multiple clicks
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  // Submit validation
  async function verifySignup(event) {

    event.preventDefault();
    let isValid = true;

    // Validate each field based on formValues state
    formFields.slice(5).forEach(field => {
      const fieldValue = formValues[field.id]?.trim(); 
        if (field.id === 'email') {
            if (formValues[field.id] === '') {
                setError(prevState => ({ ...prevState, [field.id]: true }));
                setEmailErrorMessage('Email is required');
                isValid = false;
            } else if (!emailRegex.test(formValues[field.id])) {
                setError(prevState => ({ ...prevState, [field.id]: true }));
                setEmailErrorMessage('Invalid email');
                isValid = false;
            } else {
                if (existingEmails.includes(formValues[field.id])) {
                    setError(prevState => ({ ...prevState, [field.id]: true }));
                    setEmailErrorMessage('Email already exists');
                    setFocused(prevFocused => ({ ...prevFocused,  [field.id]: false,  }));
                    isValid = false;
                } else {
                    setError(prevState => ({ ...prevState, [field.id]: false }));
                    setEmailErrorMessage('');
                    setFocused(prevFocused => ({ ...prevFocused, [field.id]: true, }));
                }
            }
        } else if (field.id === 'password') {
            if (formValues[field.id] === '') {
                setError(prevState => ({ ...prevState, [field.id]: true }));
                setPasswordErrorMessage('Password is required');
                setFormValues(prevValues => ({ ...prevValues, confirmPassword: '', }));
                setShrinked(prevShrinked => ({ ...prevShrinked, confirmPassword: false, }));
                isValid = false;
            } else if (!passwordRegex.test(formValues[field.id])) {
                setError(prevState => ({ ...prevState, [field.id]: true }));
                setPasswordErrorMessage('Password should be at least 8 characters long');
                setFormValues(prevValues => ({ ...prevValues, confirmPassword: '', }));
                setShrinked(prevShrinked => ({ ...prevShrinked, confirmPassword: false,  }));
                isValid = false;
            } else {
                setError(prevState => ({ ...prevState, [field.id]: false }));
            }
        } else if (field.id === 'confirmPassword') {
            if (isConfirmPasswordEnabled) {
                if (formValues[field.id] === '') {
                    setError(prevState => ({ ...prevState, [field.id]: true }));
                    setConfirmPasswordErrorMessage('Password confirmation is required');
                    isValid = false;
                } else if (formValues[field.id] !== formValues['password']) {
                    setError(prevState => ({ ...prevState, [field.id]: true }));
                    setConfirmPasswordErrorMessage('Passwords do not match');
                    isValid = false;
                } else {
                    setError(prevState => ({ ...prevState, [field.id]: false }));
                }
            } else {
                setError(prevState => ({ ...prevState, [field.id]: false }));
            }
        }
    });

    // If all validations pass, proceed to signup verification to check email existence
    if (isValid) {
      setIsButtonDisabled(true);
        try {
            const { email, password, fullName, companyName, occupation, address, phoneNumber } = formValues;
            const token = await Signup(email, password, password, fullName, companyName, occupation, address, phoneNumber);
            setToken(token);
        } catch (error) {
            // Handle signup error
            console.error('Signup failed:', error);
            // Optionally, set error state or handle error UI
        }
        finally {
          setIsButtonDisabled(false); // Re-enable the button after the process is complete
        }
    }
  };

  // Send email after successful sign up
  useEffect(() => {
      if (token) {
          const { email, password, fullName } = formValues;

          // Check email existence
          if (token.data.reason === "invalid") {
              setError((prevState) => ({ ...prevState, email: true }));
              setEmailErrorMessage('Email already exists');
              setFocused((prevFocused) => ({ ...prevFocused, email: false, }));
              setExistingEmails((prevEmails) => [...prevEmails, email]);

          } else {
              setError((prevState) => ({ ...prevState, email: false }));     
          }

          // Sign up successful
          if (token.data.isSuccess) {
            const verificationUrl = `https://www.mimsdrugs4dent.com/VerifyEmailAccount/` + token.data.token;
            sendEmail( fullName, verificationUrl, email, obscure(password));
          }

      }
  }, [token]);

  function obscure(pword) {
    let str=''
    let len = pword.length
    for(let i=0; i < len; i++) {
      if(i!=0 && i!=len-2 && i!=len-1) {
        str = str + '*' 
      }
      else {
        str= str + pword.substring(i, i+1)  
      }
    }
    return str 
  }
  
  // Success modal
  const [open, setOpen] = useState(false);
  
  // Open Modal
  const handleOpen = () => {
    setOpen(true);
  };

  // Close modal then proceed to login
  const handleClose = () => {
    setOpen(false);
    history.push("/login");
  };

  return (
    <>
        <MetaTags>
            <meta name="viewport" content="width=device-width, initial-scale=1" />
        </MetaTags>
        <div>
          <Modal id="modal" open={open} disableBackdropClick aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" >
          <Box id="forgotPassword">
              
            <img 
                src="/SignupSuccess.svg" 
                alt="Illustration" 
                style={{  maxWidth: '160px',  // Initial max-width
                  width: '100%',     // Allows scaling with container
                  height: 'auto', marginBottom: '16px' }} 
              />
              <Typography id="modal-modal-title" variant="h4" component="h2" gutterBottom sx={{ fontWeight: 'bold', color: '#0262AB', marginBottom: 0 }}>
                Welcome to MIMS Drugs4dent!
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2, mb: 4, fontSize: { xs: '0.9rem', sm: '1rem', md: '1.2rem' }, color: '#727272' }}>
                Your account has been created. Please check your email that we sent to <strong>{formValues.email}</strong> to verify your account and start your 30-day trial.
              </Typography>
              
              <Button
                onClick={handleClose}
                variant="contained"
                color="primary"
                sx={{
                  borderRadius: '32px',
                  padding: '16px 32px',
                  fontSize: '16px',
                  marginTop: '16px',
                  backgroundColor: '#0262AB',
                  '&:hover': { boxShadow: '0px 8px 24px 0px rgba(0, 153, 255, 0.15)', },
                }}
                endIcon={<IconLogin />}
              >
                Go to Sign In
              </Button>
            </Box>
          </Modal>
        </div>
        <Container fluid id="signupPage">
            <Row className="h-100">
            <Col lg={5}  className="d-flex flex-column align-items-center d-none d-lg-flex" id="illustrationContainer">
                {/* Content for the second column */}
                <a href="/" className="align-self-start">
                  <img src="/Logo.svg" alt="Logo" id="signupLogo"className="align-self-start"/>
                </a>
                <div id="illustrationCaption" className="align-self-start">
                   <h3 style={{lineHeight: 1.5}} >Welcome! Let's get you started with your new account</h3>
                </div>
                <img src="/Signup.svg" alt="Illustration" className="illustration" />
                <Button
                onClick={handleClose}
                variant="outlined"
                color="primary"
                sx={{
                  display: 'flex',
                  justifySelf: 'flex-end',
                  borderRadius: '32px',
                  borderColor: '#ffffff', // Border color for outlined button
                  padding: '16px 32px',
                  fontSize: '12px',
                  marginTop: '16px',
                  color: '#ffffff', // Text color
                  '&:hover': {   boxShadow: '0px 8px 24px 0px rgba(0, 153, 255, 0.15)',
                    backgroundColor: '#ffffff', // Hover background color
                    color: 'primary.main', // Text color on hover
                    borderColor: 'primary.main', // Border color on hover },
                }
              }}
                endIcon={<IconLogin />}
              >
                Already have an account? Sign In
              </Button>
                                    
                </Col>
              <Col lg={7}  id="signupContainer" className="d-flex flex-column align-items-center justify-content-lg-start justify-content-md-between" >
                <Box sx={{ width: '100%'}} id="signupForm">
                        <a href="/" className="d-lg-none d-md-flex align-self-center">
                          <img src="/Drugs4dent.svg" alt="Logo" id="signupLogo"className="align-self-start"/>
                        </a>
                        <h1 style={{ marginBottom: '8px' }}>Sign Up</h1>
                        <p className="d-none d-lg-flex" style={{  marginBottom: '20px', color: '#727272' }}>
                        Create your account and get started with your 30-Day MIMS Drugs4dent® trial account
                        </p>
                        <Stepper activeStep={activeStep}>
                          {steps.map((label) => (
                            <Step key={label}>
                              <StepLabel>{label}</StepLabel>
                            </Step>
                          ))}
                        </Stepper>
                        <Box sx={{ mt: 2 }}>
                          {activeStep === 0 ? (
                            <>
                            <form onSubmit={handleNext}>
                              {formFields.slice(0, 5).map((field) => (
                                <FormControl margin="normal" required fullWidth error={error[field.id]} key={field.id}>
                                  <TextField
                                    id={field.id}
                                    name={field.id}
                                    type= {field.type}
                                    label={field.label}
                                    variant="outlined"
                                    value={formValues[field.id]}
                                    onChange={(e) => {
                                      if (field.id === 'phoneNumber') {
                                          const re = /^[0-9\b]+$/;
                                          if (e.target.value === '' || re.test(e.target.value)) {
                                              handleInputChange(field.id)(e);
                                          }
                                      } else {
                                          handleInputChange(field.id)(e);
                                      }
                                    }}
                                    fullWidth
                                    focused={focused[field.id]}
                                    error={error[field.id]}
                                    helperText={error[field.id] ? `${field.label} is required` : ''}
                                    InputProps={{
                                      onAnimationStart: makeAnimationStartHandler(field.id),
                                      startAdornment: <InputAdornment position="start">{field.icon}</InputAdornment>,
                                      endAdornment: focused[field.id] ? (
                                        <InputAdornment position="end">
                                          <IconCheck className='validIcon' />
                                        </InputAdornment>
                                      ) : null,
                                    }}
                                    InputLabelProps={{
                                      shrink: shrinked[field.id],
                                      sx: { fontWeight: 'bold' },
                                    }}
                                  />
                                </FormControl>
                              ))}
                              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Button className="proceedButton" variant="contained" type="submit" color="primary" onClick={handleNext} endIcon={<IconArrowRight />}>
                                  Next
                                </Button>
                              </Box>
                            </form>
                            </>
                          ) : (
                            <>
                              <Button
                                variant="text"
                                color="primary"
                                onClick={handleBack}
                                style={{ textTransform: 'none', color: '#727272', marginBottom: '12px' }}
                                startIcon={<IconArrowLeft />}
                              >
                                Go back and edit personal information
                              </Button>
                              <form onSubmit={verifySignup}>
                              {formFields.slice(5).map((field) => (
                                <FormControl margin="normal" required fullWidth error={error[field.id]} >
                                  <TextField
                                    id={field.id}
                                    name={field.id}
                                    label={field.label}
                                    type={
                                      field.type === 'password' && field.id === 'password' 
                                      ? (showPassword ? 'text' : 'password') 
                                      : field.type === 'password' && field.id === 'confirmPassword' 
                                      ? (showConfirmPassword ? 'text' : 'password') 
                                      : 'email'
                                  }
                                    variant="outlined"
                                    value={formValues[field.id]}
                                    onChange={handleInputChange(field.id)}
                                    fullWidth
                                    focused={focused[field.id]}
                                    error={error[field.id]}
                                    helperText={
                                      field.id === 'email'
                                          ? error.email ? emailErrorMessage : (focused.email ? <span style={{ color: '#0262AB' }}>Valid email. To be verified upon submit</span> : '')
                                          : field.id === 'password'
                                              ? error.password ? passwordErrorMessage : (focused.password ? '' : 'Password should be at least 8 characters long')
                                              : field.id === 'confirmPassword'
                                              ? error.confirmPassword ? confirmPasswordErrorMessage : focused.confirmPassword?  <span style={{ color: '#0262AB' }}>Passwords match</span> : '' :''
                                  }
                                  InputProps={{
                                      onAnimationStart: makeAnimationStartHandler(field.id),
                                      startAdornment: <InputAdornment position="start">{field.icon}</InputAdornment>,
                                      endAdornment: field.type === 'password' ? (
                                        <>
                                          <InputAdornment position="end">
                                            <IconButton aria-label="toggle password visibility" onClick={field.id === 'password' ? handleClickShowPassword : handleClickShowConfirmPassword} edge="end">
                                              {field.id === 'password' ? (showPassword ? <IconEyeOff /> : <IconEye />) : (showConfirmPassword ? <IconEyeOff /> : <IconEye />)}
                                            </IconButton>
                                          </InputAdornment>
                                          {focused[field.id] ? (
                                            <InputAdornment position="end">
                                              <IconCheck className='validIcon' />
                                            </InputAdornment>
                                          ) : null}
                                        </>
                                      ) : focused[field.id] ? (
                                        <InputAdornment position="end">
                                          <IconCheck className='validIcon' />
                                        </InputAdornment>
                                      ) : null,
                                    }}
                                    InputLabelProps={{
                                      shrink: shrinked[field.id],
                                      sx: { fontWeight: 'bold' },
                                    }}
                                    disabled={field.id === 'confirmPassword' && !isConfirmPasswordEnabled}
                                    autoComplete="new-password"
                                    form={{
                                      autoComplete: 'off'
                                    }}
                                  />
                                </FormControl>
                              ))}
                              <p style={{ marginTop: '20px', marginBottom: '8px', color: '#727272', fontSize: '12px', textAlign: 'center' }}>
                                By clicking sign up you understand and agree to MIMS Drugs4dent® <a href="/terms" style={{ color: '#0262AB', fontWeight: 'bold' }}>Terms & Conditions</a> and <a href="/privacy" style={{ color: '#0262AB', fontWeight: 'bold' }}>Privacy Policy</a>.
                              </p>
                              <Button disabled={isButtonDisabled} variant="contained" type="submit" onClick={verifySignup} className='proceedButton' fullWidth endIcon={<IconArrowForward />}>
                                Sign Up For a 30-Day Trial
                              </Button>
                              </form>
                            </>
                          )}
                        </Box>
                        
                    </Box>
                    <div id="loginLink"className="d-lg-none d-md-flex align-self-center-md">
                            Already have an account? <a href="/login" id="loginText">Sign In</a>
                        </div>
                </Col>
                
            </Row>
        </Container>
    </>
  );
}

export default SignUp;