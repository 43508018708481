export async function getMimsClasses() {
    //returns all  classes including Main and Sub Class hierarchy
    //console.log(`Bearer ${localStorage.getItem('token').replaceAll('"', "")}`)
    try
    {
        return await fetch(`${process.env.REACT_APP_API_URL}/mimsClass/mainClass`,
        {
            headers: {
                'Authorization':`Bearer ${localStorage.getItem('token').replaceAll('"', "")}`
            }
        })
        .then(data => data.json())
    }
    catch(error)
    {
        return [];
    }
}