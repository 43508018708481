import Banner from '../../components/banner.js';
import Sidebar from '../../components/sidebar.js';
import LandingPageBanner from '../LandingPageBanner.js';
import React, { useState, useEffect, useRef } from 'react';
import { isLoggedIn } from '../../Services/Auth';
import { logPageViews } from '../../Services/LoginService.js';
import { Container, Row, Col, Placeholder } from 'react-bootstrap';
import MetaTags from 'react-meta-tags';

const Terms = () => {
    const [loggedIn, setLoggedIn] = useState(false);

    useEffect(() => {
        document.title = "Terms & Conditions"
        setLoggedIn(isLoggedIn());
        logPage(); 
        async function logPage() {
            await logPageViews('Terms',"")
        }
    }, []);

    function getData() {
        try { document.getElementById("loader").style.display = "none"; } catch { }
        return (
            <>
                <h3>Acceptance of these terms</h3>
                <p>By using this Website you agree to these terms and conditions and to the disclaimer and limitation of liability below. If you do not agree to these terms please leave this Website immediately.</p>
                <p><b>WEBSITE INFORMATION IS INTENDED FOR AUSTRALIAN QUALIFIED HEALTHCARE PROFESSIONALS AND AUSTRALIAN QUALIFIED VETERINARY PROFESSIONALS (FOR CERTAIN PRODUCTS), AND FOR EDUCATIONAL AND RESEARCH PURPOSES ONLY</b></p>
                <p><i>Information and Products on this Website is intended solely for Australian Qualified Health Care Professionals and for educational and research purposes only, and the information contained on this Website and sub-sites is Australia specific.</i></p>
                <p><i>If you are an Australian Qualified Veterinary Professional accessing our IVS Products and Websites, all references to “health care professional” under this terms and conditions shall be deemed to refer to “veterinary professional”.</i></p>
                <p>This Website, together with and including all Products and their Content thereon, wherever from or however derived, including all Content on the MIMS eStore, is not a substitute for independent professional advice from Your qualified health care professional. All information on the Website is intended solely for Australian qualified health care professionals and for educational and research purposes only.</p>
                <p>By accessing, viewing or using the Website, You:</p>
                <p>a. Warrant that You are an Australian qualified health care professional or You are using the Content for educational and research purposes only, and are accessing the Website from within Australia.</p>
                <p>b. Acknowledge that You will not rely on any of its Contents, but will treat the information as being only of a general informational nature; and</p>
                <p>c. Otherwise acknowledge, accept and agree to abide by these terms and conditions.</p>
                <p>
                    DISCLAIMER AND LIMITATION OF LIABILITY<br />
                    MIMS Australia Pty. Ltd., the operator of the Website, its affiliates, business partners and their respective employees, officers, agents and contractors (“MIMS Australia”) reviews all Content provided on the Website and in the Products by it, but makes no representation or warranty that all Content or information is complete, up to date or appropriate. Information and associated interactions contained in this Website and the Products are not intended to be used to diagnose, treat, cure or prevent any disease or for therapeutic purposes. No person should act in reliance on any statement contained in the information provided, and at all times should remember that clinical decisions are the responsibility of the attending health care professional and where any doubt exists regarding the appropriateness or not of any therapy, referral to a relevant specialist and/or the specific pharmaceutical manufacturer is recommended. MIMS Australia does not accept liability for any injury, loss or damage incurred by any use of, or reliance on the information provided through the Websites or the Products, or any of its Contents thereon (full disclaimer and limitation of liability clauses are set out under clauses 9 to 11, and 14 respectively).
                </p>
                <hr />
                <h3>Terms and Conditions</h3>
                <br />
                <h2>Definitions</h2>
                <p>
                    1. DICTIONARY<br />
                    a. AUD means the currency of Australia.<br />
                    b. Australian Qualified Health Care Professional means a professionally trained person who has the requirements set down by the relevant authorities for practising as a health care practitioner in Australia.<br />
                    c. Content means any information, data, software, music, sound, photographs, graphics, video messages, prices or other material.<br />
                    d. Editor means any representative of MIMS Australia who can update and maintain the Websites.<br />
                    e. GST means goods and services tax, consumption tax, value added tax, retail turnover tax or a tax of a similar nature.<br />
                    f. Primary Payment means any payment by You to MIMS Australia of any fees or any amounts payable by You to MIMS Australia under these terms and conditions.<br />
                    g. Products means anything, whether physical or digital copy, for sale or licensed on the Website.<br />
                    h. Tax Invoice means an invoice in the format required by the law and which also shows the amount of GST payable by You in respect of the relevant Primary payment.<br />
                    j. We, Us, and Our means MIMS Australia Pty Ltd.<br />
                    j. Websites means the websites operated by MIMS Australia, including all website platforms that hosts our Products and their Content.<br />
                    k. You and Your means any user of the Websites, including any user of the Products and their Content thereon, wherever from or however derived, including Content on the MIMS eStore.
                </p>
                <h2>Products and Services</h2>
                <p>
                    2. ABOUT DIGITAL SUBSCRIPTIONS<br />
                    a. A digital subscription provides You with access to a digital Product.<br />
                    b. The login details associated with Your digital subscription including username and password must not be shared with any other person.<br />
                    c. If any misuse of Your login details is detected or suspected by MIMS Australia (including the concurrent use of Your login details and/or the use of Your login details on more than the maximum allowable concurrent sessions), Your subscription may be immediately terminated.<br />
                    d. For more information, contact MIMS Australia Customer Service Team on 1800 800 629 or email subscriptions@mims.com.au
                </p>
                <p>
                    3. ABOUT MOBILE APPS<br />
                    iMIMS and MIMS for Android Product. After ordering online, You will receive an email confirmation containing Your order details. We will normally confirm receipt of Your order within a few minutes of ordering. After payment has been confirmed You will have access to the iMIMS, or MIMS for Android, immediately.
                </p>
                <p>
                    4. ABOUT PHYSICAL PRODUCTS<br />
                    a. MIMS Australia is committed to providing exceptional customer service and quality products. We endeavour to make sure that all products listed on our Websites are currently in stock and pricing is true and correct.<br />
                    i. In the event that an ordered item is not available or We are unable to fulfil Your order We will notify You within 3 business days to arrange an agreeable alternative item, a back order or a full refund.<br />
                    b. Standard delivery times are:<br />
                    i. Within Australia. Please allow 7 to 10 working days for delivery. We deliver by post using standard rates.<br />
                    ii. Within New Zealand. Please allow 7 to 10 working days for delivery. We deliver by post using standard rates.<br />
                    iii. Other international destinations. Delivery takes up to 20 working days. We deliver via air mail<br />
                    c. In rural and regional areas of Australia, delivery times for express parcel services are in respect of delivery to the post office, the principal township in that area. Delivery to the final destination may take longer. If in doubt please check with Your local post office.<br />
                    d. MIMS Australia ships goods around Australia and New Zealand, however at this time We do NOT accept international orders for print or disc goods through our Websites unless this option is explicitly made available on certain Product Websites. Please contact Us on +61 2 9902 7700 to enquire about a special order.
                </p>
                <p>
                    5. PRODUCT AVAILABILITY<br />
                    a. We update the information about Products regularly. However, from time to time a Product may become unavailable between updates of availability information.<br />
                    b. We use Our best endeavours to deliver the Products You have ordered within the timeframe for delivery specified in Your order. Alternatively, You can cancel Your order within thirty (30) days of the order date, in which case if Your payment has been processed We will refund to You the purchase price. Please remember that the delivery times given in Your order are approximate and delays can sometimes occur. Also, all delivery times are based upon working days. You will become the owner of the Products You have ordered when the Products have been delivered to You.
                </p>
                <p>
                    6. PAYMENT TERMS AND GST<br />
                    a. All transactions are processed in AUD. Prices of our Products are available by calling Customer Service at 1800 800 629.<br />
                    b. If You require delivery of goods prior to payment You must supply a purchase order number. If Your order contains Product(s) for international shipment, You must complete full payment before Your order will be processed for delivery.<br />
                    c. All prices quoted on our website are inclusive of GST.<br />
                    d. We do not offer instalment payments for subscriptions. The term of Your subscription to all Products is twelve (12) months.<br />
                    e. You must pay by the due date stipulated on the invoice. Short payment will result in Your order being placed on hold until the payment of the subscription fee has been received.<br />
                    f. We do not issue credit notes.<br />
                    g. You must pay by electronic fund transfer (“EFT”) if you are making payment of above AUD10,000.<br />
                    h. If We are liable by law for any GST on any Primary Payment You must pay Us the amount of GST.<br />
                    i. You must pay to Us an amount in respect of any GST that You are required to pay under this Agreement:<br />
                    i. at the same time; and<br />
                    ii. in the same manner;<br />
                    iii. as You are required to pay for the Primary Payment to which the amount in respect of GST relates.<br />
                    j. We must issue to You a Tax Invoice in accordance with the requirements of the law related to GST.<br />
                    k. If We refund to You an amount under these terms and conditions We must also refund You an amount in respect of any GST that You paid in respect of that amount.
                </p>
                <p>
                    7. REFUND/RETURN POLICY<br />
                    a. Subject to clauses 15 to 18 of these terms and conditions and to the extent permitted by law, if You do not like the Product(s), You agree that:<br />
                    i. A refund is available for Products returned within thirty (30) days of the date the original purchaser ordered a Product. For subscription-based Products, a refund is available during the initial subscription of a Product and is not available during subsequent Next Subscription Periods.<br />
                    ii. Any refund will be made to the original purchaser of the Pr;oduct only.<br />
                    iii. Refunds are dependent upon the original purchaser returning the Product at their expense to MIMS Australia, Locked Bag 3000, St Leonards NSW 1590.<br />
                    iv. Refunds are dependent upon You providing Your full name, original order number and Your credit card or bank account details to process the refund.<br />
                    b. Products returned within thirty (30) days will only be given a full refund once the Products have been received by MIMS Australia.
                </p>
                <p>
                    8. CONTINUING, CANCELLING OR CHANGING A SUBSCRIPTION<br />
                    a. We will inform you that Your subscription(s) will automatically continue for a subsequent subscription term two (2) weeks before the Next Subscription Period Billing (definition under clause 8d. below). The subsequent term will be for successive terms equal to the duration of the preceding subscription period ("Next Subscription Period"). <br />
                    b. We may from time to time change the price of the Products for the Next Subscription Period and you will be notified at the same time as the notification in clause 8a. <br />
                    c. If You do not wish to continue your subscription over the Next Subscription Period, You could notify Us in writing any time before the Next Subscription Period Billing ("Termination Notice"). <br />
                    d. We will issue You a Tax Invoice on the 1st business day of the month preceding the month of the start of the Next Subscription Period (“Next Subscription Period Billing”). You must make full payment before the start of the Next Subscription Period. If You do not make full payment within two (2) weeks into the New Subscription Period, Your subscription(s) will be immediately terminated.<br />
                    e. If You wish to terminate one of Your multiple subscriptions You need to wait until Your current subscription ends and then re-subscribe to the remaining items.<br />
                    f. If You wish to add an item to Your current subscription You can do so at any time during the subscription period. <br />
                    g. If You wish to swap Products, for example discontinuing Your current subscription on a Product and take out a new subscription of another Product, We will transfer the value of Your old subscription to the new subscription.
                </p>
                <h2>Disclaimer</h2>
                <p>
                    9. CLINICAL INFORMATION CONTAINED IN THE PRODUCTS<br />
                    a. Clinical information contained in the Products and this Website is intended for education and instruction of qualified health care professionals, specifically Australian Qualified Health Care Professionals. Consumer seeking clinical information should not act in reliance on any statement contained in the information provided on the Website, and at all times should obtain specific advice from a qualified health care professional.
                </p>
                <p>
                    10. QUALITY OF INFORMATION<br />
                    a. MIMS Australia has made all reasonable efforts to ensure that all Content and Products provided on the Website are accurate and up to date. However, the information has been provided to MIMS Australia by third parties and MIMS Australia cannot guarantee complete accuracy of the Content on the Website or the Products and assumes no legal liability or responsibility for the accuracy, currency or completeness of any information found on the Website. <br />
                    b. Clinical decisions are the responsibility of the Australian Qualified Health Care Professional using the Website and where any doubt exists regarding the appropriateness or not of any Content or Product on the Website, referral to a relevant specialist is recommended.<br />
                    c. There will inevitably be clinical situations which will not be fully covered by the Content or the Product as provided by MIMS Australia. In these circumstances, it is strongly recommended that further information and advice be sought before a clinical decision is made. To the extent permitted by law, MIMS Australia excludes all liability for any injury, loss or damage caused by reliance on any of the information contained in, or provided through the Website. You acknowledge that the use of the Website is entirely at Your own risk.
                </p>
                <p>
                    11. INFORMATION COMPLIANCE WITH AUSTRALIAN REGULATIONS ONLY<br />
                    a. MIMS Australia recognises the global nature of the Internet and also recognises that the Content and the Products include information which is subject to country specific health legislation. MIMS Australia has used its best endeavours to ensure that the information contained on the Website and in the Products complies with specific Australian health regulations and codes of practice. However, the information has been provided to MIMS Australia by third parties and MIMS Australia takes no responsibility for the accuracy or legal compliance of the information contained in advertisements or on sub-sites owned or administered by third parties and hosted by MIMS Australia.<br />
                    b. MIMS Australia also recognises the availability of pharmaceutical products varies around the world. Products referred to on this Australian site may not be available or permitted in other countries.
                </p>
                <h2>
                    Security
                </h2>
                <p>
                    12. SECURITY AT THE WEBSITE<br />
                    a. The Internet is an insecure public network and transmissions may be viewed, intercepted or interfered with by third parties. We accept no responsibility or liability for interference with transmissions or any consequent loss or liability suffered by You.<br />
                    b. We have implemented security measures to provide You with peace of mind when You purchase goods from the Website. When You place an order Our secure service uses SSL (Secure Sockets Line) to connect You to the Website which encrypts all information.<br />
                    c. We automatically delete credit card details upon the completion of an order and therefore retain Your credit card details for the shortest possible period of time.<br />
                    d. If You are uncomfortable using Your credit cards online We also accept payment by Electronic Funds Transfer (EFT), cheque (from Australian banks only) and via the ‘phone’.
                </p>
                <p>
                    13. THIRD PARTY LIABILITY<br />
                    a. If You use the links to external websites available on a Website then You acknowledge that MIMS Australia has not reviewed and is not responsible for any information contained and accessed through any link provided on the Website.<br />
                    b. The links to these websites are made available by MIMS Australia for Your convenience only. The existence of these links does not constitute a guarantee or endorsement of those websites or the products or services offered by such websites, by MIMS Australia. User recommendations provided in the linked websites do not necessarily represent the views of MIMS Australia.<br />
                    c. MIMS Australia accepts no responsibility nor liability for promotions shown or advice given on these websites, whether or not these websites are hosts or otherwise affiliated with MIMS Australia. We recommend that You carefully read the terms and conditions for each website (including the applicable privacy policy, especially if You are asked to supply personal information to those sites) linked to a Website. Remember, the Website privacy policy statements and disclaimers here apply solely to information collected by the Website.
                </p>
                <h2>Limitation of Liability</h2>
                <p>
                    14. To the extent permitted by law, We exclude (and to the extent that such exclusion is prohibited limits to the maximum allowable extent) any and all loss, costs, damages or expenses of whatsoever nature (whether direct, indirect, incidental, consequential or otherwise) including, but not limited to, loss of profits, loss of savings or other incidental or consequential damages arising out of, relating to or connected in any manner whatsoever with Your use of the Websites or the Products, or any of its Contents thereon. In the event that such liability cannot be excluded We limit Our liability to, at Our option:<br />
                    a. in the case of goods (including Products):<br />
                    i. the replacement of the goods or supply of equivalent goods;<br />
                    ii. repair of the goods;<br />
                    iii. payment of the cost of replacing the goods or acquiring equivalent goods;<br />
                    iv. payment of the cost of having the goods repaired; or<br />
                    v. in the case of any services:<br />
                    vi. supplying the services again; or<br />
                    vii. payment of the cost of having the services supplied again.<br />
                    b. To the extent permitted by law, We are not responsible for any service or goods provided by a third party supplier.
                </p>
                <h2>Licensing</h2>
                <p>
                    15. MIMS Australia grants to You and You accept, a non-transferable and non-exclusive licence to use the Product and the Content thereon within Australia, subject to the terms of this Agreement and the following conditions:<br />
                    a. You must pay the applicable licence fee as stated in the invoice from MIMS Australia;<br />
                    b. You agree that you will:<br />
                    i. not rent, lease, sub-license, loan, provide, or otherwise make available, the Product or the Content in any form, in whole or in part to any person without prior written consent from the Editor;<br />
                    ii. not translate, merge, adapt, vary, alter or modify, the whole or any part of the Product and Content, nor permit the Product or Content or any part of them to be combined with, or become incorporated in, any other programs, except as necessary to use the Product on devices as permitted in these terms;<br />
                    iii. not disassemble, de-compile, reverse engineer or create derivative works based on the whole or any part of the App or the Services nor attempt to do any such things.
                </p>
                <p>
                    16. Each Product has a separate licence which You are required to read and accept before Your purchases will be validated by the Website. Please ensure that before ticking the “I accept” option, You have read and understood all the terms and conditions for the particular Products You are purchasing. If You do not wish to comply with the terms and conditions for each Product You select, do not purchase the Products.
                </p>
                <p>
                    17. You agree to indemnify, defend and hold MIMS Australia  harmless against any claims, liabilities, proceedings, costs, losses, expenses or damages incurred by MIMS Australia  in connection with Your use of the Website, Product(s) and Content thereon that is in breach of this terms and conditions or any other negligent or wrongful act or omission by You.
                </p>
                <h2>Copyright</h2>
                <p>
                    18. ALL RIGHTS RESERVED<br />
                    MIMS Australia reserves all rights in the Websites, Products and the Content thereon. In particular, no alterations, additions or adaptations of any Website publication (including any Products accessed through Websites) are permitted by any company outside MIMS Australia without written consent from the Editor.<br />
                    b. MIMS Australia makes every effort to contact copyright holders of photographs, illustrations, images and Content. If a work in copyright has been inadvertently included, MIMS Australia apologises to those concerned. Should the copyright holder prefer that the work not be made available on the Website, please contact MIMS Australia in writing and the Website will be amended.<br />
                    c. This Website may display photographs and images that are posed by models and in no way does MIMS Australia or its affiliates suggest that the people portrayed in those images are linked to any product or service advertised on the Website.<br />
                    d. You may, for Your own private non-commercial use, download and print an advertisement from the Website. This is the only permitted use You have for any Content on the Website and Product, subject to the fair dealing purposes set out in the Copyright Act 1968 (Cth).
                </p>
                <p>
                    19. WEBSITE LINKING POLICY<br />
                    a. Other websites are welcome to link pages in the Website, provided that permission is sought by those websites prior to any linking occurring. If permission is granted by the Editor for linking, the linking can occur, provided that no attempt is made to pass off the Content of the Website as the property of the linking website.<br />
                    b. We do not grant permission for anyone to copy the Content of the Website to their own web pages without the prior written consent of MIMS Australia.
                </p>
                <h2>Termination</h2>
                <p>
                    20. MIMS Australia reserves the right to immediately terminate Your subscription to the Product(s) if:<br />
                    a. You fail to comply with Your Licensing and Copyright obligations (clauses 15 to 19) under these terms and conditions; or<br />
                    b. You are in breach of any of Your obligations to MIMS Australia, and that breach has not been remedied to the satisfaction of MIMS Australia within the requisite time period.<br />
                    21. The expiry or termination of this Agreement will not affect or limit any accrued rights of the parties.
                </p>
                <h2>Others</h2>
                <p>
                    22. GENERAL TERMS<br />
                    a. You agree that these terms and conditions and Your access to the Websites are governed by the laws of New South Wales and You agree to submit to the exclusive jurisdiction of the Courts located within the State of New South Wales for any disputes.<br />
                    b. You acknowledge that these terms and conditions constitute the entire agreement between You and MIMS Australia and govern Your use of the Website superseding any prior agreement between You and MIMS Australia.<br />
                    c. You may also be subject to additional terms and conditions that may apply when You use or purchase other services from MIMS Australia.<br />
                    d. The failure of MIMS Australia to exercise or enforce any right or provision of the terms and conditions shall not constitute a waiver or any such right or provision. If any provision of the terms of service is found by a Court of competent jurisdiction to be invalid, the parties nevertheless agree that the Court shall Endeavour to give effect to the parties’ intentions as reflected in the provision and the other provisions of the terms and conditions shall remain in full force and effect.
                </p>
                <p>
                    23. CHANGES TO TERMS AND CONDITIONS<br />
                    a. MIMS Australia reserves the right to change these terms and conditions from time to time to reflect any changes in its business.<br />
                    b. MIMS Australia may also be required to change these terms and conditions for security reasons, for technical infrastructure reasons, or if required by law.<br />
                    c. Any changes to these terms and conditions will be posted on the Website and a notice will be posted on Our home page. We recommend that You read these terms and conditions carefully each time You agree to them during the ordering process.<br />
                    d. We will not change any terms and conditions for an existing order that has been accepted by Us. The terms and conditions that will apply to the order are the terms and conditions that applied at the time You placed the order.
                </p>
                <br />
            </>

        )
    }

    return (<>
         <MetaTags>
            <meta name="viewport" content="width=device-width, initial-scale=1" />
        </MetaTags>
         {loggedIn ? (
            <Banner />
          ) : (
            <LandingPageBanner/>
          )}
          {loggedIn ? <Sidebar /> : null}
        <Container fluid id="main">
            <Row id="content">
              <Col>
                  <div id="contentCaption">
                    <h1>Website and Product Terms & Conditions</h1>
                    <hr className='d-lg-none d-md-flex'/>
                  </div>
                  <Row id="contentContainer">
                     <Col>
                     {getData()}
                     </Col>
                  </Row>
                </Col>
            </Row>
        </Container>
    </>);
}

export default Terms;