import React, { useEffect, useState, useMemo } from 'react';
import { getProductList } from '../Services/ProductSearchService'
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import { IconButton, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useHistory } from 'react-router-dom';
import '../styles/sidebar.scss'
import { IconX, IconArrowRight } from '@tabler/icons-react';
import parse from 'html-react-parser';
import { getSearchHints } from '../Services/ProductSearchService'
import { debounce } from '@mui/material/utils'
import '../styles/searchbox.scss'
  
const SearchBox = (props) => {
    const [suggests, setSuggest] = useState([]);
    const [hints, setHints] = useState([]);
    const [inputValue, setInputValue] = useState('');

    const history = useHistory();
 

    useEffect(() => {
        setSuggest([])
        async function checkToken() {
          await getProductList(inputValue, false)
            .then(data => {
              setSuggest(data?.map(items => ({
                  id: items.id,
                  type: items.type == 'molecule' ? 'Active Ingredients' : "Products" ,
                  label: parse(items.productName),
                })))
              }
            )
          
        }
    
        if (inputValue?.length >= 3) {
          checkToken()
        }
      }, [inputValue]);
    
      
    
    useEffect (() => {
        if (suggests.length==0 && inputValue!=="") getHints()

        async function getHints() {
            await getSearchHints(inputValue).then (data => setHints(data?.map(items => ({
            id: items.id,
            type: 'Did you mean?',
            label: parse(items.productName),
            }))))
        }
    }, [suggests])

    
    const inputHandler = (event ) => {
        setInputValue(event?.target?.value);
    };

    const debouncedInputValue = useMemo(()=>
        debounce(inputHandler, 200)
    ,[]);
      
    
    const handleSearchNavigate = (types, id, label) => {
        if (types == 'prescriptions') {
            history.push('/dosingcalculations/' + id);
        }
        else if (types == 'Active Ingredients') {
            history.push('/SearchResults/' + label)
        }
        else if (types == 'Did you mean?') {
            history.push('/SearchResults/' + label+'?hints='+inputValue)
        }
        else if (id==undefined) {
            history.push('/SearchResults?hints='+inputValue)
        }
        else {
            history.push('/ProductDetails/' + id);
        }
    };

    const handleClearInput = () => {
        setInputValue('');
        setSearchHasValue(false);
      };

      const [searchHasValue, setSearchHasValue] = React.useState(false);
      const makeAnimationStartHandler = (stateSetter) => (e) => {
        const autofilled = !!e.target?.matches("*:-webkit-autofill");
        if (e.animationName === "mui-auto-fill" || e.animationName === "mui-auto-fill-cancel") {
        stateSetter(autofilled || e.target.value !== '');
        }
      };
      const handleSearchChange = (e) => {
        setSearchHasValue(e.target.value !== '');
    };
      
    const theme = useTheme();
  const isLgScreen = useMediaQuery(theme.breakpoints.up('lg'));
    return(
        <div id="searchContainer">
              <Autocomplete style={{marginTop:10}}
                autoFocus
                autoHighlight
                freeSolo
                disableClearable
                disablePortal
                useStyles
                filterOptions={(options)=> options}
                options={suggests.length >0 ? suggests : inputValue?.length > 0 ? hints : []} // Replace with your search suggestions array
                value={inputValue}
                groupBy={(option) => option.type}
                renderGroup={(params)=>(
                  <li key={params.key} style={{marginLeft: 15, marginRight: 15}}>
                    <div style={{marginTop:5, textTransform: 'uppercase' ,fontSize: 12, color: '#0262AB'}}>{params.group}</div>
                    <div className='list-items'>{params.children}</div>
                  </li>
                )}
                onChange={(event, newInputValue) => {
                  handleSearchNavigate(newInputValue.type, newInputValue.id, newInputValue.label)
                  if(props.handleCloseModal) props.handleCloseModal();
                  handleClearInput();
                  if(props.toggleDrawer) props.toggleDrawer(true);
                }}
                onInputChange={debouncedInputValue}
                sx={{
                  //width: { xs: 100, sm: 130, md: 150, lg: 170 },
                  // 👇 Select the hover item here
                  
                  '& + .MuiAutocomplete-popper .MuiAutocomplete-option:active': {
                    // 👇 Customize the hover bg color here
                    backgroundColor: '#1cbef3',
                  },
                  '& + .MuiAutocomplete-popper .MuiAutocomplete-option:hover': {
                    // 👇 Customize the hover bg color here
                    backgroundColor: '#1cbef3',
                  },
                  '& + .MuiAutocomplete-popper .MuiAutocomplete-option.Mui-focused': {
                    backgroundColor: '#1cbef3',
                  }
                }}
                renderInput={(params) => (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <TextField
                      autoFocus
                      {...params}
                      label="Enter brand or ingredient name"
                      variant="outlined"
                      fullWidth
                      className="searchTextField"
                      onChange={handleSearchChange}
                      InputLabelProps={{ shrink: searchHasValue, sx: { fontWeight: 'bold' } }}
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <InputAdornment position="start" >
                            <SearchIcon sx={{ fontSize: 36, pl: '10px' }} />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <React.Fragment>
                            {inputValue && (<IconButton id="searchIcon" onClick={handleClearInput}> <IconX /> </IconButton>)}
                            <IconButton className="searchButton">
                            {isLgScreen ? (
                              'Search'
                            ) : (
                              <IconArrowRight size={20}/>
                            )}
                          </IconButton>
                          </React.Fragment>
                        ),
                        onAnimationStart: makeAnimationStartHandler(setSearchHasValue),
                      }}
                    />
                  </div>
                )
                }
              />
        </div>
    )
};

export default SearchBox;