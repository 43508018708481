import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import Banner from '../../components/banner.js';
import Sidebar from '../../components/sidebar.js';
import React, { useState, useEffect } from 'react';
import useFetch from '../../useFetch';
import Button from 'react-bootstrap/Button';
import { cleanUp } from '../../Services/Utilities.js';
import { logPageViews } from '../../Services/LoginService.js';

const DentalAntibioticGuide = () => {
    const [activeButton, setActiveButton] = useState('antibiotic');
    const { error, isPending, data } = useFetch('api/MimsDrug4dent/GetDentalAntibioticGuide')
    const [ scenario, setScenario ] = useState('')

    useEffect(() => {
        document.title = "Dental Antibiotic Use Guide"
    }, [])

    useEffect(() => {
        logPage(); 
        async function logPage() {
            await logPageViews('Antibiotic',scenario ?? "")
        }
    }, [scenario])

    if (isPending) {
        document.getElementById('loaderPage').style.display = 'flex'
    } else {
        document.getElementById('loaderPage').style.display = 'none'
    }
    return (
        <>
            <Banner />
            <div className=" body-content">
                <div className="row">
                    <div className="wit-100">
                        <div id="mySidenav" className="sidenav">
                            <Sidebar activeButton={activeButton} />
                        </div>
                    </div>
                    <div className="col leftmar overflow-auto">
                        <div className="boxBody">


                            <div className=" mp120">
                                <div className="row d-grid mb-5">
                                    <div className="col">
                                        <h2>Dental Antibiotic Use Guide</h2>
                                        <p>Comprehensive guide for appropriate dental antibiotic use and patient education</p>
                                    </div>
                                    {/* <Tab.Container id="left-tabs-example" defaultActiveKey="4"> */}
                                    <Tab.Container id="left-tabs-example"  >                                       
                                        <Row className='mtop20 mb-5' >

                                            <Col sm={4} className="scenariostyle">
                                                <h4>Scenario</h4>
                                                <Nav variant="pills" className="flex-column">
                                                    {data.map((val, index) => (
                                                        <Nav.Item key={val.id + "-" + index}>
                                                            <Nav.Link onClick={()=>setScenario(val.scenario)} key={val.id + "-" + index} eventKey={val.id}>{val.scenario}</Nav.Link>
                                                        </Nav.Item>
                                                    ))}
                                                </Nav>
                                            </Col>
                                            <Col sm={8} className='mtop20'>
                                            {scenario!="" &&
                                                <Tab.Content>
                                                    <h3 className='explanationTile'>Patient explanation:</h3>

                                                    {data.map((val, index) => (
                                                        <Tab.Pane key={val.id + "-" + index} eventKey={val.id} className='explanationBody'>
                                                            {
                                                                cleanUp(val.parientExplanation)
                                                                // val.parientExplanation.split('\\r\\n').map(function (item, idx) {
                                                                //     return (
                                                                //         <p key={idx}>
                                                                //             {item}
                                                                //             <br />
                                                                //         </p>
                                                                //     )
                                                                // })
                                                            }
                                                        </Tab.Pane>
                                                    ))}


                                                    <h3 className='explanationTile mtop30'>Dentist explanation:</h3>

                                                    {data.map((val, index) => (
                                                        <Tab.Pane key={val.id + "-" + index} className='explanationBody' eventKey={val.id}>{
                                                            val.dentistExplanation.split('\\r\\n').map(function (item, idx) {
                                                                return (
                                                                    <p key={idx}>
                                                                        {item}
                                                                        <br />
                                                                    </p>
                                                                )
                                                            })
                                                        }</Tab.Pane>
                                                    ))}
                                                </Tab.Content>
                                                }
                                                <div className="col d-flex  justify-content-end gap-1 mt-2">

                                                    {/* <Button variant="primary">
                                                        Print & Email
                                                    </Button> */}



                                                </div>
                                            </Col>
                                        </Row>
                                    </Tab.Container>


                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>



    );
}

export default DentalAntibioticGuide;