import logo from './logo.svg';
import './Content/bootstrap.css'
import './Content/Site.css'
import './Content/Fonts.css'
//import './Content/js/modernizr-2.8.3.js'
//import './Content/js/jquery-3.4.1.js'
//import './Content/js/bootstrap.js'
import { useState, useEffect } from 'react';
import Homepage from './Views/Shared/Homepage'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import Index from './Views/Home/Index';
import useFetchGetToken from '../src/Services/GetToken';
import Login from './Views/Login/Login';
import Signup from './Views/Login/SignUp';
import ResetPassword from './Views/Login/ResetPassword';
import Terms from './Views/Shared/Terms';
import Footer from './Views/Shared/AllFooter'
import { getDataVersion } from './Services/Utilities'
import { useHistory } from 'react-router-dom';
import { RefreshToken } from './/Services/LoginService';
import { jwt_decode } from "jwt-decode";
import { jwtDecode } from "jwt-decode";
import IMGateway from './Views/IMGATEWAY/IMGatewayHerbalInteractions';
import DrugInteractions from './Views/DrugAndAllergy/DrugAllergyInteractions';
import DosingCalculations from './Views/DosingCalculation/DosingCalculations';
import SearchResults from './Views/Search/SearchResults';
import PregnancyAndLactationGuide from './Views/PregnancyAndLactation/PregnancyLactationGuide';
import DentalAntibioticGuide from './Views/DentalGuide/DentalAntibioticGuide';
import ProductDetails from './Views/Search/ProductDetails';
import about from './Views/Shared/About';
import LandingPage from './Views/LandingPage';
import privacy from './Views/Shared/Privacy'
import FeedBack from './Views/Shared/FeedBack';
import VerifyEmail from './Views/Login/VerifyEmailAccount'
import EstoreVerification from './Views/Login/EstoreVerification'
import MyAccount from './Views/Account/MyAccount';
import { isLoggedIn } from '../src/Services/Auth';
import Renewal from './Views/Login/Renewal';
//import MetaTags from 'react-meta-tags';
//const { default: jwt_decode } = require("jwt-decode");
function App() {

  const history = useHistory();
  //const { error: GettokenError, isPending: GettokenIsPending, data: GettokenData } = useFetchGetToken('https://sqn72kk00l.execute-api.ap-southeast-2.amazonaws.com/prod/account/authToken')

  //localStorage.setItem('token', JSON.stringify(GettokenData.token));
  // useEffect(() => {
  //       localStorage.setItem('token', JSON.stringify(GettokenData.token));
  //       //setToken(`Bearer ${localStorage.getItem('token').replaceAll('"', "")}`)
  //       //console.log(`Bearer ${localStorage.getItem('token').replace('"',"")}`)
  //       //console.log(data);

  //   }, [GettokenData]);
  const [dataVer, setdataVer] = useState([]);
  const PrivateRoute = ({ component: Component, ...rest }) =>
  (

    <Route
    {...rest}
    render={props => {
      const token = localStorage.getItem('token');
      console.log('Token:', token); // Debug token

      return token ? (
        <Component {...props} />
      ) : (
        <Redirect to="/login" />
      );
    }}
  />
);




  if ("token" in localStorage) {
    if (localStorage.getItem("token") === 'undefined' || localStorage.getItem("token") === null) {
      localStorage.removeItem("token");
      window.location.reload();
    }

    const decoded = jwtDecode(localStorage.getItem("token"));
    const expiration = new Date(decoded.exp)
    const now = new Date();
    const fiveMinutes = 1000 * 60 * 5 / 1000;
    //console.log(fiveMinutes)
    //console.log((expiration.getTime() - Math.floor(now.getTime() / 1000)))
    if ((expiration.getTime() - Math.floor(now.getTime() / 1000)) < fiveMinutes) {
      
        (async () => {
          console.log('ex')
          try {
            // Refresh the token
            const refreshResult = await RefreshToken(
              localStorage.getItem('token'),
              localStorage.getItem('refreshToken'),
              localStorage.getItem('sessionId')
            );

            // Update local storage with the new token
            localStorage.setItem('token', refreshResult.token);
            localStorage.setItem('refreshToken', refreshResult.refreshToken);
            localStorage.setItem('sessionId', refreshResult.sessionId);

            //window.location.reload();
          } catch (error) {
            console.error('An error occurred:', error);
            localStorage.clear()
            window.location.reload();
          }
        })();

    }
  }


  return (
    <Router>
      {/* <meta name="viewport" content="width=device-width, initial-scale=.5" /> */}
      {/* <MetaTags><meta name="viewport" content="width=device-width, initial-scale=.5" /></MetaTags> */}
      <div className="App">
        {/* <Login /> */}



        {/* <Route exact path="/Index">
            <Homepage />
          </Route>
          <Route exact path="/Login">
            <Login />
          </Route> */}
        <Switch>
          <PrivateRoute path="/Home" component={Homepage} exact />
          <PrivateRoute path="/SearchMedicine" component={Homepage} exact />
          <PrivateRoute path="/MyAccount" component={MyAccount} exact />
          <PrivateRoute path="/druginteractions" component={DrugInteractions} exact />
          <PrivateRoute path="/dosingcalculations" component={DosingCalculations} exact />
          <PrivateRoute path="/pregnancyandlactationguide" component={PregnancyAndLactationGuide} exact />
          <PrivateRoute path="/dentalantibioticguide" component={DentalAntibioticGuide} exact />
          {localStorage.getItem('gateway') == 'false'? '': <PrivateRoute path="/IMGatewayHerbalInteractions" component={IMGateway} exact />} 
          <PrivateRoute path="/ProductDetails/:id" component={ProductDetails} exact />
          <PrivateRoute path="/dosingcalculations/:id" component={DosingCalculations} exact />
          <PrivateRoute path="/SearchResults/:name" component={SearchResults} exact />
          <Route path="/feedback" component={FeedBack} />
          <Route path="/privacy" component={privacy} />
          <Route path="/about" component={about} />
          <Route path="/terms" component={Terms} />
          <Route path="/Signup" component={Signup} />
          <Route path="/ResetPassword/:id" component={ResetPassword} />
          <Route path="/VerifyEmailAccount/:id" component={VerifyEmail} />
          <Route path="/EstoreVerification" component={EstoreVerification} />
          <Route path="/Renewal" component={Renewal} />
          <Route path="/login" component={Login} />
          <Route path="/" component={LandingPage} />
        </Switch>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
