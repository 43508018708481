import React, { useState, useEffect } from 'react';
import parse from 'html-react-parser';
import { getProductAutocompleteByMolecule, getProductAutocompleteByBrand } from '../../Services/ProductSearchService'
import { logPageViews } from '../../Services/LoginService.js';
import AsyncSelect from "react-select/async";
import { Button, OverlayTrigger, Popover } from 'react-bootstrap'
//import {TreeView, TreeItem, } from '@mui/lab'  
//import { TreeView,TreeItem } from '@mui/x-tree-view/SimpleTreeView';
//import { TreeItem } from '@mui/x-tree-view/TreeItem';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import Box from '@mui/material/Box';
import { useNavigate, useLocation } from "react-router-dom";
import { getMimsClasses } from '../../Services/MimsClassService'
import Typography from '@mui/material/Typography';
import { getPregnancy } from '../../Services/PregnancyService'
import Banner from '../../components/banner.js';
import Sidebar from '../../components/sidebar.js';
import Select from 'react-select'
import useFetch from '../../useFetch';

//import parse from 'html-react-parser'
const PregnancyLactationGuide = () => {
    const [activeButton, setActiveButton] = useState('pregnancy');
    //const params = new URLSearchParams(search);
    const [classes, setClasses] = useState([])
    const [mimsClass, setMimsClass] = useState('')
    const location = useLocation();
    const [currentPage, setCurrentPage] = useState(1);
    const [brands, setBrands] = useState([])
    const [molecules, setMolecules] = useState([])
    const [operator, setOperator] = useState('OR')
    const [ActiveIngre, setActiveIngre] = useState('');


    const { error, isPending, data } = useFetch('api/MimsDrug4dent/GetDosingCalculationDrugName')

    //const search = useLocation().search;
    function handleClassChange(e) {
        if (e.length > 2) {
            setCurrentPage(1)
            setMimsClass(e)
        }
    }

    function handleRemoveClass() {
        setMimsClass('')
    }
    //Active Ingredient
    const [ActiveIngredientInput, setActiveIngredientInput] = useState("");
    const [BrandInput, setBrandInput] = useState("");
    const [activeIngredientInputs, setactiveIngredientInputs] = useState([]);
    const ActiveIngredientInputsLoadOptions = async (event) => {
        //console.log(event)
        if (event.length > 1) {
            await getProductAutocompleteByMolecule(event).then(items => {
                setactiveIngredientInputs(items)
            })

            return activeIngredientInputs.map(item => ({
                value: item.id,
                label: item.name
            }));
        }
    };
    useEffect(() => {
        document.title = "Pregnancy & Lactation Guide"
        getMimsClasses().then(items => { setClasses(items) })

    }, [])

    useEffect(()=> {
        logPage(); 
        async function logPage() {
            await logPageViews('Pregnancy',ActiveIngre ?? "")
        }
    }, [ActiveIngre])

    const [currentPageGW, setCurrentPageGW] = useState(1);
    const [countPerPage] = useState(20);
    const [pregnancyResult, setPregnancyResult] = useState([]);
    const [pregnancyD4DResult, setPregnancyD4DResult] = useState([]);

    useEffect(() => {
        setPregnancyResult([]);

        async function fetchData() {
            setPregnancyResult(await getPregnancy(ActiveIngredientInput, BrandInput, mimsClass, operator, currentPageGW, countPerPage))
        }
        if (ActiveIngredientInput != "" || BrandInput != "" || mimsClass != "") {
            document.getElementById('loader').style.display = 'flex';
            fetchData()
        }
    }, [ActiveIngredientInput, BrandInput, mimsClass, operator, currentPageGW])

    useEffect(() => {
        if (pregnancyResult.success) {
            document.getElementById('loader').style.display = 'none'
        }
    }, [pregnancyResult])
    async function ActiveIngredienthandleChange(event) {
        if (event != null) {
            setActiveIngredientInput(event.map(val => (val.value)))
        }

    }
    async function ActiveIngredienthandleChangeD4D(event) {

        if (event != null) {
            // console.log(event.value)
            setActiveIngre(event.label)
            //setPregnancyD4DResult()
            async function checkToken() {
                fetch(process.env.REACT_APP_APIDrug4dents_URL + 'api/MimsDrug4dent/GetPregnancyAndLactation?drugName=' + event.value,
                    { headers: { 'Authorization': `Bearer ${localStorage.getItem('token').replaceAll('"', "")}` } }
                ).then((res) => {
                    return res.json();
                })
                    .then((data) => {
                        setPregnancyD4DResult(data);
                    }, [event.value]);
            }
            if (event.value != "") {
                checkToken()
            }

            // console.log(event.map(val => (val.value)))
        }

    }
    //Brand
    const [BrandInputs, setBrandInputs] = useState([]);
    const BrandInputsLoadOptions = async (event) => {

        if (event.length > 1) {
            await getProductAutocompleteByBrand(event).then(items => {
                setBrandInputs(items)
            })
            return BrandInputs.map(item => ({
                value: item.id,
                label: item.name
            }));
        }
    };
    const [BrandshowOption, setBrandShowOption] = useState(false);
    const [Brandquery, setBrandquery] = useState("");
    function BrandhandleInputChange(typedOption) {
        //console.log(typedOption)
        setBrandquery(typedOption)
        if (typedOption.length > 1) {
            //console.log('Shown')
            setBrandShowOption(true)
        }
        else {
            //console.log('not')
            setBrandShowOption(false)
        }
    }
    function BrandhandleChangePrescribed(event) {
        if (event != null) {
            //setInputs(event.map(val => ({ id: val.value.split('|')[0], type: val.value.split('|')[1] })))

        }

    }
    function BrandhandleChange(event) {
        if (event != null) {
            //setInputAllergy(event.map(val => ({ allergenId: val.value.split('|')[0], allergenType: val.value.split('|')[1] })))
            setBrandInput(event.map(val => (val.value)))
        }

    }
    const [lastSelectedItem, setLastSelectedItem] = React.useState(null);

    const handleItemSelectionToggle = (event, itemId, isSelected) => {
        //console.log(event)
        if (isSelected) {
            setLastSelectedItem(itemId);
        }
    };
    function OperatorType(event) {
        if (event != null) {
            //console.log(event.target.value);
            setOperator(event.target.value)
        };
    }
    const options = [
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' }
    ]
    return (

        <>
            <Banner />
            <div className=" body-content">
                <div className="row">
                    <div className="wit-100">
                        <div id="mySidenav" className="sidenav">
                            <Sidebar activeButton={activeButton} />
                        </div>
                    </div>
                    <div className="col leftmar overflow-auto">
                        <div className="boxBody mr-1">
                            <div className="ml-2 mr-2 mp120">
                                <div className="row d-grid">
                                    <div className="col searchT">
                                        <h2>Pregnancy & Lactation Guide</h2>
                                    </div>
                                    <div className="col searchB">
                                        <div>
                                            <div className="row">
                                                <div className="w-25">
                                                    <h5>Active Ingredient</h5>
                                                    <Select
                                            
                                                        isClearable
                                                        onChange={ActiveIngredienthandleChangeD4D}
                                                        options={data.length > 0 &&
                                                            data.map((val, index) => (
                                                                { value: val.id, label: val.name }
                                                            ))} />
                                                    {/* <AsyncSelect
                                                        isMulti
                                                        isClearable
                                                        loadOptions={ActiveIngredientInputsLoadOptions}
                                                        onChange={ActiveIngredienthandleChange}
                                                        onInputChange={ActiveIngredienthandleInputChange}
                                                    /> */}
                                                </div>
                                               
                                                
                                                {/* <div className="col">
                                                    <label>Brand</label>
                                                    <AsyncSelect
                                                        isMulti
                                                        isClearable
                                                        loadOptions={BrandInputsLoadOptions}
                                                        onChange={BrandhandleChange}
                                                        onInputChange={BrandhandleInputChange}
                                                    />
                                                </div>
                                                <div className="col">
                                                    <label>MIMS Therapeutic ClassName</label>
                                                    <OverlayTrigger
                                                        trigger="click"
                                                        placement='bottom'
                                                        rootClose={true}
                                                        overlay={
                                                            <Popover id="MedicinesInPregnancyPopover" >
                                                                <Popover.Body>
                                                                    <SimpleTreeView onItemSelectionToggle={handleItemSelectionToggle}>
                                                                        {classes.length > 0 && classes.map((mc) => (
                                                                            <TreeItem key={mc.classCode} itemId={mc.classCode} label={mc.classDescription}>
                                                                                {mc.subClasses.map((sc, index) => {

                                                                                    return (<TreeItem key={index} itemId={sc.id + '|' + sc.classDescription} label={sc.classDescription} onClick={() => { handleClassChange(sc.classDescription); document.body.click() }} />)
                                                                                })}
                                                                            </TreeItem>
                                                                        ))}
                                                                    </SimpleTreeView>
                                                                </Popover.Body>
                                                            </Popover>
                                                        }>
                                                        <div className="d-flex">
                                                            <input className="mimsClassInput" defaultValue={''} />

                                                            <div className="caret">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16">
                                                                    <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708" />
                                                                </svg>
                                                            </div>

                                                        </div>
                                                    </OverlayTrigger>
                                                    {mimsClass != '' &&
                                                        <>
                                                            {(molecules.length > 0 || brands.length > 0) && <small className='me-1'>{operator}</small>}
                                                            <span className="badge rounded-pill m-2" title={(mimsClass)}>
                                                                <span className="text">{(mimsClass)}</span>
                                                                <a onClick={() => { handleRemoveClass() }}>

                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-circle" viewBox="0 0 16 16">
                                                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                                                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                                                                    </svg>
                                                                </a>
                                                            </span>
                                                        </>
                                                    }
                                                </div>
                                                <div className="col d-grid">
                                                    <label>Operator</label>
                                                    <select name="Drugname" defaultValue={'OR'} id="Drugname" onChange={OperatorType}>
                                                        <option value="OR">OR</option>
                                                        <option value="AND">AND</option>
                                                    </select>
                                                </div> */}
                                                {/* <div className="col">
                                                    <label>Clear All</label>
                                                </div> */}

                                            </div>
                                            <div className="boxLactation martop mt-4">
                                                {/* <label>Showing results 1 to 3 of 3 total results.</label> */}
                                                <table className="table table-bordered martop">
                                                    {/* <thead>
                                                        <tr>
                                                            <th>ACTIVE INGREDIENT</th>
                                                            <th>AVAILABLE BRANDS</th>
                                                            <th>GUIDE IN PREGNANCY</th>
                                                            <th>GUIDE IN LACTATION</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>

                                                        {pregnancyResult.success == true &&
                                                            pregnancyResult.data.map((val, index) =>
                                                                <tr key={index}>
                                                                    <td>{val.acgName}</td>
                                                                    <td>
                                                                        <ul>
                                                                            {val.brands.map((bval, bIndex) =>
                                                                            (
                                                                                <li key={bIndex} > {bval.brandName}</li>
                                                                            )
                                                                            )}
                                                                        </ul>

                                                                    </td>
                                                                    <td>
                                                                        Safe to use
                                                                    </td>
                                                                    <td>
                                                                        Safe to use; may cause diarrhoea in the infant.
                                                                    </td>
                                                                </tr>
                                                            )}

                                                    </tbody> ;*/}
                                                    <thead>
                                                        <tr>
                                                            <th style={{minWidth:200}}>Active Ingredent</th>
                                                            <th style={{minWidth:300}}>Guide in Pregnancy</th>
                                                            <th style={{minWidth:300}}>Guide in Lactation</th>
                                                            <th style={{maxWidth:200}}>Reference</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {/* {console.log(pregnancyD4DResult)} */}
                                                        {pregnancyD4DResult.length > 0 &&

                                                            <tr>
                                                                <td>{ActiveIngre}</td>
                                                                <td>
                                                                    {pregnancyD4DResult[0].pregnancyInfo.split('\\r\\n').map((instruction, index) => {
                                                                        return <div key={index} className=''>{` ${instruction}`}</div>
                                                                    })}
                                                                </td>
                                                                <td>
                                                                    {pregnancyD4DResult[0].lactaionInfo.split('\\r\\n').map((instruction, index) => {
                                                                        return <div key={index} className='mb-2'>{` ${instruction}`}</div>
                                                                    })}
                                                                </td>
                                                                <td>
                                                                {pregnancyD4DResult[0].referenceInfo.split('\\r\\n').map((instruction, index) => {
                                                                        return <div key={index} className='mb-2'>{parse(instruction)}</div>
                                                                    })}
                                                                {/* {!ActiveIngre.startsWith('Tranexamic acid')
                                                                    ? parse(`Teoh, L., Moses, G., & McCullough, M. (2024). <i>Handbook of Dental Therapeutics.</i> Cambridge: Cambridge University Press.`)
                                                                    : 'Cyclokapron Product information. Available from www.tga.gov.au. Accessed 29/07/2024.'
                                                                    } */}

                                                                    {/* {!ActiveIngre.startsWith('Tranexamic acid')
                                                                    ? parse(`Teoh, L., Moses, G., & McCullough, M. (2024). <i>Handbook of Dental Therapeutics.</i> Cambridge: Cambridge University Press.`)
                                                                    : 'Cyclokapron Product information. Available from www.tga.gov.au. Accessed 29/07/2024.'
                                                                    } */}

                                                                    {/* {pregnancyD4DResult[0].calculationReference.split('\\r\\n').map((instruction, index) => {
                                                                        return <div key={index} className='mb-2'>{` ${instruction.replaceAll('\\"','')}`}</div>
                                                                    })} */}
                                                                </td>
                                                            </tr>
                                                        }

                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>

    );
}

export default PregnancyLactationGuide;