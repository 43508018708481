
export function getEntities()
{
    try
    {
        let tok = localStorage.getItem('token').replaceAll('"', "")
        //console.log(tok)
        return  fetch(`${process.env.REACT_APP_API_URL}/imgateway/entities?herbs=true&drugs=true&drugclass=true`,
        {
    
            method:"GET",
            headers: {
                 'Authorization': `Bearer ${tok}`
            }
        })
        .then(data => data.json())
    }
    catch(error)
    {
        return [];
    }
}
export async function gatewayInteraction(inputs,interactingEntities,fetchPage)
{
    try
    {
        //console.log('gateway fetch inputs:', inputs, interactingEntities)
        let response = await fetch(`${process.env.REACT_APP_API_URL}/imgateway/interaction?page=${(fetchPage)}`,
        {
            method:"POST",
            body:JSON.stringify({
                'inputs':inputs,
                'interactingEntities':interactingEntities
            }),
            headers: {
                'Content-Type': 'application/json',
                 'Authorization':`Bearer ${localStorage.getItem('token').replaceAll('"', "")}`
            }
        })
        //console.log(response.json());
        var count = parseInt(response.headers.get('total-count'));
        var page = parseInt(response.headers.get('page-index'));
        var status = response.status;
        let json = await response.json();
        return {status:status, count:count, page:page, data:json};
        
    }
    catch(error)
    {
        return {status:0, count:0, page:0, data:[]};
    }
}
export async function getInteraction(inputs,fetchPage) {
    try
    {
        //console.log('interaction fetch inputs:', inputs)
        let res = await fetch(`${process.env.REACT_APP_API_URL}/drugInteraction?page=${(fetchPage)}&limit=5`,
        {
            method:"POST",
            body:JSON.stringify({
               'inputs':inputs
            }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token').replaceAll('"', "")}`,
            }
        });

        let json = await res.json();
        var count = parseInt(res.headers.get('total-count') ?? 0);
        var page = parseInt(res.headers.get('page-index'));
        return {success:true, count:count, page:page, data:json};
        
    }
    catch(error)
    {
       return {success:false, count:0, page:0, data:[]};
    }
}
export async function getInteractionVP(inputs,fetchPage) {
    try
    {
        // console.log(process.env.REACT_APP_API_URL)
        let res = await fetch(`${process.env.REACT_APP_API_URL}/d4d/drugInteraction?page=${(fetchPage)}&limit=5`,
        {
            method:"POST",
            body:JSON.stringify({
               'inputs':inputs
            }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token').replaceAll('"', "")}`,
            }
        });

        let json = await res.json();
        var count = parseInt(res.headers.get('total-count') ?? 0);
        var page = parseInt(res.headers.get('page-index'));
        return {success:true, count:count, page:page, data:json};
        
    }
    catch(error)
    {
       return {success:false, count:0, page:0, data:[]};
    }
}

export async function getAllergyInteractions(inputs,allergens,fetchPage)
{
    try
    {
//        console.log('allergy fetch inputs:', inputs, allergens)
        let response= await  fetch(`${process.env.REACT_APP_API_URL}/drugAllergy?page=${(fetchPage)}&limit=30`,
        {
            method:"POST",
            body:JSON.stringify({
                'inputs':inputs,
                'allergens':allergens
            }),
            headers: {
                'Content-Type': 'application/json',
                 'Authorization':`Bearer ${localStorage.getItem('token').replaceAll('"', "")}`,
            }
        })

        var count = parseInt(response.headers.get('total-count')  ?? 0);
        var page = parseInt(response.headers.get('page-index'));
        var status = response.status;
        let json = await response.json();
        return {status:status, count:count, page:page, data:json};
        
    }
    catch(error)
    {
        return {status:0, count:0, page:0, data:[]};
    }
}
export async function getDrugInfo(input) {
    try
    {
        let response = await fetch(`${process.env.REACT_APP_API_URL}/drugInfo/afe3f563-6aa4-4615-8d60-affd00f52334`,
        {
            method:"GET",
            headers: { 'Authorization': `Bearer ${localStorage.getItem('token').replaceAll('"', "")}`}
        });
        return await response.json();
    }
    catch(error)
    {
        return  [];
    }
}

export async function getInteractionsDisclaimer(url)
{
   try
   {
       return await fetch(url,
       {
           method: "GET"
       }).then(data => data.json())
   }
   catch (error)
   { return [] }
}
