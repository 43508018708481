import React, { useState, useEffect, useRef } from 'react';
import { ResetPass  } from '../../Services/LoginService'
import {  useParams } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Row, Col, Container } from 'react-bootstrap';
import MetaTags from 'react-meta-tags';
import '../../styles/ResetPassword.scss'
import { TextField, Button, Typography, FormControl, InputAdornment, IconButton, Checkbox, FormControlLabel, Box, Modal, CircularProgress } from '@mui/material';
import { IconUser, IconBuilding, IconBriefcase, IconMapPin, IconPhone, IconMail, IconLock, IconEye, IconEyeOff, IconLogin, IconRestore, IconCheck, IconArrowForward, IconArrowLeft, IconArrowRight } from '@tabler/icons-react';
import { logPageViews } from '../../Services/LoginService';

export const history = createBrowserHistory();

const ResetPassword = () => {
    const { id } = useParams();
    const [token, setToken] = useState('')

    //Shrink label when there's an autofill value 
    const [newPasswordHasValue, setNewPasswordHasValue] = React.useState(false);
    const [confirmPasswordHasValue, setConfirmPasswordHasValue] = React.useState(false);
    const makeAnimationStartHandler = (fieldId) => (e) => {
        const autofilled = !!e.target?.matches("*:-webkit-autofill");
        if (e.animationName === "mui-auto-fill" || e.animationName === "mui-auto-fill-cancel") {
          setShrinked((prevShrinked) => ({
            ...prevShrinked,
            [fieldId]: autofilled || e.target.value !== '',
          }));
        }
      };

    const [focused, setFocused] = useState({ newPassword: false, confirmPassword: false });
    const [shrinked, setShrinked] = useState({ newPassword: false, confirmPassword: false });

    const [newPassword, setNewPassword] = useState('');
    const handleNewPasswordChange = (e) => {
        const value = e.target.value;
        setNewPassword(value);
        setNewPasswordHasValue(value !== '');
        setError((prevState) => ({ ...prevState, newPassword: false }));
        setShrinked((prevShrinked) => ({ ...prevShrinked, newPassword: value !== '' }));
        if (value.length > 7){
            setFocused((prevFocused) => ({ ...prevFocused, newPassword: true}));
            setIsConfirmPasswordEnabled(true);
            if (confirmPassword.length>7){
                if (value !== confirmPassword){
                    setError((prevState) => ({ ...prevState, confirmPassword: true }));
                    setConfirmPasswordErrorMessage('Passwords do not match');
                    setFocused((prevFocused) => ({ ...prevFocused, confirmPassword: false }));
                 }
                 else{
                    setError((prevState) => ({ ...prevState, confirmPassword: false }));
                    setFocused((prevFocused) => ({ ...prevFocused, confirmPassword: true }));
                }
            }
        }
        else{
            setIsConfirmPasswordEnabled(false);
            setFocused((prevFocused) => ({ ...prevFocused, newPassword: false }));
            setFocused((prevFocused) => ({ ...prevFocused, confirmPassword: false }));
            setError((prevState) => ({ ...prevState, confirmPassword: false }));
            if (value.length === 0)
            {
                setConfirmPassword('');
                setShrinked((prevShrinked) => ({ ...prevShrinked, confirmPassword: value !== '' }));
                setFocused((prevFocused) => ({ ...prevFocused, confirmPassword: false }));
            }
        }
        
    };

    const [confirmPassword, setConfirmPassword] = useState('');
    const [isConfirmPasswordEnabled, setIsConfirmPasswordEnabled] = useState(false);
    const handleConfirmPasswordChange = (e) => {
        const value = e.target.value;
        setConfirmPassword(value);
        setConfirmPasswordHasValue(value !== '');
        setFocused((prevFocused) => ({ ...prevFocused, confirmPassword: false }));
        setError((prevState) => ({ ...prevState, confirmPassword: false }));
        setShrinked((prevShrinked) => ({ ...prevShrinked, confirmPassword: value !== '' }));
        if (value.length > 7){
            if (value !== newPassword){
                setError((prevState) => ({ ...prevState, confirmPassword: true }));
                setConfirmPasswordErrorMessage('Passwords do not match');
            }
            else{
                setFocused((prevFocused) => ({ ...prevFocused, confirmPassword: true }));
            }
        } 
    };

    const [error, setError] = useState({ newPassword: false, confirmPassword: false });
    const [newPasswordErrorMessage, setNewPasswordErrorMessage] = useState('');
    const [confirmPasswordErrorMessage, setConfirmPasswordErrorMessage] = useState('');

      // Show password toggle
    const [showNewPassword, setShowNewPassword] = useState(false);
    const handleClickShowNewPassword = () => {
        setShowNewPassword(!showNewPassword);
    };
    // Show confirm password toggle
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
    };
    async function logPage(email) {
      await logPageViews("Reset Password", email)
    }

    // Submit validation
    async function VerifyUser()  {
        let isValid = true;
        
        const passwordRegex = /^.{8,}$/;
     
            if (newPassword === '') {
                isValid = false;
                setError(prevState => ({ ...prevState, newPassword: true }));
                setNewPasswordErrorMessage('Password is required');
                setConfirmPassword('');
                setShrinked(prevShrinked => ({ ...prevShrinked, confirmPassword: false, }));    
            } else if (newPassword.length < 8 && newPassword !== '') {
                isValid = false;
                setError(prevState => ({ ...prevState, newPassword: true }));
                setNewPasswordErrorMessage('Password should be at least 8 characters long');
                setConfirmPassword('');
                setShrinked(prevShrinked => ({ ...prevShrinked, confirmPassword: false,  }));
                
            } else {
                setError(prevState => ({ ...prevState, newPassword: false }));
                if (isConfirmPasswordEnabled) {
                    if (confirmPassword === '') {
                        setError(prevState => ({ ...prevState, confirmPassword: true }));
                        setConfirmPasswordErrorMessage('Password confirmation is required');
                        isValid = false;
                    } else if (confirmPassword !== newPassword) {
                        setError(prevState => ({ ...prevState, confirmPassword: true }));
                        setConfirmPasswordErrorMessage('Passwords do not match');
                        isValid = false;
                    } 
                    else{
                        if (newPassword === confirmPassword) {
                        isValid = true;
                       
                        }

                    }
                }
            }
       
        if (isValid) {
            setToken(await ResetPass(id, newPassword, confirmPassword));
            logPage(id)
        }
       

    }
    useEffect(() => {
      if (token) {
         
          // Check email existence
          if (token.data.reason === "invalid") {
              setError((prevState) => ({ ...prevState, newPassword: true }));
              setNewPasswordErrorMessage('Invalid Password');
              setFocused((prevFocused) => ({ ...prevFocused, newPassword: false, }));

          } else {
              setError((prevState) => ({ ...prevState, newPassword: false }));     
          }

          // Sign up successful
          if (token.data.isSuccess) {
            setOpen(true)
          }
          

      }
  }, [token]);


  // Success modal
  const [open, setOpen] = useState(false);
  // Modal styles
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: '90%', sm: '60%', md: '50%', lg: '40%' },
    maxHeight: '90vh', // Set a maximum height to prevent overflow beyond the viewport
    bgcolor: 'background.paper',
    boxShadow: 20,
    borderRadius: '40px',
    p: { xs: 4, sm: 4, md: 6, lg: 6 },
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  };
  // Open Modal
  const handleOpen = () => {
    setOpen(true);
  };

  // Close modal then proceed to login
  const handleClose = () => {
    setOpen(false);
    history.push("/");
    window.location.reload();
  };

    
    return ( 
        <>
            <MetaTags>
                <meta name="viewport" content="width=device-width, initial-scale=.7" />
            </MetaTags>
            <div>
              <Modal open={open} disableBackdropClick aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" >
                <Box sx={style}>
                <img 
                    src="/ForgotPasswordSuccess.svg" 
                    alt="Illustration" 
                    style={{  maxWidth: '160px',  // Initial max-width
                      width: '100%',     // Allows scaling with container
                      height: 'auto', marginBottom: '16px' }} 
                  />
                  <Typography id="modal-modal-title" variant="h4" component="h2" gutterBottom sx={{ fontWeight: 'bold', color: '#0262AB', marginBottom: 0 }}>
                  Great!
                  </Typography>
                  <Typography id="modal-modal-description" sx={{ mt: 2, mb: 4, fontSize: { xs: '0.9rem', sm: '1rem', md: '1.2rem' }, color: '#727272' }}>
                  You can now access your account with your new password
                  </Typography>
                  
                  <Button
                    onClick={handleClose}
                    variant="contained"
                    color="primary"
                    sx={{
                      borderRadius: '32px',
                      padding: '16px 32px',
                      fontSize: '16px',
                      marginTop: '16px',
                      backgroundColor: '#0262AB',
                      '&:hover': { boxShadow: '0px 8px 24px 0px rgba(0, 153, 255, 0.15)', },
                    }}
                    endIcon={<IconLogin />}
                  >
                    Close and go to Sign In
                  </Button>
                </Box>
              </Modal>
            </div>
            
            <Container fluid id="resetPasswordPage">
                <Row className="h-100"> 
                    <Col lg={6} className="d-flex flex-column align-items-center" id="illustrationContainer">
                    {/* Content for the second column */}
                           <img src="/Logo.svg" alt="Logo" id="logo" className="align-self-start"/>
                            <div id="illustrationCaption" className="align-self-start">
                                <h3 style={{lineHeight: 1.5}} >Welcome back! Secure your account with a new password</h3>
                            </div>
                            <img src="/ResetPassword.svg" alt="Illustration" className="illustration" />
                           
                    </Col>
                    
                    <Col lg={6}  id="resetPasswordContainer" className="d-flex flex-column align-items-center justify-content-center" >
                        <Box sx={{ width: '100%'}}>
                       
                            <h1>Reset your password</h1>
                            <p style={{  marginBottom: '20px', color: '#727272' }}>
                            Enter your new password below. It should be at least 8 characters long and can include a combination of letters, numbers, and special characters for better security
                            </p>
                            <FormControl margin="normal" required fullWidth error={error.newPassword} >
                                  <TextField
                                    id="newPassword"
                                    name="newPassword"
                                    label='New Password'
                                    type={showNewPassword ? 'text' : 'password' }
                                    variant="outlined"
                                    value={newPassword}
                                    onChange={handleNewPasswordChange}
                                    fullWidth
                                    focused={focused.newPassword}
                                    error={error.newPassword}
                                    helperText={error.newPassword ? newPasswordErrorMessage : (focused.newPassword ? '' : 'Password should be at least 8 characters long')}
                                    InputProps={{
                                        onAnimationStart: makeAnimationStartHandler('newPassword'),
                                        startAdornment: (
                                            <InputAdornment position="start" >
                                                <IconLock sx={{ fontSize: 16, pl: '10px' }} />
                                            </InputAdornment>
                                        ),
                                        endAdornment: (
                                            <>
                                              <InputAdornment position="end">
                                                <IconButton
                                                  aria-label="toggle password visibility"
                                                  onClick={handleClickShowNewPassword}
                                                  edge="end"
                                                >
                                                  {showNewPassword ? <IconEyeOff /> : <IconEye />}
                                                </IconButton>
                                              </InputAdornment>
                                              {focused.newPassword && (
                                                <InputAdornment position="end">
                                                  <IconCheck className='validIcon' />
                                                </InputAdornment>
                                              )}
                                            </>
                                          ),
                                        }}
                                    InputLabelProps={{ shrink: shrinked.newPassword, sx: { fontWeight: 'bold' },  }}
                                    // disabled={field.id === 'confirmPassword' && !isConfirmPasswordEnabled}
                                    autoComplete="new-password"
                                    form={{ autoComplete: 'off' }}
                                  />
                                </FormControl>
                                <FormControl margin="normal" required fullWidth error={error.confirmPassword} >
                                  <TextField
                                    id="confirmPassword"
                                    name="confirmPassword"
                                    label='Confirm Password'
                                    type={showConfirmPassword ? 'text' : 'password' }
                                    variant="outlined"
                                    value={confirmPassword}
                                    onChange={handleConfirmPasswordChange}
                                    fullWidth
                                    focused={focused.confirmPassword}
                                    error={error.confirmPassword}
                                    helperText={error.confirmPassword ? confirmPasswordErrorMessage : (focused.confirmPassword ?  <span style={{ color: '#0262AB' }}>Passwords match</span>  : '')}
                                    InputProps={{
                                        onAnimationStart: makeAnimationStartHandler('confirmPassword'),
                                        startAdornment: (
                                            <InputAdornment position="start" >
                                                <IconLock sx={{ fontSize: 16, pl: '10px' }} />
                                            </InputAdornment>
                                        ),
                                        endAdornment: (
                                            <>
                                              <InputAdornment position="end">
                                                <IconButton
                                                  aria-label="toggle password visibility"
                                                  onClick={handleClickShowConfirmPassword}
                                                  edge="end"
                                                >
                                                  {showConfirmPassword ? <IconEyeOff /> : <IconEye />}
                                                </IconButton>
                                              </InputAdornment>
                                              {focused.confirmPassword && (
                                                <InputAdornment position="end">
                                                  <IconCheck className='validIcon' />
                                                </InputAdornment>
                                              )}
                                            </>
                                          ),
                                        }}
                                    InputLabelProps={{ shrink: shrinked.confirmPassword, sx: { fontWeight: 'bold' },  }}
                                    disabled={!isConfirmPasswordEnabled}
                                    autoComplete="new-password"
                                    form={{ autoComplete: 'off' }}
                                  />
                                </FormControl>
                               
                                
                                <Button id="resetPasswordButton"  onClick={VerifyUser} type="submit" fullWidth variant="contained" color="primary" endIcon={<IconRestore />}>
                                    Reset Password
                                </Button>
                                </Box>
                    </Col>
                </Row>
    
            </Container>
        </>
        );
}

export default ResetPassword;